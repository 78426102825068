var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "pub_dialog",
      staticStyle: { "z-index": "99" },
      attrs: {
        title: _vm.dom.title || "新增",
        visible: _vm.open,
        width: _vm.dialogWidth,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.open = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        { staticClass: "sketch_content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": _vm.dom.labelWidth
                  ? _vm.dom.labelWidth
                  : "120px",
                rules: _vm.dom.rules || {},
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                _vm._l(_vm.dom.dom, function (d, index) {
                  return _c(
                    "el-col",
                    { key: index, attrs: { span: Number(d.col) || 24 } },
                    [
                      !_vm.isEdit ||
                      !(
                        d.component === "regionTreeSelect" &&
                        _vm.form[d.prop] === 0
                      )
                        ? [
                            _vm.form[d.showProp] == 1 ||
                            (!d.isshow && !d.showProps) ||
                            _vm.form[d.showProp] == "1,-1" ||
                            _vm.form[d.showProp] == "-1,1" ||
                            _vm.isShowProp(_vm.form, d)
                              ? _c("saas-form-item", {
                                  attrs: { form: _vm.form, dom: d },
                                  on: {
                                    linkVal: _vm.linkVal,
                                    "update:form": function ($event) {
                                      _vm.form = $event
                                    },
                                    sendRelation: _vm.getData,
                                  },
                                  model: {
                                    value: _vm.form[d.prop],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, d.prop, $$v)
                                    },
                                    expression: "form[d.prop]",
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [
            _vm._v(_vm._s(_vm.dom.fail ? _vm.dom.fail.name : "取 消")),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.dom.name ? _vm.dom.name : "确 定"))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }