// 公寓管理 财务管理
import Layout from '@/components/Layout'
const systemSettings = {
  path: '/finance',
  component: Layout,
  hidden: true,
  meta: { title: '财务管理', icon: 'list', noCache: true },
  children: [
    {
      path: 'energyConsumption',
      component: () => import('@/views/financialManagement/energyConsumption'),
      name: 'FinanceEnergyConsumption',
      meta: { title: '能耗抄表', icon: 'user', noCache: false }
    },
    {
      path: 'financialStatement',
      component: () => import('@/views/financialManagement/financialStatement'),
      name: 'FinanceFinancialStatement',
      meta: { title: '财务账单', icon: 'user', noCache: false }
    },
    {
      path: 'financialStatement-client',
      component: () => import('@/views/financialManagement-client/financialStatement'),
      name: 'FinanceFinancialStatementClient',
      meta: { title: '财务账单', icon: 'user', noCache: false }
    },
    {
      path: 'transactionFlow',
      component: () => import('@/views/financialManagement/transactionFlow'),
      name: 'FinanceTransactionFlow',
      meta: { title: '交易流水', icon: 'user', noCache: false }
    },
    {
      path: 'bankStatement',
      component: () => import('@/views/financialManagement/bankStatementVerification'),
      name: 'FinanceBankStatement',
      meta: { title: '银行流水核账', icon: 'user', noCache: false }
    },
    {
      path: 'energyConsumptionStatistics',
      component: () => import('@/views/financialManagement/energyConsumptionStatistics'),
      name: 'FinanceEnergyConsumptionStatistics',
      meta: { title: '用能统计', icon: 'user', noCache: false }
    },
    {
      path: 'rechargeStatistics',
      component: () => import('@/views/financialManagement/rechargeStatistics'),
      name: 'FinanceRechargeStatistics',
      meta: { title: '充值统计', icon: 'user', noCache: false }
    },
    {
      path: 'energyBill-client',
      component: () => import('@/views/financialManagement-client/energyBill'),
      name: 'FinanceEnergyBill',
      meta: { title: '用能账单', icon: 'user', noCache: false }
    },
    {
      path: 'collectionReport',
      component: () => import('@/views/financialManagement/collectionReport'),
      name: 'FinanceCollectionReport',
      meta: { title: '收缴报表', icon: 'user', noCache: false }
    }
  ]
}
export default systemSettings
