import request from '@/utils/request'

export function formworkCreate(data) { // 新增消息模板
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-msg-formwork/create',
    method: 'post',
    data
  })
}

export function formworkUpdate(data) { // 编辑消息模板
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-msg-formwork/update',
    method: 'post',
    data
  })
}

export function formworkDelete(params) { // 删除消息模板
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-msg-formwork/logicDelete',
    method: 'post',
    params
  })
}

export function getFormworkPage(params) { // 获取消息模板
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-msg-formwork/page',
    method: 'get',
    params
  })
}

export function getMsgPage(params) { // 获取消息提醒
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-msg/page',
    method: 'get',
    params
  })
}

export function batchUpdateStatus(data) { // 更新消息提醒已读
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-msg/batchUpdateStatus',
    method: 'post',
    data
  })
}

export function allBatchUpdateStatus(data) { // 更新全部消息提醒已读
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-msg/allUpdateStatus',
    method: 'post',
    data
  })
}

export function getSysMsgPage(params) { // 获取系统通知
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-sys-msg/page',
    method: 'get',
    params
  })
}

export function sysBatchUpdateStatus(data) { // 更新系统通知已读
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-sys-msg/batchUpdateStatus',
    method: 'post',
    data
  })
}

export function allSysBatchUpdateStatus(data) { // 更新全部系统通知已读
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-sys-msg/allUpdateStatus',
    method: 'post',
    data
  })
}

export function getSendLogPage(params) { // 获取消息推送记录
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-msg-send-log/page',
    method: 'get',
    params
  })
}

export function workTypeAdd(data) { // 新建工单类型
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order-type/create',
    method: 'post',
    data
  })
}

export function getWorkTypeList(params) { // 获取工单类型分页
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order-type/page',
    method: 'get',
    params
  })
}

export function deleteWorkType(params) { // 删除工单类型
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order-type/delete',
    method: 'post',
    params
  })
}

export function updateWorkType(data) { // 修改工单类型
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order-type/update',
    method: 'post',
    data
  })
}

export function updateStatusWorkType(params) { // 工单类型启用停用
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order-type/updateStatus',
    method: 'post',
    params
  })
}

export function getNodeList(params) { // 工单类型流程列表获取
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-flow-node/list',
    method: 'post',
    params
  })
}

export function updateNodeList(data) { // 工单类型流程修改流程
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-flow-node/update',
    method: 'post',
    data
  })
}

export function deleteNodeList(params) { // 工单类型流程删除流程
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-flow-node/delete',
    method: 'post',
    params
  })
}

export function createNodeList(data) { // 工单类型流程新增流程
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-flow-node/create',
    method: 'post',
    data
  })
}

export function changeSortNode(params) { // 工单类型流程修改节点位置
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-flow-node/changeSort',
    method: 'post',
    params
  })
}

export function getOrgList(params) { // 获取组织用户树
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/org-list',
    method: 'get',
    params
  })
}

export function getUserList(params) { // 获取用户
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/user-list',
    method: 'get',
    params
  })
}

export function getWorkOrderList(params) { // 获取工单记录
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/page',
    method: 'get',
    params
  })
}

export function getWorkOrderById(params) { // 获取粗略工单详情
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/getCommonById',
    method: 'get',
    params
  })
}

export function getWorkOrderDetailById(params) { // 获取工单详情
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/getById',
    method: 'get',
    params
  })
}

export function getWorkType(params) { // 获取可用工单类型列表
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order-type/activateList',
    method: 'get',
    params
  })
}

export function createWorkOrder(data) { // 创建工单记录
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/create',
    method: 'post',
    data
  })
}

export function updateWorkOrder(data) { // 编辑工单记录
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/update',
    method: 'post',
    data
  })
}

export function uploadWorkOrder(data) { // 工单上传信息
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/upload',
    method: 'post',
    data
  })
}

export function revokeWorkOrder(params) { // 退回工单
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/revoke',
    method: 'post',
    params
  })
}

export function backWorkOrder(params) { // 撤销工单
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/back',
    method: 'post',
    params
  })
}

export function transToAnotherWorkOrder(params) { // 转他人处理
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/transToAnother',
    method: 'post',
    params
  })
}

export function allocateWorkOrder(params) { // 分配工单
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/allocate',
    method: 'post',
    params
  })
}

export function deleteWorkOrder(params) { // 删除工单记录
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/delete',
    method: 'post',
    params
  })
}

export function handleWorkOrder(data) { // 处理工单
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/handle',
    method: 'post',
    data
  })
}

// 上传
export function fileUpload(file) {
  const params = new FormData() // 创建form对象
  params.append('file', file) // 通过append向form对象添加数据//第一个参数字符串可以填任
  // params.append('folder', '')
  return request({
    url: '/file/upload/event',
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'post',
    data: params
  })
}

export function getOrgTree(params) { // 获取部门
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/org-tree',
    method: 'get',
    params
  })
}

export function getEventTotal(params) { // 获取消息数量
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-sys-msg/getTotal',
    method: 'get',
    params
  })
}

export function getWorkListTopFive(params) { // 获取工单前5
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-work-order/listTopFive',
    method: 'post',
    params
  })
}

export function getMsgListTopFive(params) { // 获取消息提醒前5
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-msg/listTopFive',
    method: 'post',
    params
  })
}

export function getSysMsgListTopFive(params) { // 获取系统通知前5
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-sys-msg/listTopFive',
    method: 'post',
    params
  })
}

export function eventTypeCreate(data) { // 新建事件类型
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-type/create',
    method: 'post',
    data
  })
}

export function eventTypeUpdate(data) { // 修改事件类型
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-type/update',
    method: 'post',
    data
  })
}

export function eventTypeUpdateStatus(params) { // 修改事件类型启用状态
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-type/updateStatus',
    method: 'post',
    params
  })
}

export function eventTypePage(params) { // 获取事件类型列表
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-type/page',
    method: 'get',
    params
  })
}

export function eventTypeList(params) { // 获取启用的事件类型列表
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-type/listEnable',
    method: 'post',
    params
  })
}

export function eventTypeDelete(params) { // 删除事件类型
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-type/delete',
    method: 'post',
    params
  })
}

export function eventTypePageConfig(params) { // 获取事件配置列表
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-type/pageConfig',
    method: 'get',
    params
  })
}

export function eventTypeUpdateConfig(data) { // 修改事件类型配置
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-type/updateConfig',
    method: 'post',
    data
  })
}

export function createOrBatchUpdatePerson(data) { // 新增或修改责任人
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-person-liable/createOrBatchUpdate',
    method: 'post',
    data
  })
}

export function getPersonList(params) { // 获取责任人列表
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-person-liable/page',
    method: 'get',
    params
  })
}

export function eventListByUserId(data) { // 获取用户关联的事件类型
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-person-liable-type/listByUserId',
    method: 'post',
    data
  })
}

export function areaListByUserId(data) { // 获取用户关联的区域
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-person-liable-area/listByUserId',
    method: 'post',
    data
  })
}

export function getEventLogList(params) { // 获取事件记录列表
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-log/page',
    method: 'get',
    params
  })
}

export function eventLogProcess(data) { // 快速处理事件
  return request({
    baseURL: process.env.VUE_APP_EVENT_API,
    url: '/event/event-log/process',
    method: 'post',
    data
  })
}

export function getDictData(data) { // 获取字典表
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/common/getDictData',
    method: 'post',
    data
  })
}

// 应用列表
export function getApplicationList(data) {
  return request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: '/orgServiceApply/openServiceDict',
    method: 'post',
    data
  })
}
