// 智慧园区 能耗管理
import Layout from '@/components/Layout'
export default {
  path: '/energy',
  component: Layout,
  hidden: true,
  meta: { title: '能耗管理', icon: 'list', noCache: true },
  children: [
    {
      path: 'index',
      component: () => import('@/views/energy/chart/index'),
      name: 'EnergyIndex',
      meta: { title: '综合概览', icon: 'documentation', noCache: true }
    },
    {
      path: 'breed',
      component: () => import('@/views/energy/breed/index'),
      name: 'EnergyBreed',
      meta: { title: '能源品种', icon: 'documentation', noCache: true }
    },
    {
      path: 'type',
      component: () => import('@/views/energy/type/index'),
      name: 'EnergyType',
      meta: { title: '能源类型', icon: 'documentation', noCache: true }
    },
    {
      path: 'zhibiao',
      component: () => import('@/views/energy/chart/zhibiao/index'),
      name: 'EnergyZhibiao',
      meta: { title: '能耗指标', icon: 'documentation', noCache: true }
    },
    {
      path: 'duibiao',
      component: () => import('@/views/energy/chart/duibiao/index'),
      name: 'EnergyDuibiao',
      meta: { title: '能效对标', icon: 'documentation', noCache: true }
    },
    {
      path: 'subitems',
      component: () => import('@/views/energy/statistics/subitems/index'),
      name: 'EnergySubitems',
      meta: { title: '分项统计', icon: 'documentation', noCache: true }
    },
    {
      path: 'classification',
      component: () => import('@/views/energy/statistics/classification/index'),
      name: 'EnergyClssIfication',
      meta: { title: '分类统计', icon: 'documentation', noCache: true }
    },
    {
      path: 'region',
      component: () => import('@/views/energy/statistics/region/index'),
      name: 'EnergyRegion',
      meta: { title: '区域统计', icon: 'documentation', noCache: true }
    },
    {
      path: 'changetrend',
      component: () => import('@/views/energy/statistics/changetrend/index'),
      name: 'EnergyChangetrend',
      meta: { title: '变化趋势', icon: 'documentation', noCache: true }
    },
    {
      path: 'rank',
      component: () => import('@/views/energy/statistics/rank/index'),
      name: 'EnergyRank',
      meta: { title: '能耗排名', icon: 'documentation', noCache: true }
    },
    {
      path: 'report',
      component: () => import('@/views/energy/statistics/report/index'),
      name: 'EnergyReport',
      meta: { title: '报表 ', icon: 'documentation', noCache: true }
    },
    {
      path: 'electricperiod',
      component: () => import('@/views/energy/statistics/electricperiod/index'),
      name: 'EnergyElectricperiod',
      meta: { title: '电能时段 ', icon: 'documentation', noCache: true }
    },
    {
      path: 'cost',
      component: () => import('@/views/energy/cost/index'),
      name: 'cost',
      meta: { title: '费用设置', icon: 'documentation', noCache: true }
    },
    {
      path: 'threshold',
      component: () => import('@/views/energy/threshold/index'),
      name: 'threshold',
      meta: { title: '能耗阈值管理', icon: 'documentation', noCache: true }
    },
    {
      path: 'costcount',
      component: () => import('@/views/energy/costCount/index'),
      name: 'costCount',
      meta: { title: '能耗费用统计', icon: 'documentation', noCache: true }
    },
    {
      path: 'usagecount',
      component: () => import('@/views/energy/usageCount/index'),
      name: 'usageCount',
      meta: { title: '能耗用量统计', icon: 'documentation', noCache: true }
    },
    {
      path: 'meteredData',
      component: () => import('@/views/energy/meteredData/index'),
      name: 'meteredData',
      meta: { title: '计量数据', icon: 'documentation', noCache: true }
    },
    {
      path: 'countEnergy',
      component: () => import('@/views/energy/countEnergy/index'),
      name: 'countEnergy',
      meta: { title: '能耗用量计算', icon: 'documentation', noCache: true }
    },
    {
      path: 'deviceLevel',
      component: () => import('@/views/energy/deviceLevel/index'),
      name: 'deviceLevel',
      meta: { title: '仪器层级', icon: 'documentation', noCache: true }
    },
    {
      path: 'costShare',
      component: () => import('@/views/energy/costShare/index'),
      name: 'costShare',
      meta: { title: '成本分摊', icon: 'documentation', noCache: true }
    },
    {
      path: 'costDetails',
      hidden: true,
      component: () => import('@/views/energy/costDetails/index'),
      name: 'costDetails',
      meta: { title: '成本分摊详情', icon: 'documentation', noCache: true }
    },
    {
      path: 'costStatistics',
      component: () => import('@/views/energy/costStatistics/index'),
      name: 'costStatistics',
      meta: { title: '费用统计', icon: 'documentation', noCache: true }
    },
    {
      path: 'costCenter',
      component: () => import('@/views/energy/costCenter/index'),
      name: 'costCenter',
      meta: { title: '成本中心', icon: 'documentation', noCache: true }
    },
    {
      path: 'costCenter/add',
      hidden: true,
      component: () => import('@/views/energy/costCenter/add'),
      name: 'costCenter/add',
      meta: { title: '成本中心详情', icon: 'documentation', noCache: true }
    },
    {
      path: 'recovery',
      component: () => import('@/views/energy/recovery/index'),
      name: 'recovery',
      meta: { title: '能源回收', icon: 'documentation', noCache: true }
    },
    {
      path: 'recovery/type',
      component: () => import('@/views/energy/recovery/type'),
      name: 'type',
      hidden: true,
      meta: { title: '能源回收项', icon: 'documentation', noCache: true }
    },
    {
      path: 'yield',
      component: () => import('@/views/energy/yield/index'),
      name: 'yield',
      meta: { title: '生产产量', icon: 'documentation', noCache: true }
    },
    {
      path: 'alarm',
      component: () => import('@/views/energy/alarm/index'),
      name: 'alarm',
      meta: { title: '用能告警', icon: 'documentation', noCache: true }
    },
    {
      path: 'obversion',
      component: () => import('@/views/energy/config/obversion/index'),
      name: 'obversion',
      meta: { title: '折算系数', icon: 'documentation', noCache: true }
    },
    {
      path: 'co2Config',
      component: () => import('@/views/energy/config/co2Config/index'),
      name: 'co2Config',
      meta: { title: '排放因子', icon: 'documentation', noCache: true }
    },
    {
      path: 'alarm/add',
      hidden: true,
      component: () => import('@/views/energy/alarm/add'),
      name: 'alarm/add',
      meta: { title: '用能告警详情', icon: 'documentation', noCache: true }
    },
    {
      path: 'subitem',
      component: () => import('@/views/energy/subitem/index'),
      name: 'subitem',
      meta: { title: '用能设备分项', icon: 'documentation', noCache: true }
    },
    {
      path: 'subitem/add',
      hidden: true,
      component: () => import('@/views/energy/subitem/add'),
      name: 'subitem/add',
      meta: { title: '用能设备分项详情', icon: 'documentation', noCache: true }
    },
    {
      path: 'department',
      component: () => import('@/views/energy/department/index'),
      name: 'department',
      meta: { title: '组织关联设备', icon: 'documentation', noCache: true }
    },
    {
      path: 'department/add',
      hidden: true,
      component: () => import('@/views/energy/department/add'),
      name: 'department/add',
      meta: { title: '组织关联设备详情', icon: 'documentation', noCache: true }
    },
    {
      path: 'programme',
      component: () => import('@/views/energy/programme/index'),
      name: 'programme',
      meta: { title: '能源方案', icon: 'documentation', noCache: true }
    },
    {
      path: 'programme/add',
      hidden: true,
      component: () => import('@/views/energy/programme/add'),
      name: 'programme/add',
      meta: { title: '能源方案详情', icon: 'documentation', noCache: true }
    }
  ]
}

