function handlePromise(promiseList) {
  return promiseList.map(promise =>
    promise.then(result => result).catch(error => error)
  )
}

export default function promiseAll(promiseList) {
  return originPromiseAll.call(this, handlePromise(promiseList));
  // https://tc39.es/ecma262/#sec-promise.all
  // return originPromiseAll(handlePromise(promiseList));
}

const originPromiseAll = Promise.all

Promise.all = promiseAll
