<template>
  <textarea
    :id="tinymceId"
    style="visibility: hidden"
    :dom="dom"
    v-model="formValue"
    :placeholder="dom.placeholder ? dom.placeholder : '请输入'"
  />
</template>

<script>
import loadTinymce from "@/utils/loadTinymce";
import { plugins, toolbar } from "./config";
import { debounce } from "throttle-debounce";

let num = 1;

export default {
  props: {
    id: {
      type: String,
      default: () => {
        return `tinymce${+new Date()}${num++}`;
      },
    },

    dom: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: "",
      default: "",
    },
    // 要提交表单
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      open: true,
      tinymce: null,
      tinymceId: this.id,
      formValue: "",
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        console.log("newVal", newVal, this.form);
        {
          this.formValue = newVal;
        }

        // this.form.areaCode = newVal
      },
    },
    formValue: {
      immediate: true,
      handler(newVal) {
        console.log(newVal)
        this.$emit("input", newVal);
        // this.form.areaCode = newVal
      },
    },
    dom: {
      immediate: true,
      handler(newVal) {
        

        loadTinymce((tinymce) => {
          // eslint-disable-next-line global-require
          require("./zh_CN");
          let conf = {
            selector: `#${this.tinymceId}`,
            language: "zh_CN",
            menubar: "file edit insert view format table",
            plugins,
            toolbar,
            height: 300,
            branding: false,
            object_resizing: false,
            end_container_on_empty_block: true,
            powerpaste_word_import: "clean",
            code_dialog_height: 450,
            code_dialog_width: 1000,
            advlist_bullet_styles: "square",
            advlist_number_styles: "default",
            default_link_target: "_blank",
            link_title: false,
            nonbreaking_force_tab: true,
          };
          conf = Object.assign(conf, this.$attrs);
          console.log(this.formValue);
          conf.init_instance_callback = (editor) => {
            if (this.formValue) {
              console.log(this.formValue);
              editor.setContent(this.formValue);
            }else{
              editor.setContent('');
            }
            this.vModel(editor);
          };
          tinymce.init(conf);
        });
        console.log(newVal);
        // this.form.areaCode = newVal
      },
    },
  },
  mounted() {
    loadTinymce((tinymce) => {
      // eslint-disable-next-line global-require
      require("./zh_CN");
      let conf = {
        selector: `#${this.tinymceId}`,
        language: "zh_CN",
        menubar: "file edit insert view format table",
        plugins,
        toolbar,
        height: 300,
        branding: false,
        object_resizing: false,
        end_container_on_empty_block: true,
        powerpaste_word_import: "clean",
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: "square",
        advlist_number_styles: "default",
        default_link_target: "_blank",
        link_title: false,
        nonbreaking_force_tab: true,
      };
      conf = Object.assign(conf, this.$attrs);
      conf.init_instance_callback = (editor) => {
        if (this.value) editor.setContent(this.value);
        this.vModel(editor);
      };
      tinymce.init(conf);
    });
    this.$bus.$on("close", (msg) => {
      // A发送来的消息

      this.destroyTinymce();
    });
  },
  destroyed() {
    this.destroyTinymce();
  },
  methods: {
    vModel(editor) {
      // 控制连续写入时setContent的触发频率
      const debounceSetContent = debounce(250, editor.setContent);
      this.$watch("value", (val, prevVal) => {
        if (editor && val !== prevVal && val !== editor.getContent()) {
          if (typeof val !== "string") val = val.toString();
          debounceSetContent.call(editor, val);
        }
      });
      editor.on("change keyup undo redo", () => {
        this.$emit("input", editor.getContent());
      });
    },
    destroyTinymce() {
      if (!window.tinymce) return;
      const tinymce = window.tinymce.get(this.tinymceId);
      if (tinymce) {
        tinymce.destroy();
      }
    },
  },
};
</script>
