var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dom.remoteMethod
        ? _c(
            "el-select",
            _vm._b(
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  multiple: _vm.dom.multiple,
                  "popper-append-to-body": false,
                  placeholder: _vm.dom.placeholder || "请输入关键字",
                  remote: "",
                  loading: _vm.loading,
                  "remote-method": _vm.remoteMethod,
                },
                on: { change: _vm.handleChange, clear: _vm.clearValue },
                model: {
                  value: _vm.formValue,
                  callback: function ($$v) {
                    _vm.formValue = $$v
                  },
                  expression: "formValue",
                },
              },
              "el-select",
              _vm.dom,
              false
            ),
            _vm._l(_vm.options, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: {
                  label: item[_vm.dom["dictLabel"] || "dictLabel"],
                  value: item[_vm.dom["dictValue"] || "dictValue"],
                },
              })
            }),
            1
          )
        : _c(
            "el-select",
            _vm._b(
              {
                attrs: {
                  clearable: "",
                  multiple: _vm.dom.multiple,
                  "popper-append-to-body": true,
                  placeholder: _vm.dom.placeholder || "请选择",
                },
                on: { change: _vm.handleChange, clear: _vm.clearValue },
                model: {
                  value: _vm.formValue,
                  callback: function ($$v) {
                    _vm.formValue = $$v
                  },
                  expression: "formValue",
                },
              },
              "el-select",
              _vm.dom,
              false
            ),
            _vm._l(_vm.options, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: {
                  label: item[_vm.dom["dictLabel"] || "dictLabel"],
                  value: item[_vm.dom["dictValue"] || "dictValue"],
                },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }