var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.isOpen,
        "before-close": _vm.closeDialog,
        width: _vm.dialogWidth,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        "append-to-body": "",
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isOpen = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.isLoading },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:isOpen", false)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }