import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5f2c575d&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=5f2c575d&lang=scss&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&id=5f2c575d&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f2c575d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/nisparkv2/front/nisbos-front-admin/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f2c575d')) {
      api.createRecord('5f2c575d', component.options)
    } else {
      api.reload('5f2c575d', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=5f2c575d&scoped=true&", function () {
      api.rerender('5f2c575d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Layout/index.vue"
export default component.exports