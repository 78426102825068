import hasRole from './hasRole'
import hasPermi from './hasPermi'
import permission from './permission'
import componentpermissions from './componentpermissions.js'
const install = function(Vue) {
  Vue.directive('hasRole', hasRole)
  Vue.directive('hasPermi', hasPermi)
  Vue.directive('permission', permission)
  Vue.directive('componentpermissions', componentpermissions)
}

if (window.Vue) {
  window['hasRole'] = hasRole
  window['hasPermi'] = hasPermi
  window['permission'] = permission
  window['componentpermissions'] = componentpermissions
  Vue.use(install); // eslint-disable-line
}

export default install
