import Layout from '@/components/Layout'
export default {
  path: '/smallRoutineDevise',
  component: Layout,
  hidden: true,
  meta: { title: '系统设置', icon: 'list', noCache: true },
  children: [
    {
      path: 'fitUp',
      component: () => import('@/views/smallRoutineDevise/enterpriseSettings/fitUp'),
      name: 'SmallRoutineDeviseFitUp',
      meta: { title: '小程序装修', icon: 'user', noCache: true }
    }
  ]
}
