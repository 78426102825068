import Cookies from 'js-cookie';

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}
Date.prototype.Format = function (fmt) { //author: meizz
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
};

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * 合并两个对象，重复的非obj值会被后面的值覆盖
 * 相同key将会被后面的值覆盖
 * */
export function extend() {
  let options;
  let name;
  let src;
  let copy;
  let copyIsArray;
  let clone;
  let target = arguments[0] || {}; // 目标对象
  let i = 1;
  const length = arguments.length;
  let deep = false;

  // 处理深度拷贝情况（第一个参数是boolean类型且为true）
  if (typeof target === 'boolean') {
    deep = target;
    target = arguments[1] || {};
    // 跳过第一个参数（是否深度拷贝）和第二个参数（目标对象）
    i = 2;
  }

  // 如果目标不是对象或函数，则初始化为空对象
  if (typeof target !== 'object') {
    target = {};
  }

  // 如果只指定了一个参数，则使用jQuery自身作为目标对象
  if (length === i) {
    target = this;
    --i;
  }

  for (; i < length; i++) {
    // Only deal with non-null/undefined values
    if ((options = arguments[i]) != null) {
      // Extend the base object
      for (name in options) {
        src = target[name];
        copy = options[name];

        // Prevent never-ending loop
        if (target === copy) {
          continue;
        }

        // 如果对象中包含了数组或者其他对象，则使用递归进行拷贝
        copyIsArray = Array.isArray(copy)
        if (deep && copy && typeof copy !== 'function' && (copy instanceof Object || copyIsArray)) {
          // 处理数组
          if (copyIsArray) {
            copyIsArray = false;
            // 如果目标对象不存在该数组，则创建一个空数组；
            clone = src && Array.isArray(src) ? src : [];
          } else {
            clone = src && Object.keys(src).length ? src : {};
          }

          // 从不改变原始对象，只做拷贝
          target[name] = extend(deep, clone, copy);

          // 不拷贝undefined值
        } else if (copy !== undefined) {
          target[name] = copy;
        }
      }
    }
  }

  // 返回已经被修改的对象
  return target;
}

/** 处理后端返回的时间*/
export function formateDate(date) {
  const arr = date.split('T');
  const d = arr[0];
  const darr = d.split('-');

  const t = arr[1];
  const tarr = t.split('.000');
  const marr = tarr[0].split(':');

  return parseInt(darr[0]) +
    '/' +
    parseInt(darr[1]) +
    '/' +
    parseInt(darr[2]) +
    ' ' +
    parseInt(marr[0]) +
    ':' +
    parseInt(marr[1]) +
    ':' +
    parseInt(marr[2]);
}

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

/**
 * 为dom添加class
 * @param {HTMLElement} ele
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * 删除dom的指定class
 * @param {HTMLElement} ele
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

/**
 * 判断dom是否拥有传入的class
 * @param {HTMLElement} ele
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

// cookie头
const C_HEADER = 'enterprise'

/**
 * 返回拼装后的cookieKey
 * @param key {String}
 * */
export function cookieKey(key) {
  return C_HEADER + '_' + key
}

/**
 * 设置COOKIE
 * @param key {String}
 * @param val {String}
 * */
export function setCookie(key, val, expires = undefined) {
  Cookies.set(cookieKey(key), val, expires)
}

/**
 * 获取COOKIE
 * @param key {String}
 * */
export function getCookie(key) {
  return Cookies.get(cookieKey(key))
}

/**
 * 删除COOKIE
 * @param key {String,Array}
 * */
export function removeCookie(key) {
  if (Array.isArray(key)) {
    key.forEach(item => {
      Cookies.remove(cookieKey(item))
    })
  } else {
    Cookies.remove(cookieKey(key))
  }
}

/**
 * 计算距过期天数
 * @param targetTime
 * @returns {number}
 */
export function computeExpireDays(targetTime) {
  return Math.floor(Math.abs(Date.parse(new Date(targetTime)) - Date.now()) / 86400000)
}

export const exportDefault = 'export default '

export const beautifierConf = {
  html: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'separate',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'normal',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

// 首字母大小
export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, L => L.toUpperCase())
}

// 下划转驼峰
export function camelCase(str) {
  return str.replace(/_[a-z]/g, str1 => str1.substr(-1).toUpperCase())
}

export function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str)
}


export function accDiv(arg1,arg2){ 
  var t1 = 0,t2 = 0, r1, r2;
  try{
  t1 = arg1.toString().split(".")[1].length; // 被除数小数位数
  }catch(e){
  }
  try{
  t2 = arg2.toString().split(".")[1].length; // 除数小数位数
  }catch(e){
  }
  // 俩数分别乘以10的其中小数位最多的位数的次方，转换为整数
  r1 = accMul(Number(arg1.toString()) , Math.pow(10, Math.max(t1,t2)));  
  r2 = accMul(Number(arg2.toString()) , Math.pow(10, Math.max(t1,t2)));
  return r1 / r2; // 进行整数的除法计算
} 

export function accMul(arg1,arg2) { 
  var m=0,s1=arg1.toString(),s2=arg2.toString(); 
  try{m+=s1.split(".")[1].length}catch(e){} 
  try{m+=s2.split(".")[1].length}catch(e){} 
  return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m) 
} 


 
export function accAdd(arg1, arg2) {
  var r1, r2, m, c;
  try {
      r1 = arg1.toString().split(".")[1].length;
  }
  catch (e) {
      r1 = 0;
  }
  try {
      r2 = arg2.toString().split(".")[1].length;
  }
  catch (e) {
      r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
      var cm = Math.pow(10, c);
      if (r1 > r2) {
          arg1 = Number(arg1.toString().replace(".", ""));
          arg2 = Number(arg2.toString().replace(".", "")) * cm;
      } else {
          arg1 = Number(arg1.toString().replace(".", "")) * cm;
          arg2 = Number(arg2.toString().replace(".", ""));
      }
  } else {
      arg1 = Number(arg1.toString().replace(".", ""));
      arg2 = Number(arg2.toString().replace(".", ""));
  }
  return (arg1 + arg2) / m;
}