var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "portal-layout-main" }, [
    _c("div", { staticClass: "head-menu" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingMenu,
              expression: "loadingMenu",
            },
          ],
          staticClass: "menu-left",
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-text",
              class: { checked: _vm.isChecked(_vm.rootMenu) },
              on: {
                click: function ($event) {
                  return _vm.toPortalPortalList(_vm.rootMenu)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.rootMenu.cateName) + " ")]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "menu-middle" },
        _vm._l(_vm.menuList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "btn-text menu-btn",
              class: { checked: _vm.isChecked(item) },
              on: {
                click: function ($event) {
                  return _vm.toPortalPortalList(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.cateName) + " ")]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "menu-right" },
        [
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.toSmartPark } },
            [_vm._v("前往智慧园区")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "main-content" },
      [
        _c(
          "transition",
          { attrs: { name: "fade-transform", mode: "out-in" } },
          [
            _c(
              "keep-alive",
              { attrs: { include: _vm.cachedViews } },
              [_c("router-view", { key: _vm.key }), _c("router-view")],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "footer-title" }, [_vm._v("智慧园区")]),
              _c("div", { staticClass: "footer-text" }, [
                _c("div", { staticClass: "footer-text-item" }, [
                  _vm._v(
                    "CopyRight @ 2017-2022 乐石科技有限公司 闽ICP备15001146号"
                  ),
                ]),
                _c("div", [_vm._v("地址：厦门市思明区桃园路16号2302室")]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "footer-title" }, [_vm._v("联系方式")]),
              _c("div", { staticClass: "footer-text" }, [
                _c("div", { staticClass: "footer-text-item" }, [
                  _vm._v("联系电话：400-6869-666"),
                ]),
                _c("div", [_vm._v("联系邮箱：zyyq@qq.com")]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }