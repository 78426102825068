var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _vm.iframesStatus
        ? _c("sidebar", { staticClass: "sidebar-container" })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: {
            hasTagsView: _vm.needTagsView,
            iframesStatus: !_vm.iframesStatus,
          },
        },
        [
          _c(
            "el-scrollbar",
            { staticStyle: { height: "100vh" } },
            [
              _vm.iframesStatus
                ? _c(
                    "div",
                    { class: { "fixed-header": _vm.fixedHeader } },
                    [
                      _c("navbar"),
                      _c("tags-view", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.needTagsView,
                            expression: "needTagsView",
                          },
                        ],
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("app-main"),
            ],
            1
          ),
          _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }