// 智慧园区 表单制作
import Layout from '@/components/Layout'
export default {
  path: '/formMaker',
  component: Layout,
  hidden: true,
  meta: { title: '表单制作', icon: 'documentation', noCache: true },
  children: [
    {
      path: 'formList',
      component: () => import('@/views/formMaker/formList/index'),
      name: 'FormList',
      meta: { title: '表单列表', icon: 'documentation', noCache: true }
    },
    {
      path: 'formList/edit',
      hidden: true,
      component: () => import('@/views/formMaker/formList/edit'),
      name: 'FormEdit',
      meta: { title: '表单详情', icon: 'documentation', noCache: true }
    },
    {
      path: 'tableInfo',
      hidden: true,
      component: () => import('@/views/formMaker/tableInfo/index'),
      name: 'TableInfo',
      meta: { title: '已收集的表', icon: 'documentation', noCache: true }
    },
    {
      path: 'tableInfo/edit',
      hidden: true,
      component: () => import('@/views/formMaker/tableInfo/edit'),
      name: 'TableInfoEdit',
      meta: { title: '填写详情', icon: 'documentation', noCache: true }
    },
    {
      path: 'passSetting',
      component: () => import('@/views/formMaker/passSetting/index'),
      name: 'PassSetting',
      meta: { title: '便捷通行配置', icon: 'documentation', noCache: true }
    },
    {
      path: 'passSetting/edit',
      hidden: true,
      component: () => import('@/views/formMaker/passSetting/edit'),
      name: 'PassSettingEdit',
      meta: { title: '便捷通行配置', icon: 'documentation', noCache: true }
    }
  ]
}

