import api from './bolbRequest';

export const downloadFile = (url,params,method) => api({
  url: url, 
  method: method?method:'get', 
  responseType: 'blob' ,
  
  params: params 

})

