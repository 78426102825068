var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticStyle: { cursor: "pointer" } }, [
    _vm.text
      ? _c(
          "span",
          {
            staticStyle: { color: "#409EFF" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.copy(_vm.text)
              },
            },
          },
          [
            _c("i", { staticClass: "el-icon-document" }),
            _vm._v(" "),
            _vm._t("default", [_vm._v(_vm._s(_vm.text))]),
          ],
          2
        )
      : _c("span", [_vm._v("--")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }