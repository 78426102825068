const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  fullscreen: state => state.app.fullscreen,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  configSystem: state => state.user.configSystem,
  routeMenu: state => state.permission.routeMenu,
  permission_addRoutes: state => state.permission.addRoutes,
  systemIdAndUrl: state => state.user.systemIdAndUrl,
  systemIdAndUrl1: state => state.user.systemIdAndUrl1,
  roles: state => state.user.roles,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  btnPermission:state => state.user.btnPermission,
  companyList:state => state.user.companyList,
  company:state => state.user.company,
  name: state => state.user.name,
  companyName: state => state.user.companyName,
  introduction: state => state.user.introduction,
  userInfo: state => state.user.userInfo, // 用户基础信息
  tenantId: state => state.user.tenantId,
  systemIdAndUrl: state => state.user.systemIdAndUrl,
  tenantCode: state => state.user.tenantCode,
  DICT_S: state => state.dict.dicts,
  obsClient: state => state.obs.obsClient,
  config: state => state.configuration.siteconfig,
  defaultSetting: state => state.configuration.defaultSetting,
  designConfig: state => state.flow.designConfig
}
export default getters
