// 智慧园区 能耗管理
import Layout from '@/components/Layout'
export default [{
  path: '/fireManagement',
  component: Layout,
  hidden: true,
  meta: { title: '消防管理', icon: 'documentation', noCache: true },
  children: [

    {
      path: 'fireControl',
      component: () => import('@/views/fireManagement/fireControl'),
      name: 'fireControl',
      meta: { title: '消防报警', icon: 'documentation', noCache: true }
    },
    {
      path: 'fireControl/list',
      component: () => import('@/views/fireManagement/fireControl/list/index'),
      name: 'fireControl/list',
      meta: { title: '消防报警类型', icon: 'documentation', noCache: true }
    },
    {
      path: 'fireMonitor',
      component: () => import('@/views/fireManagement/fireMonitor/index'),
      name: 'list',
      meta: { title: '消防监控', icon: 'documentation', noCache: true }
    },
    {
      path: 'fireMonitorAdd',
      hidden: true,
      component: () => import('@/views/fireManagement/fireMonitor/add'),
      name: 'fireMonitorAdd',
      meta: { title: '消防监控详情', icon: 'documentation', noCache: true }
    },
    {
      path: 'fireControl/monitor',
      component: () => import('@/views/fireManagement/monitor/index'),
      name: 'fireControl/monitor',
      meta: { title: '报警监控', icon: 'documentation', noCache: true }
    },

    {
      path: 'fireControlByTenant',
      hidden: true,
      component: () => import('@/views/fireManagement/fireControlByTenant'),
      name: 'fireControlByTenant',
      meta: { title: '消防报警', icon: 'documentation', noCache: true }
    },
    {
      path: 'fireControl/add',
      hidden: true,
      component: () => import('@/views/fireManagement/fireControl/add'),
      name: 'fireControl/add',
      meta: { title: '消防报警', icon: 'documentation', noCache: true }
    },
    {
      path: 'fireControl/edit',
      hidden: true,
      component: () => import('@/views/fireManagement/fireControl/edit'),
      name: 'fireControl/edit',
      meta: { title: '消防报警', icon: 'documentation', noCache: true }
    },
    {
      path: 'scoringRules',
      component: () => import('@/views/fireManagement/scoringRules/index'),
      name: 'index',
      meta: { title: '评分规则管理', icon: 'documentation', noCache: true }
    },
    {
      path: 'scoringRules/update',
      hidden: true,
      component: () => import('@/views/fireManagement/scoringRules/update'),
      name: 'update',
      meta: { title: '评分规则设置', icon: 'documentation', noCache: true }
    }

  ]
}
]

