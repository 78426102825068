var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _c("div", { staticClass: "setInfo" }, [
        _c(
          "div",
          { staticClass: "right-menu" },
          [
            _c(
              "el-popover",
              {
                staticClass: "right-menu-item new_item right-menu-margin",
                attrs: {
                  placement: "bottom",
                  width: "326",
                  padding: "0",
                  "visible-arrow": false,
                  trigger: "hover",
                },
                model: {
                  value: _vm.visible,
                  callback: function ($$v) {
                    _vm.visible = $$v
                  },
                  expression: "visible",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "new_item_box" },
                  [
                    _c(
                      "el-menu",
                      {
                        staticClass: "el-menu-demo",
                        attrs: {
                          "default-active": _vm.activeIndex,
                          mode: "horizontal",
                        },
                        on: { select: _vm.handleSelect },
                      },
                      [
                        _c(
                          "el-menu-item",
                          { attrs: { index: "1" } },
                          [
                            _c(
                              "el-badge",
                              {
                                staticClass: "item",
                                staticStyle: {
                                  height: "20px",
                                  "line-height": "20px",
                                },
                                attrs: { "is-dot": _vm.n1 },
                              },
                              [_vm._v(" 通知 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-menu-item",
                          { attrs: { index: "2" } },
                          [
                            _c(
                              "el-badge",
                              {
                                staticClass: "item",
                                staticStyle: {
                                  height: "20px",
                                  "line-height": "20px",
                                },
                                attrs: { "is-dot": _vm.n2 },
                              },
                              [_vm._v(" 消息 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-menu-item",
                          { attrs: { index: "3" } },
                          [
                            _c(
                              "el-badge",
                              {
                                staticClass: "item",
                                staticStyle: {
                                  height: "20px",
                                  "line-height": "20px",
                                },
                                attrs: { "is-dot": _vm.n3 },
                              },
                              [_vm._v(" 工单 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.activeIndex == 1
                  ? _c("div", { staticClass: "tongzhi" }, [
                      _c(
                        "div",
                        { staticClass: "list" },
                        _vm._l(_vm.list1, function (item, index) {
                          return _c(
                            "div",
                            {
                              class: {
                                noborder: index + 1 == _vm.list1.length,
                              },
                            },
                            [
                              _c("p", { staticClass: "p1" }, [
                                item.status == 0 ? _c("span") : _vm._e(),
                                _vm._v(_vm._s(item.title) + " "),
                              ]),
                              _c("p", { staticClass: "p2" }, [
                                _vm._v(_vm._s(item.createDate)),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "bottom" }, [
                        _c(
                          "a",
                          {
                            staticClass: "a1",
                            on: {
                              click: function ($event) {
                                return _vm.readCkeck()
                              },
                            },
                          },
                          [_vm._v("清除未读")]
                        ),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.goRouter(1)
                              },
                            },
                          },
                          [_vm._v("查看全部")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.activeIndex == 2
                  ? _c("div", { staticClass: "tongzhi" }, [
                      _c(
                        "div",
                        { staticClass: "list list2" },
                        _vm._l(_vm.list2, function (item, index) {
                          return _c(
                            "div",
                            {
                              class: {
                                noborder: index + 1 == _vm.list1.length,
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "limg",
                                attrs: {
                                  src: require("@/assets/image/tongzhi.png"),
                                },
                              }),
                              _c("div", [
                                _c("p", { staticClass: "p1" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                _c("p", { staticClass: "p2" }, [
                                  _vm._v(_vm._s(item.createDate)),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "bottom" }, [
                        _c(
                          "a",
                          {
                            staticClass: "a2",
                            on: {
                              click: function ($event) {
                                return _vm.goRouter(2)
                              },
                            },
                          },
                          [_vm._v("查看全部")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.activeIndex == 3
                  ? _c("div", { staticClass: "tongzhi" }, [
                      _c(
                        "div",
                        { staticClass: "list list2 list3" },
                        _vm._l(_vm.list3, function (item, index) {
                          return _c(
                            "div",
                            {
                              class: {
                                noborder: index + 1 == _vm.list1.length,
                              },
                            },
                            [
                              _c("div", { staticClass: "p_box" }, [
                                _c("p", { staticClass: "p1" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                _c("p", { staticClass: "p2" }, [
                                  _vm._v(_vm._s(item.createDate)),
                                ]),
                              ]),
                              item.level == 1
                                ? _c("div", { staticClass: "status_box" }, [
                                    _vm._v("重要"),
                                  ])
                                : _vm._e(),
                              item.level == 2
                                ? _c("div", { staticClass: "status_box c1" }, [
                                    _vm._v("紧急"),
                                  ])
                                : _vm._e(),
                              item.level == 3
                                ? _c("div", { staticClass: "status_box c2" }, [
                                    _vm._v("普通"),
                                  ])
                                : _vm._e(),
                              item.level == 4
                                ? _c("div", { staticClass: "status_box c3" }, [
                                    _vm._v("较低"),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "bottom" }, [
                        _c(
                          "a",
                          {
                            staticClass: "a2",
                            on: {
                              click: function ($event) {
                                return _vm.goRouter(3)
                              },
                            },
                          },
                          [_vm._v("查看全部")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { attrs: { slot: "reference" }, slot: "reference" },
                  [
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        staticStyle: { height: "31px" },
                        attrs: {
                          value: _vm.num == 0 ? null : _vm.num,
                          max: 99,
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "el-dropdown-link newsIcon" },
                          [_c("i", { staticClass: "el-icon-bell" })]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm.type == 2
              ? _c(
                  "el-dropdown",
                  { staticClass: "right-menu-item" },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _vm._v(" " + _vm._s(_vm.company.name)),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      _vm._l(_vm.companyList, function (item) {
                        return _c(
                          "el-dropdown-item",
                          {
                            key: item.id,
                            nativeOn: {
                              click: function ($event) {
                                return _vm.change(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-dropdown",
              {
                staticClass: "avatar-container right-menu-item hover-effect",
                attrs: { trigger: "click" },
              },
              [
                _c("div", { staticClass: "avatar-wrapper" }, [
                  _c("img", {
                    staticClass: "user-avatar",
                    attrs: {
                      src:
                        !_vm.avatar || _vm.avatar.length === 0
                          ? require("@/assets/image/DefaultUserAvatar.png")
                          : _vm.avatar,
                    },
                  }),
                  _c("i", { staticClass: "el-icon-caret-bottom" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/user/profile" } },
                      [_c("el-dropdown-item", [_vm._v("个人中心")])],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.logout($event)
                          },
                        },
                      },
                      [_c("span", [_vm._v("退出登录")])]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.$store.getters.name
          ? _c("div", { staticClass: "corporate" }, [
              _vm._v(" " + _vm._s(_vm.$store.getters.name) + " "),
            ])
          : _c("div", { staticClass: "corporate" }, [_vm._v("欢迎您!")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }