// 访客中心
import Layout from '@/components/Layout'
const systemSettings = {
    path: '/visitOrders',
    component: Layout,
    hidden: true,
    meta: { title: '访单中心', icon: 'list', noCache: true },
    children: [
        // {
        //     path: 'manages',
        //     component: () => import('@/views/visiter/manages/index.vue'),
        //     name: 'SystemSettingHydroelectricity',
        //     meta: { title: '访客管理', icon: 'user', noCache: true }
        // },
        // {
        //     path: 'blacklist',
        //     component: () => import('@/views/visiter/blacklist/index.vue'),
        //     name: 'SystemSettingHydroelectricity',
        //     meta: { title: '黑名单规则', icon: 'user', noCache: true }
        // },
        {
            path: 'management',
            component: () => import('@/views/visitOrders/management/index.vue'),
            name: 'SystemSettingHydroelectricity',
            meta: { title: '访单管理（管理员）', icon: 'user', noCache: true }
        },
        {
            path: 'managements',
            component: () => import('@/views/visitOrders/managements/index.vue'),
            name: 'managements',
            meta: { title: '访单管理（员工）', icon: 'user', noCache: true }
        },
        {
            path: 'managementdetail',
            component: () => import('@/views/visitOrders/managementdetail/index.vue'),
            name: 'managementdetail',
            meta: { title: '详情', icon: 'user', noCache: true }
        },
        {
            path: 'records',
            component: () => import('@/views/visitOrders/records/index.vue'),
            name: 'records',
            meta: { title: '来访记录（管理员）', icon: 'user', noCache: true }
        },
        {
            path: 'recordsstaff',
            component: () => import('@/views/visitOrders/recordsstaff/index.vue'),
            name: 'recordsstaff',
            meta: { title: '来访记录（员工）', icon: 'user', noCache: true }
        },
    ]
}
export default systemSettings