var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _vm.onlyOneChild.meta
                  ? _c(
                      "app-link",
                      {
                        attrs: {
                          to: _vm.resolvePath(_vm.onlyOneChild),
                          "can-to": _vm.isDisabled,
                        },
                      },
                      [
                        _c(
                          "el-menu-item",
                          {
                            class: { "submenu-title-noDropdown": !_vm.isNest },
                            attrs: { index: _vm.resolvePath(_vm.onlyOneChild) },
                          },
                          [
                            _c("item", {
                              attrs: {
                                icon:
                                  _vm.onlyOneChild.meta.icon ||
                                  (_vm.item.meta && _vm.item.meta.icon),
                                title: _vm.onlyOneChild.meta.title,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm.item.children
            ? _c(
                "el-submenu",
                { key: _vm.item.path, attrs: { index: _vm.item.path } },
                [
                  _c("template", { slot: "title" }, [
                    _vm.item.meta.icon
                      ? _c("i", { class: _vm.item.meta.icon })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.item.meta.title))]),
                  ]),
                  _vm._l(_vm.item.children, function (itemChild) {
                    return [
                      itemChild.children &&
                      itemChild.children.length &&
                      !itemChild.hidden
                        ? _c(
                            "el-submenu",
                            {
                              key: itemChild.index,
                              staticClass: "erji",
                              attrs: { index: itemChild.path },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.routeClick(itemChild)
                                },
                              },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                itemChild.meta.icon
                                  ? _c("i", { class: itemChild.meta.icon })
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(_vm._s(itemChild.meta.title)),
                                ]),
                              ]),
                              _vm._l(
                                itemChild.children,
                                function (itemChild_Child) {
                                  return _c(
                                    "el-menu-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !itemChild_Child.hidden,
                                          expression: "!itemChild_Child.hidden",
                                        },
                                      ],
                                      key: itemChild_Child.index,
                                      attrs: { index: itemChild_Child.path },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.routeClick(itemChild_Child)
                                        },
                                      },
                                    },
                                    [
                                      itemChild_Child.meta.icon
                                        ? _c("i", {
                                            class: itemChild_Child.meta.icon,
                                          })
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "title" },
                                          slot: "title",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(itemChild_Child.meta.title)
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : !itemChild.hidden
                        ? _c(
                            "el-menu-item",
                            {
                              key: itemChild.index,
                              attrs: { index: itemChild.path },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.routeClick(itemChild)
                                },
                              },
                            },
                            [
                              itemChild.meta.icon
                                ? _c("i", { class: itemChild.meta.icon })
                                : _vm._e(),
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(itemChild.meta.title))]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }