<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb" class="tabsList">
      <!-- <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
        <span v-if="item.redirect==='noRedirect'||index==levelList.length-1" class="no-redirect">{{
          item.meta.title
        }}</span>
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item> -->
     
       <!--  <div 
          v-for="(item, index) in newRoute"
          :key="item.path + index"
          :item="item"
        >
          <span class="tabItem" v-if="item.meta && !item.hidden" :class="{'active':actIndex==index }" @click="handleClick(item,index)" :style="{background:actIndex==index ?settings.theme:'white'}"><i :class="item.meta.icon"></i>{{
            item.meta.title
          }}</span>
        
        </div> -->
     
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import pathToRegexp from "path-to-regexp";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      levelList: null,
      actIndex:0,
      newRoute:[]
    };
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["permission_routes", "sidebar"]),
  },
  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
    this.newRoute=this.$store.getters.permission_routes.filter(item=>{
      if(item.meta && !item.hidden){
        return item
      }
    })
    this.$store.commit('SET_ROUTESMENU',this.newRoute[0].children)
  },
  methods: {
    handleClick(item,index){
      this.actIndex=index
      console.log(item,index)
      this.$store.commit('SET_ROUTESMENU',item.children)
      if (item.linkUrl) {
        
        window.open(
          item.linkUrl + "?token=" + Cookies.get("nisbos_auth_token")
        );
        this.$router.push('/index')
      }
      console.log(this.$store.getters)
    },
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      const first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [{ path: "/", meta: { title: "首页" } }].concat(matched);
      }
      this.levelList = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
      const length = this.levelList.length;
      if (
        length > 2 &&
        this.levelList[length - 1].meta.title ===
          this.levelList[length - 2].meta.title
      ) {
        this.levelList.splice(length - 1, 1);
      }
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim() === "首页";
    },
    pathCompile(path) {
      const { params } = this.$route;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    },
  },
};
</script>

<style lang="scss" scoped>
.tabsList {
  width: 950px;
  
  display: flex;
  overflow-x: scroll; 	/* 设置x轴可滑动 */
	white-space: nowrap; 
  
  .tabItem{
    cursor: pointer;
    padding: 12px;
    


  }
  .active{
    color: #FFFFFF;
   
  }
}
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
