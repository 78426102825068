import request from '@/utils/request'
import Cookies from 'js-cookie'
// 查询字典数据列表
export function listData(query) {
  return request({
    url: '/admin/system/dict/data/list',
    baseURL: process.env.VUE_APP_SERVICE_API,
    method: 'get',
    params: query
  })
}

// 查询字典数据详细
export function getData(dictCode) {
  return request({
    url: '/admin/system/dict/data/' + dictCode,
    baseURL: process.env.VUE_APP_SERVICE_API,
    method: 'get'
  })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    baseURL: process.env.VUE_APP_APPLET_API,
    url: '/configDictData/dict',
    method: 'post',
    data: {
      'dictType': dictType,
      // 'systemId': Cookies.get('getUserInfoSystemId')
      'systemId': Cookies.get('systemId')
    }
  })
}

// 根据字典类型查询字典数据信息
export function getDictsNew(dictType) {
  return request({
    url: '/admin/common/dict/queryAll/' + dictType,
    baseURL: process.env.VUE_APP_SERVICE_API,
    method: 'get'
  })
}
// 新增字典数据
export function addData(data) {
  return request({
    url: '/admin/system/dict/data',
    baseURL: process.env.VUE_APP_SERVICE_API,
    method: 'post',
    data: data
  })
}

// 修改字典数据
export function updateData(data) {
  return request({
    url: '/admin/system/dict/data',
    baseURL: process.env.VUE_APP_SERVICE_API,
    method: 'put',
    data: data
  })
}

// 删除字典数据
export function delData(dictCode) {
  return request({
    url: '/admin/system/dict/data/' + dictCode,
    baseURL: process.env.VUE_APP_SERVICE_API,
    method: 'delete'
  })
}

// 导出字典数据
export function exportData(query) {
  return request({
    url: '/admin/system/dict/data/export',
    baseURL: process.env.VUE_APP_SERVICE_API,
    method: 'get',
    params: query
  })
}

export function reportExport(data) { // 导出
  return request({
    url: '/admin/system/dict/data/export',
    baseURL: process.env.VUE_APP_SERVICE_API,
    method: 'get',
    params: data
  })
}
