// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-3-1!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-3-2!../../../node_modules/sass-loader/lib/loader.js??ref--9-oneOf-3-3!element-ui/packages/theme-chalk/lib/index.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "/**\n* I think element-ui's default theme color is too light for long-term use.\n* So I modified the default color and you can modify it to your liking.\n**/\n/* theme color */\n/* icon font path, required */\n", ""]);
// Exports
exports.locals = {
	"theme": "#02A4F5"
};
module.exports = exports;
