var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.dom.listType == "text"
      ? _c(
          "div",
          [
            _c(
              "el-upload",
              _vm._b(
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    action: _vm.url,
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "before-remove": _vm.beforeRemove,
                    "on-change": _vm.handleChange,
                    "on-success": _vm.handleSuccess,
                    "before-upload": _vm.beforeUpload,
                    multiple: "",
                    headers: _vm.config,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.formValue,
                  },
                },
                "el-upload",
                _vm.dom,
                false
              ),
              [
                _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                  _vm._v("点击上传"),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.dom.listType == "picture"
      ? _c(
          "div",
          [
            _c(
              "el-upload",
              _vm._b(
                {
                  ref: "upload",
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.url,
                    headers: _vm.config,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess,
                    "before-upload": _vm.beforeAvatarUpload,
                    name: "file",
                  },
                },
                "el-upload",
                _vm.dom,
                false
              ),
              [
                _vm.formValue
                  ? _c("img", {
                      staticClass: "avatar",
                      attrs: { src: _vm.formValue },
                    })
                  : _c("i", {
                      staticClass: "el-icon-plus avatar-uploader-icon",
                    }),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }