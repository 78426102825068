const state = {
  // 字典对象集合
  dicts: {},
}

const mutations = {
  SET_DICT: (state, obj) => {
    state.dicts = { ...state.dicts, [obj.key]: obj.val }
  },
  CLEAR_DICT: (state) => {
    state.dicts = {}
  }
}

const actions = {
  setDict({ commit }, obj) {
    commit('SET_DICT', obj)
  },
  clearDict({ commit }) {
    commit('CLEAR_DICT')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
