// 智慧园区 设备中心
import Layout from '@/components/Layout'
export default {
  path: '/energyManagement',
  component: Layout,
  hidden: true,
  meta: { title: '能源管理', icon: 'documentation', noCache: true },
  children: [
    {
      path: 'process',
      component: () => import('@/views/energyManagement/process/index'),
      name: 'process',
      meta: { title: '生产工序', icon: 'documentation', noCache: true }
    },
    {
      path: 'bill',
      component: () => import('@/views/energyManagement/bill/index'),
      name: 'bill',
      meta: { title: '用能账单', icon: 'documentation', noCache: true }
    },
    {
      path: 'calculation',
      component: () => import('@/views/energyManagement/calculation/index'),
      name: 'calculation',
      meta: { title: '测算单价配置', icon: 'documentation', noCache: true }
    },
    {
      path: 'price',
      component: () => import('@/views/energyManagement/price/index'),
      name: 'price',
      meta: { title: '电月均价管理', icon: 'documentation', noCache: true }
    },
    {
      path: 'meter',
      component: () => import('@/views/energyManagement/meter/index'),
      name: 'meter',
      meta: { title: '空调抄表', icon: 'documentation', noCache: true }
    },
    {
      path: 'newDeviceType',
      component: () => import('@/views/energyManagement/newDeviceType/index'),
      name: 'newDeviceType',
      meta: { title: '设备分类管理', icon: 'documentation', noCache: true }
    },
    {
      path: 'deviceType',
      component: () => import('@/views/energyManagement/deviceType/index'),
      name: 'deviceType',
      meta: { title: '耗能设备类型', icon: 'documentation', noCache: true }
    },
    {
      path: 'applianceType',
      component: () => import('@/views/energyManagement/applianceType/index'),
      name: 'applianceType',
      meta: { title: '计量器具类型', icon: 'documentation', noCache: true }
    },
    {
      path: 'energyWorking',
      component: () => import('@/views/energyManagement/energyWorking/index'),
      name: 'energyWorking',
      meta: { title: '耗能工质', icon: 'documentation', noCache: true }
    },
    {
      path: 'energyWorkingSort',
      hidden: true,
      component: () => import('@/views/energyManagement/energyWorkingSort/index'),
      name: 'energyWorkingSort',
      meta: { title: '耗能工质分类', icon: 'documentation', noCache: true }
    },
    {
      path: 'list',
      component: () => import('@/views/energyManagement/enterpriseInfo/index'),
      name: 'enterpriseList',
      meta: { title: '企业列表', icon: 'documentation', noCache: true }
    },
    {
      path: 'monitor',
      hidden: true,
      component: () => import('@/views/energyManagement/enterpriseInfo/monitor'),
      name: 'enterpriseMonitor',
      meta: { title: '端系统远程监控', icon: 'documentation', noCache: true }
    },
    {
      path: 'hostList',
      component: () => import('@/views/energyManagement/enterpriseInfo/host'),
      name: 'enterpriseHostList',
      meta: { title: '端主机列表', icon: 'documentation', noCache: true }
    },
    {
      path: 'efficiencyAnalysis',
      component: () => import('@/views/energyManagement/efficiencyAnalysis/index'),
      name: 'efficiencyAnalysis',
      hidden: false,
      meta: { title: '生产能效分析', icon: 'documentation', noCache: true }
    },
    {
      path: 'qualityAnalysis',
      component: () => import('@/views/energyManagement/qualityAnalysis/index'),
      name: 'qualityAnalysis',
      hidden: false,
      meta: { title: '电能质量分析', icon: 'documentation', noCache: true }
    }
  ]
}

