
// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/')
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  return format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
}

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handleTree(data, id, parentId, children, rootId) {
  id = id || 'id';
  parentId = parentId || 'parentId';
  children = children || 'children';
  rootId = rootId || Math.min.apply(Math, data.map(item => {
    return +item[parentId]
  })) || 0;

  // 对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data));

  // 循环所有项
  const treeData = cloneData.filter(father => {
    const branchArr = cloneData.filter(child => {
      console.log(child)
      // 当类型值为M也就是菜单时，不显示组件路径字段
      child.menuType && child.menuType === 'M' && (child.component = '');
      // 返回每一项的子级数组
      return father[id] === child[parentId];
    });
console.log(branchArr)
    branchArr.length > 0 ? (father.childrenData = branchArr, father.hasChildren = true) : '';

    // 返回第一层
    return String(father[parentId]) === String(rootId);
  });
  console.log(treeData,data)
  return treeData || data;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handleTree2(data, id, parentId, children, rootId) {
  id = id || 'id';
  parentId = parentId || 'parentId';
  children = children || 'children';
  rootId = rootId || Math.min.apply(Math, data.map(item => {
    return item[parentId];
  })) || 0;
  // 对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data));

  // 循环所有项
  const treeData = cloneData.filter(father => {
    const branchArr = cloneData.filter(child => {
      // 当类型值为M也就是菜单时，不显示组件路径字段
      child.menuType && child.menuType === 'M' && (child.component = '');
      // 返回每一项的子级数组
      return father[id] === child[parentId];
    });
    branchArr.length > 0 ? (father.children = branchArr, father.hasChildren = true) : '';
    // 返回第一层
    // return father[parentId] === rootId;
    return String(father[parentId]) === String(rootId);
  });
  return treeData || data;
}

// 通用下载方法-完整路径
export function downloadFile(fileUrl) {
  const a = document.createElement('a')
  const strArr = fileUrl.split('/')
  const fileName = strArr[strArr.length]
  a.download = fileName || '导出.xls'
  a.href = fileUrl
  a.click()
  a.remove()
}

export function jsons(str) {
  return JSON.stringify(str, function(key, val) {
    if (typeof val === 'function') {
      return val + '';
    }
    return val;
  })
}

export function jsonp(item) {
  return JSON.parse(item, function(k, v) {
    if (v && v.indexOf && v.indexOf('function') > -1) {
      // eslint-disable-next-line no-eval
      return eval('(function(){return ' + v + ' })()')
    }
    return v;
  })
}

// 验证是否为blob格式
export async function blobValidate(data) {
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
}