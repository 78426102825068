<template>
  <div>
    <div v-for="item in dom.dom" :key="item">
      <el-divider content-position="left" style="margin:10px 0">{{ item.title }}</el-divider>

      <lsTableBtns
        v-if="item.topBtn"
        :btns="item.topBtn"
        @btnClick="btnClick"
        @add="btnsOpenAddDialog"
        @edit="btnsOpenEditDialog"
        @delete="btnsOpenConfirm"
        @import="btnsOpenImportDialog"
        @export="btnsOpenConfirm"
        @detail="btnsOpenDetailDialog"
      />

      <vTable ref="table" :table="item" />
    </div>
    <detail-dialog
      ref="dialog-detail"
      @done="table.isThree ? getTreeList() : getList()"
    />

    <add-dialog
      ref="dialog-add"
      @done="table.isThree ? getTreeList() : getList()"
    />

    <import-dialog
      ref="ImportDialog"
      v-bind="importDialogConfig"
      @done="table.isThree ? getTreeList() : getList()"
    />
  </div>
</template>
<script>
import vTable from './vTable.vue'

import detailDialog from './dialog/detailDialog'
import addDialog from './dialog/addDialog'

import { saasRequest } from '../../api/request'
import ImportDialog from '../ImportDialog'
import { getStructure } from '../../api'
import { downloadFile } from '../../api/blob'
// import { downloadFile } from "../../utils/nisbos";
import { jsons } from '@/utils/nisbos'
import Cookies from 'js-cookie'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'FancyTable',
  components: {
    vTable,
    detailDialog,
    addDialog,

    ImportDialog
  },
  props: {
    dom: {
      type: Object,
      default: () => {
        return {}
      }
    },
    value: {
      type: [String, Number, Boolean],
      default: undefined
    }
  },
  data() {
    return {}
  },
  mounted() {
    console.log(this.dom)
  },
  methods: {
    handleLink(obj) {
      console.log(obj)
      const paramsArr = obj.paramsArr
      let params = ''
      if (Array.isArray(paramsArr) && paramsArr.length) {
        paramsArr.forEach((p, index) => {
          if (index === 0) {
            params += '?'
          } else if (index > 0) {
            params += '&'
          }
          console.log(JSON.parse(p).key)
          if (JSON.parse(p).key) {
            params += `${JSON.parse(p).key}=${obj.row[JSON.parse(p).linkKey]}`
          } else {
            params += `${p}=${obj.row[p]}`
          }
        })
      }
      console.log(params)
      // let params = "?" + `${obj.params}=${obj.row[obj.params]}`;
      // this.$router.push(obj.url + params);
    },
    /** 表格内发起详情弹框 */
    openDetailDialog(obj) {
      console.log(obj)
      getStructure(obj).then((res) => {
        console.log(res)
        this.detailDialogDom = res.data
        let data = this.detailDialogDom.data
        if (
          !this.detailDialogDom.data ||
          !Object.keys(this.detailDialogDom.data).length
        ) {
          // 如果没有传数据 则使用表格行的数据
          data = obj.row
          console.log('11')
        }
        console.log(3455, this.detailDialogDom, data)
        this.$refs['dialog-detail'].show(
          data,
          this.detailDialogDom,
          obj,
          this.treeId,
          this.treeObj
        )
      })
    },
    /** 表格内发起详情抽屉 */
    openDrawerDialog(obj) {
      console.log(obj)
      getStructure(obj).then((res) => {
        console.log(res)
        this.detailDialogDom = res.data
        let data = this.detailDialogDom.data
        if (
          !this.detailDialogDom.data ||
          !Object.keys(this.detailDialogDom.data).length
        ) {
          // 如果没有传数据 则使用表格行的数据
          data = obj.row
          console.log('11')
        }
        console.log(3455, this.detailDialogDom, data)
        this.$refs['drawer-detail'].show(
          data,
          this.detailDialogDom,
          obj,
          this.treeId,
          this.treeObj
        )
      })
    },
    /** 表格内发起新增弹框 */
    openAddDialog(obj) {
      getStructure(obj).then((res) => {
        this.addDialogDom = res.data
        let data = this.addDialogDom.data
        console.log(res)
        if (
          !this.addDialogDom.data ||
          !Object.keys(this.addDialogDom.data).length
        ) {
          // 如果没有传数据 则使用表格行的数据
          data = obj.row
        }
        console.log(data)

        this.$refs['dialog-add'].show(
          data,
          this.addDialogDom,
          obj,
          this.treeId,
          this.treeObj
        )
      })
    },
    /** 表格内发起编辑弹框 */
    openEditDialog(obj) {
      console.log(obj)
      if (obj.components) {
        this.comps = []
        this.comps.push(obj.components)
        this.$nextTick(() => {
          console.log(this.$refs)
          this.$refs[obj.components][0].show(
            obj.row.id,
            obj.name,
            obj.row,
            obj
          )
        })
      } else {
        getStructure(obj).then((res) => {
          this.editDialogDom = res.data
          let data = this.editDialogDom.data

          if (
            !this.editDialogDom.data ||
            !Object.keys(this.editDialogDom.data).length
          ) {
            // 如果没有传数据 则使用表格行的数据
            data = obj.row
          }
          console.log(data, this.editDialogDom, obj)
          this.$refs['dialog-add'].show(data, this.editDialogDom, obj)
        })
      }
    },
    btnClick(obj) {
      switch (obj.type) {
      case 'add':
        this.btnsOpenAddDialog(obj)
        break
      case 'link':
        this.btnsOpenLink(obj)
        break
      case 'edit':
        this.btnsOpenEditDialog(obj)
        break
      case 'import':
        this.btnsOpenImportDialog(obj)
        break
      case 'delete':
        this.btnsOpenConfirm(obj)
        break
      case 'confirm':
        this.btnsOpenConfirm(obj)
        break
      case 'export':
        this.btnsOpenConfirm(obj)
        break
      case 'dialog-detail':
        this.btnsOpenDetailDialog(obj)
        break
      default:
        this.btnsOpenAddDialog(obj)
      }
    },
    /** 按钮组详情弹框事件 */
    btnsOpenDetailDialog(obj = {}) {
      console.log(obj)
      getStructure(obj).then((res) => {
        this.detailDialogDom = res.data
        const data = this.detailDialogDom.data
        console.log(res)
        this.$refs['dialog-detail'].show(
          data,
          this.detailDialogDom,
          obj,
          this.treeId,
          this.treeObj
        )
      })
    },
    btnsOpenLink(obj = {}) {
      console.log(obj)
      this.$router.push(obj.url)
    },
    /** 按钮组新增弹框事件 */
    btnsOpenAddDialog(obj = {}) {
      console.log(this.page, obj)
      if (obj.components) {
        this.comps = []
        this.comps.push(obj.components)
        console.log(this.comps, obj)
        this.$nextTick(() => {
          console.log(this.$refs)
          if (obj.batch) {
            console.log(this.selection, this.ids)
            this.$refs[obj.components][0].show(this.selection, this.ids)
          } else {
            this.$refs[obj.components][0].show(null, obj.name, null, obj)
          }
        })
      } else {
        getStructure(obj).then((res) => {
          console.log(res)
          this.addDialogDom = res.data
          const data = this.addDialogDom.data
          console.log(res, this.addDialogDom, data)
          obj.row = this.selectRow || {}
          // if (!this.addDialogDom.data || !Object.keys(this.addDialogDom.data).length) {
          // 如果没有传数据 则使用表格行的数据
          // data = obj.row;
          // }
          if (obj.pageUrl) {
            this.$router.push(obj.pageUrl)
            const pageDom = {
              data: {},
              dom: this.addDialogDom,
              obj
            }
            sessionStorage.setItem('pageDom', jsons(pageDom))
          } else {
            if (!this.treeId && this.page.queryDom.treeDom) {
              this.msgError('请选中组织')
            } else {
              if (this.$route.query) {
                Object.assign(this.treeObj, this.$route.query)
              }
              {
                this.$refs['dialog-add'].show(
                  data,
                  this.addDialogDom,
                  obj,
                  this.treeId,
                  this.treeObj
                )
              }
            }
          }
        })
      }
    },
    /** 按钮组编辑弹框事件 */
    btnsOpenEditDialog(obj = {}) {
      getStructure(obj).then((structureRes) => {
        console.log(structureRes)
        this.editDialogDom = structureRes.data
        let data = this.editDialogDom.data
        obj.row = this.selectRow || {}
        if (
          !this.editDialogDom.data ||
          !Object.keys(this.editDialogDom.data).length
        ) {
          // 如果没有传数据 则使用表格行的数据
          data = obj.row
        }

        if (obj.pageUrl) {
          this.$router.push(obj.pageUrl)
          const pageDom = {
            data,
            dom: this.editDialogDom,
            obj
          }
          sessionStorage.setItem('pageDom', jsons(pageDom))
        } else {
          this.$refs['dialog-add'].show(data, this.editDialogDom, obj)
        }
        // this.$refs['dialog-add'].show(data, this.editDialogDom, rowObj);
      })
    },
    /** 按钮组导入弹框事件 */
    btnsOpenImportDialog(b) {
      this.importDialogConfig = b
      this.$refs.ImportDialog.show()
    },
    urlSplicing(url, obj) {
      // url='http://admin.dev.nisbos.com'+url
      if (url.indexOf('?') == -1) {
        url += '?'
      }
      Object.keys(obj).map((key) => {
        url += key + '=' + obj[key] + '&'
      })
      url = url.slice(0, url.length - 1)
      console.log(url, obj)
      return url
    },

    /** 按钮组确认框事件 */
    btnsOpenConfirm(b = {}) {
      console.log(location.hostname, b)
      let params = this.ids
      if (b.paramsType && b.paramsType.toLowerCase() === 'string') {
        params = {}
        params[b.paramKey || 'ids'] = this.ids.join(',')
      } else if (b.paramsType && b.paramsType.toLowerCase() === 'array') {
        params = {}
        if (b.paramKey) {
          params[b.paramKey] = this.ids
        } else {
          params = this.ids
        }
      } else if (b.type === 'export') {
        params = this.queryParams
        console.log(params)
      } else if (b.name === '仅获取用户') {
        params = this.queryParams
        b.api = b.domApi
      }
      if (b.paramStatus) {
        Object.assign(params, b.paramStatus)
      }
      if (b.routers) {
        const systemIds = Cookies.get('systemIds')
        Object.assign(params, { systemId: systemIds })
      }
      console.log(b, params)
      if (b.type === 'export') {
        // window.open(this.urlSplicing(b.url, params));
        // downloadFile(this.urlSplicing(b.url, params) );
        let fileName = ''
        if (b.tipApi) {
          saasRequest({ api: b.tipApi, method: 'post' }).then((res) => {
            fileName = res.data
            this.$message({
              message: res.message,
              type: 'success'
            })
          })
        }
        downloadFile(this.urlSplicing(b.url, params)).then((res) => {
          console.log(res)
          const blob = new Blob([res.data], {
            type: 'application/xls'
          }) // type为所需要下载的文件格式，以xls文件为例
          console.log(blob)
          const link = document.createElement('a') // 创建a标签
          link.style.display = 'none'
          const objectUrl = URL.createObjectURL(blob)
          link.href = objectUrl
          link.download = fileName || '导出'
          link.click()
          URL.revokeObjectURL(objectUrl)
        })
      } else {
        this.$confirm(b.msg || '是否要执行该操作?', b.title || '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'primary'
        })
          .then(() => {
            console.log(b, params)
            if (b.type == 'download') {
              const arr = JSON.parse(JSON.stringify(params))
              arr.ids = arr.ids.join(',')
              downloadFile(b.api, arr).then((res) => {
                console.log(res)
                const blob = new Blob([res.data], {
                  type: 'application/zip'
                }) // type为所需要下载的文件格式，以xls文件为例
                console.log(blob)
                const link = document.createElement('a') // 创建a标签
                link.style.display = 'none'
                const objectUrl = URL.createObjectURL(blob)
                link.href = objectUrl
                link.download = '代码'
                link.click()
                URL.revokeObjectURL(objectUrl)
              })
            } else {
              return saasRequest(b, params)
            }
          })
          .then((response) => {
            this.msgSuccess()
            {
              if (b.name === '仅获取用户') {
                this.$refs.table.resetQuery()
              } else {
              // this.$refs.table.getList(this.table.isThree);
              }
            }
          })
      }
    }
  }
}
</script>
