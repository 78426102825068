<template>
  <div class="demo-block" v-if="queryDom.dom">
    <el-form
      ref="queryForm"
      :model="form"
      :inline="true"
      :label-width="form.labelWidth || '90px'"
     
    >
     <el-row  style="display: flex; flex-wrap: wrap">
      <template v-for="(s, index) in queryDom.dom">
        <template v-if="index < 3">
          <saas-form-item
            v-if="!evalHide(s.hide)"
            :key="index"
            v-model="form[s.prop]"
            :form="form"
            :dom="s"
          />
        </template>
        <template v-else>
          <saas-form-item
            v-if="!evalHide(s.hide) && expand"
            :key="index"
            v-model="form[s.prop]"
            :form="form"
            :dom="s"
          />
        </template>
      </template>
      <el-col :span="8" style="text-align: right; flex-grow: 1">
        <el-form-item
          v-if="queryDom.dom"
          
        >
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-col>
     </el-row>
    </el-form>
    <div
      class="demo-block-control"
      v-if="queryDom.dom.length > 3"
      @click="expandQuery"
      :tooltip="title"
    >
      <i :class="expand ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
import searchMixin from "@/mixins/search";
import saasFormItem from "./saasFormItem";

export default {
  components: {
    saasFormItem,
  },
  mixins: [searchMixin],
  props: {
    // 表格的属性
    table: {
      type: Object,
      default: () => ({}),
    },
    queryDom: {
      type: Object,
      default: () => ({}),
    },
    // 是否为双列表页
    double: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 查询参数
      form: {
        currentPage: 1,
        pageSize: 20,
      },
      title: "收起",
      expand: true,
    };
  },
  created() {},
  mounted() {
    if (this.double) {
      // 双列表页初次请求由左列表发起
      return;
    }
    this.$nextTick(() => {
      this.resetQuery();
      this.query();
    });
  },
  watch: {},
  queryDom: {
    handler(v) {
      console.log(v);
    },
    immediate: true,
    deep: true,
  },
  methods: {
    init(dom) {
      this.queryDom = dom;
    },
    /** 设置默认值 */
    setDefault() {
      const queryDom = this.queryDom.dom || [];
      queryDom.forEach((dom) => {
        this.$set(this.form, dom.prop, dom.value || null);
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      for (let i in this.form) {
        if (this.form[i] === "") {
          this.$set(this.form, i, null);
        }
      }

      console.log("yehua");
      this.$refs.queryForm.validate((valid) => {
        if (valid) {
          this.form.currentPage = 1;
          this.$emit("query", this.form);
        }
      });
    },
    /** 重置按钮操作 */
    resetQuery() {
      // 表单校验通过
      this.resetForm("queryForm");

      this.form = { pageSize: 20, currentPage: 1 };
      if (this.$route.query) {
        Object.assign(this.form, this.$route.query);
      }
      this.setDefault();
      this.$bus.$emit("reset");
      this.$refs.queryForm.validate((valid) => {
        if (valid) {
          this.query();
        }
      });
    },
    query() {
      this.form.currentPage = 1;
      if (this.table.isThree) {
        setTimeout(() => {
          // 这里需要触发获取树状表格
          this.$emit("queryTreeList");
        });
      } else {
        this.handleQuery();
      }
    },
    evalHide(str) {
      // eslint-disable-next-line no-eval
      return eval(str);
    },
    expandQuery() {
      if (this.expand) {
        this.expand = false;
        this.title = "展开";
      } else {
        this.expand = true;
        this.title = "收起";
      }
    },
  },
};
</script>
<style>
.demo-block {
  border-radius: 3px;
  transition: 0.2s;
}
.demo-block.hover {
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
    0 2px 4px 0 rgba(232, 237, 250, 0.5);
}
.demo-block code {
  font-family: Menlo, Monaco, Consolas, Courier, monospace;
}
.demo-block .demo-button {
  float: right;
}
.demo-block .source {
  padding: 24px;
}
.demo-block .meta {
  background-color: #fafafa;
  border-top: 1px solid #eaeefb;
  overflow: hidden;
  height: 0;
  transition: height 0.2s;
}
.demo-block .description {
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  font-size: 14px;
  line-height: 22px;
  color: #666;
  word-break: break-word;
  margin: 10px;
  background-color: #fff;
}
.demo-block .description p {
  margin: 0;
  line-height: 26px;
}
.demo-block .description code {
  color: #5e6d82;
  background-color: #e6effb;
  margin: 0 4px;
  display: inline-block;
  padding: 1px 5px;
  font-size: 12px;
  border-radius: 3px;
  height: 18px;
  line-height: 18px;
}
.demo-block .highlight pre {
  margin: 0;
}
.demo-block .highlight code.hljs {
  margin: 0;
  border: none;
  max-height: none;
  border-radius: 0;
}
.demo-block .highlight code.hljs:before {
  content: none;
}
.demo-block .demo-block-control {
  border-top: 1px solid #eaeefb;
  height: 44px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  margin-top: -1px;
  color: #d3dce6;
  cursor: pointer;
  position: relative;
}
.demo-block .demo-block-control.is-fixed {
  position: fixed;
  bottom: 0;
  width: 868px;
}
.demo-block .demo-block-control i {
  font-size: 16px;
  line-height: 44px;
  transition: 0.3s;
}
.demo-block .demo-block-control i.hovering {
  transform: translateX(-40px);
}
.demo-block .demo-block-control > span {
  position: absolute;
  font-size: 14px;
  line-height: 44px;
  transition: 0.3s;
  display: inline-block;
}
.demo-block .demo-block-control:hover {
  color: #409eff;
  background-color: #f9fafc;
}
.demo-block .demo-block-control .text-slide-enter,
.demo-block .demo-block-control .text-slide-leave-active {
  opacity: 0;
  transform: translateX(10px);
}
.demo-block .demo-block-control .control-button {
  line-height: 26px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 25px;
}
</style>