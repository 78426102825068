import axios from 'axios'
import Cookies from 'js-cookie'
import { getToken, removeToken, getSystemId } from '@/utils/auth'
const path = Cookies.get('url')
// console.log(path)
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers['x-app-id'] = '476449634043998215';
// axios.defaults.headers['skipTokenAuthorize'] = true

// axios.defaults.headers['x-user-id'] = '476501801144520705'
// axios.defaults.headers['x-app-id'] = '511610133850107907'
// axios.defaults.headers['x-tenant-id'] = '476501800058195973'
const api = axios.create({
  baseURL: window.location.protocol + '//' + path, // 请求的公共地址部分
  timeout: 7000 // 请求超时时间 当请求时间超过5秒还未取得结果时 提示用户请求超时
})
// interceptors axios的拦截器对象
api.interceptors.request.use(config => {
  // console.log(config)
  if (getSystemId()) {
    config.headers['systemId'] = getSystemId()
  } else {
    config.headers['systemId'] = '0'
  }

  if (getToken()) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  if (config.url.indexOf('niscommon') !== -1) { // url带有niscommon跳过权限判断
    config.headers['x-noauth'] = true
  }
  config.responseType = 'blob'
  // config 请求的信息
  return config // 将配置完成的config对象返回出去 如果不返回 请求则不会进行
}, err => {
  // 请求发生错误时的处理 抛出错误
  Promise.reject(err)
})
api.interceptors.response.use(res => {
  // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
  // res 是所有相应的信息
  // console.log(res)
  return Promise.resolve(res)
}, err => {
  // 服务器响应发生错误时的处理
  Promise.reject(err)
})
export default api
