import { render, staticRenderFns } from "./AppMain.vue?vue&type=template&id=216fa566&scoped=true&"
import script from "./AppMain.vue?vue&type=script&lang=js&"
export * from "./AppMain.vue?vue&type=script&lang=js&"
import style0 from "./AppMain.vue?vue&type=style&index=0&id=216fa566&lang=scss&scoped=true&"
import style1 from "./AppMain.vue?vue&type=style&index=1&id=216fa566&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216fa566",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/nisems/front/nisbos-front-admin/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('216fa566')) {
      api.createRecord('216fa566', component.options)
    } else {
      api.reload('216fa566', component.options)
    }
    module.hot.accept("./AppMain.vue?vue&type=template&id=216fa566&scoped=true&", function () {
      api.rerender('216fa566', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Layout/components/AppMain.vue"
export default component.exports