<template>
  <div class="portal-layout-main">
    <div class="head-menu">
      <div v-loading="loadingMenu" class="menu-left">
        <!-- /portal/portalHomepage -->
        <div
          class="btn-text"
          :class="{'checked':isChecked(rootMenu)}"
          @click="toPortalPortalList(rootMenu)"
        >
          {{ rootMenu.cateName }}
        </div>
      </div>
      <div class="menu-middle">
        <div
          v-for="item in menuList"
          :key="item.id"
          class="btn-text menu-btn"
          :class="{'checked':isChecked(item)}"
          @click="toPortalPortalList(item)"
        >
          {{ item.cateName }}
        </div>
      </div>
      <div class="menu-right">
        <el-button type="text" @click="toSmartPark">前往智慧园区</el-button>
      </div>
    </div>
    <!-- <div style="height:70px" /> -->
    <div class="main-content">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="cachedViews">
          <!-- key有碍缓存页面    -->
          <router-view :key="key" />
          <router-view />
        </keep-alive>
      </transition>
    </div>
    <div class="footer">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="footer-title">智慧园区</div>
          <div class="footer-text">
            <div class="footer-text-item">CopyRight @ 2017-2022 乐石科技有限公司 闽ICP备15001146号</div>
            <div>地址：厦门市思明区桃园路16号2302室</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="footer-title">联系方式</div>
          <div class="footer-text">
            <div class="footer-text-item">联系电话：400-6869-666</div>
            <div>联系邮箱：zyyq@qq.com</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  infoFirstCateList
} from '@/api/portalContent'
import { getDefaultParkInfo } from '@/api/gateway'

// import logoImg from '@/assets/logo/new-logo-sc.png'
import iconImg from '@/assets/logo/favicon.png'
import logoImg from '@/assets/logo/newS-logo.png'
//import iconImg from '@/assets/logo/favicon.ico'


export default {
  data() {
    return {
      menuList: [],
      loadingMenu: false,
      rootMenu: { id: 0, cateName: '首页' },
      checkedList: [],
      icon:iconImg,
      logo:logoImg
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  },
  created() {
    this.getPark()
    this.getMenuList()
  },
  methods: {
    getPark(){
      getDefaultParkInfo().then(res=>{
        if(res.data){
          this.icon = res.data.icon?res.data.icon:iconImg
          this.logo = res.data.logo?res.data.logo:logoImg
          const link = document.querySelector("link[rel~='icon']");
          link.href = this.icon;
          sessionStorage.setItem('iconUrl',res.data.icon?this.icon:'')
          sessionStorage.setItem('logoUrl',res.data.logo?this.logo:'')
        }
      })
    },
    getMenuList() {
      this.loadingMenu = true
      infoFirstCateList().then(res => {
        if (res.success !== true) {
          this.menuList = []
          this.checkedList = []
          this.$message.error(res.message)
          return
        }
        this.menuList = res.data
        this.checkedList = [this.rootMenu]
      }).finally(() => {
        this.loadingMenu = false
      })
    },
    toPortalPortalList(item) {
      this.checkedList = [item]
      if (item.id === this.rootMenu.id) {
        this.$router.push({
          path: '/portal/portalHomepage'
        })
        return
      }
      this.$router.push({
        path: '/portal/portalList',
        query: {
          id: item.id,
          cateName: item.cateName
        }
      })
    },
    toSmartPark() {
      window.open('https://qiye.jjsanchuang.com/')
      // window.open('http://sanchuang.nispark-dev.nistone.com/')
      // var host = window.location.host
      // var path = 'http://sanchuang.nispark-dev.nistone.com/'
      // if(host ==='sc.jjsanchuang.com')path = 'https://qiye.jjsanchuang.com/'
      // console.log(window.location)
    },
    isChecked(item) {
      var findData = this.checkedList.findIndex(x => x.id === item.id)
      return findData !== -1
    }
  }
}
</script>

<style lang="scss" scoped>
.portal-layout-main{
  width: 100%;
  // height: 100%;
  // padding-top: 250px;
  .main-content{
    width: 100%;
    // max-height: calc(100vh - 70px);
    // height: 100%;
    // overflow: auto;
    // height: 100%;
    // margin-top:70px;
    padding-top: 70px;
    padding-bottom: 130px;
  }
  .footer{
    position: fixed;
    left: 0;
    bottom: -70px;
    width: 100%;
    height: 200px;
    padding: 0 100px;
    background-color: rgb(78,78,78);
    margin-top: 16px;
    .footer-title{
      font-size: 16px;
      color: #F2F2F2;
      padding: 20px 0;
    }
    .footer-text{
      color: #D7D7D7;
      font-size: 14px;
      .footer-text-item{
        margin-bottom: 10px;
      }
    }
  }
}
.head-menu{
  position: fixed;
  display: flex;
  width: 100%;
  height: 70px;
  padding-left: 100px;
  box-sizing: border-box;
  left: 0;
  top: 0;
  z-index: 1500;
  opacity: 1;
  background: #fff;
  .menu-left,.menu-right,.menu-middle{
    display: flex;
    align-items: center;
  }
  .menu-left,.menu-right{
    flex: 0 0 auto;
  }
  .menu-middle{
    flex: 1 1 auto;
    overflow: auto;
    .menu-btn{
      margin-left: 35px;
      flex: 0 0 auto;
    }
  }
  .menu-right{
    flex: 0 0 auto;
    padding: 0 20px;
  }
}
.btn-text{
  // color: #409eff;
  cursor: pointer;
  transition: 0.1s;
  padding: 12px 0;
  &:hover{
    color: #64b0fc;
  }
  &:active{
    color: #2b95ff;
  }
}
.checked{
  color: #409eff;
}
/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        // background-color: rgba(0,0,0,.2);
    }

    /*定义滚动条轨道
 内阴影+圆角*/
    ::-webkit-scrollbar-track
    {
        // -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
        // border-radius:10px;
        background-color:#fff;
    }
    /*定义滑块
     内阴影+圆角*/
    ::-webkit-scrollbar-thumb
    {
        border-radius:10px;
        // -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgb(199,201,204);
    }
</style>
