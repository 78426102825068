var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        _vm._l(_vm.dom.dom, function (d, index) {
          return _c(
            "span",
            { key: index },
            [
              _c("lsSelect", {
                attrs: { dom: d },
                on: { change: _vm.changeStatus },
                model: {
                  value: _vm.form[d.prop],
                  callback: function ($$v) {
                    _vm.$set(_vm.form, d.prop, $$v)
                  },
                  expression: "form[d.prop]",
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm.dom.filter
        ? _c("el-input", {
            staticStyle: { width: "85%" },
            attrs: { placeholder: "输入关键字进行过滤" },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          })
        : _vm._e(),
      _c(
        "el-tree",
        _vm._b(
          {
            ref: "tree",
            attrs: {
              data: _vm.data,
              props: _vm.dom.props || { children: "children", label: "label" },
              "node-key": _vm.dom.nodeKey || "id",
              "show-checkbox": _vm.dom.showCheckbox,
              "filter-node-method": _vm.filterNode,
              indent: 5,
            },
            on: {
              "node-click": _vm.handleNodeClick,
              "check-change": _vm.checkChange,
            },
          },
          "el-tree",
          _vm.dom,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }