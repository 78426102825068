// import { getInfo } from '@/apiOld/login';

import { getToken, setToken, removeToken } from '@/utils/auth'
// import { getUserRoles } from '@/apiOld/orgAndUser/role';
import { loginByPhone, login, getUserInfo,updateToken } from '@/api/login'
import Cookies from 'js-cookie'
import { cookieKey } from '@/utils'
import { saasRequest } from '../../../ls-form/api/request'
const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    tenantId: '',
    permissions: [],

    companyName: '',
    tenantCode: '',
    userInfo: {}, // 用户信息  包含了 name , companyName  userCode
    systemIdAndUrl: [],
    systemIdAndUrl1: {},
    companyList: [],
    company: {},
    configSystem: {},
    btnPermission: {}
  },

  mutations: {
    setConfigSystem: (state, list) => {
      state.configSystem = list
    },
    setBtnPermission: (state, list) => {
      state.btnPermission = list
    },
    setCompanyId: (state, list) => {
      state.company = list
    },
    setCompany: (state, list) => {
      state.companyList = list
    },
    setUrl: (state, url) => {
      state.systemIdAndUrl = url
    },
    setUrl1: (state, url) => {
      // console.log(url)
      state.systemIdAndUrl1 = url
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_TENANTID: (state, tenantId) => {
      state.tenantId = tenantId
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_COMPANY_NAME: (state, companyName) => {
      state.companyName = companyName
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_TENANT_CODE: (state, tenantCode) => {
      state.tenantCode = tenantCode
    }
  },

  actions: {
    // 刷新token
    refreshToken({ commit }) {
      return new Promise(reslove => {
        updateToken({ refreshToken: true }).then(res => {
          Cookies.set('nisbos_auth_token', res.data)
          window.location.reload()
          reslove(res)
        })
      })
    },
    setUrls: ({ commit }, url) => {
      commit('setUrl', url)
    },
    newlogin({ commit }, res) {
      // const userCode = userInfo.userCode.trim();
      // const usePwd = userInfo.usePwd;
      // const tenantCode = userInfo.tenantCode;
      // const domain = 'ehs-www.develop.nisbos.com'
      // 1.账号登录 2.验证码登录
      const loginType = 1
      return new Promise((resolve, reject) => {
        setToken(res.ssoToken) // token
        Cookies.set('nisbos_auth_token', res.ssoToken)
        //新增续签token
        Cookies.set('refreshToken', res.refreshToken)
        commit('SET_TOKEN', res.ssoToken) // token
        if (Cookies.get('url') != 'api.admin.nisbos.com') {
          saasRequest(
            { api: '/niscommon/getRoutersPermission', method: 'post' }

          ).then((ress) => {
            commit('setBtnPermission', ress.data)
          })
          saasRequest(
            { api: '/orgService/getSystemIdAndUrl', method: 'post' },
            { serviceName: '' }
          ).then((res) => {
            commit('setUrl', res.data) // token
          })
          saasRequest({
            api: '/orgService/getSystemFrontUrl',
            method: 'post'
          }, {
            serviceName: ''
          }).then((ress) => {
            commit('setUrl1', ress.data) // token
          })
          saasRequest(
            { api: '/configSystem/queryConfigSystem', method: 'post' },
            {}
          ).then((res) => {
            if (res.success) {
              commit('setConfigSystem', res.data) // token
            }
          })
        }
        resolve()
      })
    },

    // 使用账号密码登录
    Login({ commit }, userInfo) {
      const userCode = userInfo.userCode.trim()
      const usePwd = userInfo.usePwd
      const tenantCode = userInfo.tenantCode
      // 1.账号登录 2.验证码登录
      const loginType = 1
      return new Promise((resolve, reject) => {
        login({ tenantCode, usePwd, userCode, loginType })
          .then((res) => {
            // console.log("11111111", res);
            setToken(res.data.data) // token
            Cookies.set('nisbos_auth_token', res.data.data)
            commit('SET_TOKEN', res.data) // token
            // console.log(Cookies.get('nisbos_auth_token'))
            if (Cookies.get('url') != 'api.admin.nisbos.com') {
              saasRequest(
                { api: '/niscommon/getRoutersPermission', method: 'post' }

              ).then((ress) => {
                commit('setBtnPermission', ress.data)
              })
              saasRequest(
                { api: '/orgService/getSystemIdAndUrl', method: 'post' },
                { serviceName: '' }
              ).then((res) => {
                // console.log(this.$store)
                commit('setUrl', res.data) // token

                // console.log(res);
              })
              saasRequest(
                { api: '/configSystem/queryConfigSystem', method: 'post' },
                { }
              ).then((res) => {
                if (res.success) {
                  commit('setConfigSystem', res.data) // token
                }
              })
            }

            resolve()
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },

    /**
     * 使用手机号码进行登录
     * @param telLoginForm  Object
     * */
    telLogin({ commit }, telLoginForm) {
      return new Promise((resolve, reject) => {
        loginByPhone(telLoginForm)
          .then((res) => {
            setToken(res.data) // token
            commit('SET_TOKEN', res.data) // token
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((res) => {
            resolve(res)
            const roles = ['ROLE_DEFAULT']
            commit('SET_ROLES', roles)
            const data = JSON.parse(res.data)
            console.log('userInfo',data);
            localStorage.setItem('userInfo',JSON.stringify(res.data))
            // console.log('userInfo',data);
            // Cookies.set('userInfo',data)
            Cookies.set('companyId',data.companyId)
            commit('SET_NAME', data.userName)
            commit('SET_USER_INFO', data)
            // const result = res.data || {}
            // if (!result.saasUser) {
            //   reject('获取用户信息异常,请重新登录');
            // }
            // const userInfo = result.saasUser;
            // const avatar = require('@/assets/image/user.png')
            // // const saasEmployee = result.saasEmployee || {}
            // result.saasUser.tenantName = result.tenantName
            // commit('SET_PERMISSIONS', result.permissions || []);
            // commit('SET_NAME', userInfo.userName);
            commit('SET_AVATAR', data.photo)
            // commit('SET_COMPANY_NAME', result.companyName)
            // // commit('SET_EMPLOYEE_ID', saasEmployee.id)
            // // commit('SET_ORG_ID', saasEmployee.orgId)
            // // commit('SET_SAAS_EMPLOYEE', saasEmployee)
            // // commit('SET_SASS_USER', result.saasUser)
            // // if (!Array.isArray(roles) || !roles.length) {
            // // 暂时写死
            // const roles = ['ROLE_DEFAULT']
            // // }
            // commit('SET_ROLES', roles);

            // resolve(res);
          })
          .catch((error) => {
            console.log(error)
            removeToken()
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        Cookies.set('serviceId', '')
        Cookies.set('companyId', '')
        // Cookies.set('userInfo', '')
        localStorage.removeItem('userInfo')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
