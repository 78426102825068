// 智慧园区 设备中心
import Layout from '@/components/Layout'
export default {
  path: '/equipmentManagement',
  component: Layout,
  hidden: true,
  meta: { title: '设备中心', icon: 'documentation', noCache: true },
  children: [

    {
      path: 'equipmentByTenant',
      hidden: true,
      component: () => import('@/views/equipmentManagement/equipmentByTenant'),
      name: 'equipmentByTenant',
      meta: { title: '设备', icon: 'documentation', noCache: true }
    },

    {
      path: 'equipment/add',
      hidden: true,
      component: () => import('@/views/equipmentManagement/equipment/add'),
      name: 'equipment/add',
      meta: { title: '新增设备', icon: 'documentation', noCache: true }
    },

    {
      path: 'ability/update',
      hidden: true,
      component: () => import('@/views/equipmentManagement/ability/update'),
      name: 'ability/update',
      meta: { title: '设备能力', icon: 'documentation', noCache: true }
    },
    {
      path: 'ability/add',
      hidden: true,
      component: () => import('@/views/equipmentManagement/ability/add'),
      name: 'ability/add',
      meta: { title: '设备能力', icon: 'documentation', noCache: true }
    },
    {
      path: 'ability/order',
      hidden: true,
      component: () => import('@/views/equipmentManagement/ability/order'),
      name: 'ability/add',
      meta: { title: '设备指令列表', icon: 'documentation', noCache: true }
    },
    {
      path: 'ability/addOrder',
      hidden: true,
      component: () => import('@/views/equipmentManagement/ability/addOrder'),
      name: 'ability/add',
      meta: { title: '设备指令详情', icon: 'documentation', noCache: true }
    },

    {
      path: 'eventEquipment/add',
      hidden: true,
      component: () => import('@/views/equipmentManagement/eventEquipment/add'),
      name: 'eventEquipment/add',
      meta: { title: '设备事件配置', icon: 'documentation', noCache: true }
    },

    {
      path: '/deviceGroup',
      hidden: true,
      component: () => import('@/views/kongtiaogroup/userGrouping/index'),
      name: 'deviceGroup',
      meta: { title: '设备组别', icon: 'documentation', noCache: true }
    },
    {
      path: '/deviceGroup/add',
      hidden: true,
      component: () => import('@/views/kongtiaogroup/userGrouping/add.vue'),
      name: 'deviceGroupAdd',
      meta: { title: '设备组别详情', icon: 'documentation', noCache: true }
    },
    {
      path: '/deviceGroup/addDevice',
      hidden: true,
      component: () => import('@/views/kongtiaogroup/userGrouping/addDevice.vue'),
      name: 'deviceGroupAddDevice',
      meta: { title: '组别设备详情', icon: 'documentation', noCache: true }
    },

    {
      path: 'ability',
      hidden: true,
      component: () => import('@/views/equipmentManagement/ability'),
      name: 'ability',
      meta: { title: '设备能力', icon: 'documentation', noCache: true }
    },
    {
      path: 'region',
      hidden: true,
      component: () => import('@/views/equipmentManagement/region'),
      name: 'region',
      meta: { title: '区域配置', icon: 'documentation', noCache: true }
    },
    {
      path: 'equipment',
      hidden: true,
      component: () => import('@/views/equipmentManagement/equipment'),
      name: 'equipment',
      meta: { title: '智能设备', icon: 'documentation', noCache: true }
    },
    {
      path: 'dataCollection',
      hidden: true,
      component: () => import('@/views/equipmentManagement/dataCollection'),
      name: 'dataCollection',
      meta: { title: '采集数据', icon: 'documentation', noCache: true }
    },
    {
      path: 'monitor',
      hidden: true,
      component: () => import('@/views/equipmentManagement/monitor'),
      name: 'monitor',
      meta: { title: '设备监控', icon: 'documentation', noCache: true }
    },
    {
      path: 'brand',
      hidden: true,
      component: () => import('@/views/equipmentManagement/brand'),
      name: 'brand',
      meta: { title: '品牌配置', icon: 'documentation', noCache: true }
    },
    {
      path: 'equipmentLog',
      hidden: true,
      component: () => import('@/views/equipmentManagement/equipmentLog'),
      name: 'equipmentLog',
      meta: { title: '设备指令日志', icon: 'documentation', noCache: true }
    },
    {
      path: 'eventLog',
      hidden: true,
      component: () => import('@/views/equipmentManagement/eventLog'),
      name: 'eventLog',
      meta: { title: '设备事件日志', icon: 'documentation', noCache: true }
    }
  ]
}

