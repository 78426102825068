import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import Message from 'vue-m-message'
import 'vue-m-message/dist/index.css'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import 'element-ui/lib/theme-chalk/index.css'
import './permission' // permission control
import './assets/icons' // icon
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/new-themes.scss' // global css
import '@/assets/styles/nisbos.scss' // nisbos css
import copy from '@/components/copy'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
Vue.component('RecycleScroller', RecycleScroller)
import store from './store'
// import element from './components/element/index'
import NexusnisbosUi from 'nexus-nisbos-ui'
// nisbos-www 全局方法
import { getDicts } from '@/api/migrate_NISBOS-WWW/energy/system/dict/data'
// 引入UI样式
import 'nexus-nisbos-ui/dist/nexus-nisbos-ui.css'
// 使用UI库
Vue.use(NexusnisbosUi)
// 引入自定义图标库
import '@/assets/lspc_icon/iconfont.css'
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue'
import { parseTime, resetForm } from '@/utils'
import { selectDictLabel } from '@/utils/ruoyi'
import Pagination from '@/components/Pagination'
import permission from './directive/permission'
// 自定义表格工具扩展
import RightToolbar from '@/components/RightToolbar'
import { handleTree } from '@/utils/nisbos'

import xss from 'xss'
import '@/utils/promiseAll'
import qs from 'qs'
Vue.prototype.$qs = qs
import md5 from 'js-md5'
Vue.prototype.$md5 = md5

import Print from 'vue-print-nb'
Vue.use(Print)

import { accDiv, accMul, accAdd } from '@/utils/index'
// 引入自定义图标库
import '@/assets/lspc_icon/iconfont.css'

import VueClipboard from 'vue-clipboard2'
import VFormRender from '../lib/vform/VFormDesigner.umd.min'
import '../lib/vform/VFormDesigner.css'

Number.prototype.div = function(arg) {
  return accDiv(this, arg)
}

Number.prototype.mul = function(arg) {
  return accMul(arg, this)
}

Number.prototype.add = function(arg) {
  return accAdd(arg, this)
}

import formDialog from '@/components/dialog/formDialog'

Vue.component('formDialog', formDialog)

import ElementUI from 'element-ui' // element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'// element-ui的css
Vue.use(ElementUI) // 使用elementUI
// import '@/assets/theme/index.css'
// 引入字典
import { dictoryList } from '@/utils/dictory'
Vue.prototype.$dictionary = dictoryList
Vue.prototype.selectDictLabel = selectDictLabel
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts
Vue.prototype.getDicts = getDicts // nisbos-www 全局方法

Vue.prototype.$xss = xss

Vue.use(OverlayScrollbarsPlugin)
Vue.use(Message, { name: 'msg' }) // will mount `Vue.prototype.$msg`

Vue.use(VFormRender) // 注册v-form-render等组件
Vue.use(VueClipboard)

// Vue.use(element)
import fancyTable from '../ls-form/components/saas/fancyTable'
// 注册为全局组件
Vue.component('fancyTable', fancyTable)

Vue.config.productionTip = false

Vue.prototype.resetForm = resetForm
Vue.prototype.handleTree = handleTree
Vue.prototype.parseTime = parseTime

Vue.prototype.$bus = new Vue()
Vue.mixin({ // 全局混入消息提示方法
  methods: {
    msgSuccess(msg = '操作成功') {
      this.$message({ showClose: true, message: msg, type: 'success' })
    },
    msgError(msg) {
      this.$message({ showClose: true, message: msg, type: 'error' })
    },
    msgInfo(msg) {
      this.$message.info(msg)
    },
    /**
     * 复制内容到剪切板
     * @param text 要复制的文本
     * @param showMsg 复制成功后是否成功提示
     * */
    copy(text, showMsg = true) {
      let copyInput = document.getElementById('copyInput')
      if (!copyInput) {
        copyInput = document.createElement('input')
        copyInput.id = 'copyInput'
        copyInput.style.position = 'fixed'
        copyInput.style.top = '-9999px'
        copyInput.style.opacity = '0'
        copyInput.style.zIndex = '-1'
        document.body.append(copyInput)
      }
      try {
        copyInput.value = text
        copyInput.select() // 选择对象
        document.execCommand('Copy') // 执行浏览器复制命令
        if (showMsg) {
          this.msgSuccess('复制成功！')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
})
Vue.prototype.resSetItemLogo = (key, newVal) => {
  if (key === 'playFlagStateLogo') { 
  //监听本地存储 key为 playFlagState时 创建新的监听事件
      var newStorageEvent = document.createEvent('StorageEvent')
        const storage = {
            setItem: function (k, val) {
              //执行会话存储
              sessionStorage.setItem(k, val)
              newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
              window.dispatchEvent(newStorageEvent)
            }
        }
      return storage.setItem(key, newVal)
  }

}



import { Carousel, carouselItem } from 'element-ui'// 导入app组件
Vue.component(Carousel.name, Carousel)
Vue.component(carouselItem.name, carouselItem)

Vue.use(permission)

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)

Vue.component('copy', copy)
import axios from 'axios'
// 获取配置信息
Vue.prototype.$http = axios
Vue.prototype.$http.get('/basisConfig.json').then((res) => {
  store.dispatch('configuration/getConfig', res.data)

  // router.push({ path: '/view' })
}).catch(err => {
  console.log('apiUrl ' + err)
})
window.CUSTOM = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
