// 访客中心
import Layout from '@/components/Layout'
const systemSettings = {
    path: '/visiter',
    component: Layout,
    hidden: true,
    meta: { title: '访客中心', icon: 'list', noCache: true },
    children: [
        {
            path: 'manages',
            component: () => import('@/views/visiter/manages/index.vue'),
            name: 'SystemSettingHydroelectricity',
            meta: { title: '访客管理', icon: 'user', noCache: true }
        },
        {
            path: 'blacklist',
            component: () => import('@/views/visiter/blacklist/index.vue'),
            name: 'SystemSettingHydroelectricity',
            meta: { title: '黑名单规则', icon: 'user', noCache: true }
        },
        // {
        //     path: 'management',
        //     component: () => import('@/views/visiter/management/index.vue'),
        //     name: 'SystemSettingHydroelectricity',
        //     meta: { title: '访单管理', icon: 'user', noCache: true }
        // },
    ]
}
export default systemSettings