<template>
  <div v-if="!item.hidden">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link
        v-if="onlyOneChild.meta"
        :to="resolvePath(onlyOneChild)"
        :can-to="isDisabled"
      >
        <el-menu-item
          :index="resolvePath(onlyOneChild)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <item
            :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
            :title="onlyOneChild.meta.title"
          />
        </el-menu-item>
      </app-link>
    </template>
    <!-- <el-submenu  v-if="item.children">
      <template slot="title">
        <i :class="item.meta.icon"></i>
        <span slot="title">{{ item.meta.title }}</span>
      </template>
      <template v-for="(itemChild, index) in item.children">
        <el-submenu
          v-if="itemChild.children && itemChild.children.length"
          :index="itemChild.path"
          :key="itemChild.path+index"
        >
          <template slot="title"
            ><i :class="itemChild.meta.icon" v-if="itemChild.meta.icon"></i
            ><span>{{ itemChild.meta.title }}</span></template
          >

          <el-menu-item
            v-for="itemChild_Child in itemChild.children"
            :index="itemChild_Child.path"
            :key="itemChild_Child.path"
          >
            <i
              :class="itemChild_Child.meta.icon"
              v-if="itemChild_Child.meta.icon"
            ></i
            ><span slot="title">{{ itemChild_Child.meta.title }}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          v-else
          :index="itemChild.path"
          :key="itemChild.path + index"
          >{{ itemChild.meta.title }}</el-menu-item
        >
      </template>
    
    </el-submenu> -->
    <el-submenu v-else-if="item.children" :index="item.path" :key="item.path">
      <template slot="title"
        ><i :class="item.meta.icon" v-if="item.meta.icon"></i
        ><span>{{ item.meta.title }}</span></template
      >

      <template v-for="itemChild in item.children">
        <el-submenu
          v-if="
            itemChild.children && itemChild.children.length && !itemChild.hidden
          "
          :index="itemChild.path"
          :key="itemChild.index"
          class="erji"
          @click.native.prevent="routeClick(itemChild)"
        >
          <template slot="title"
            ><i :class="itemChild.meta.icon" v-if="itemChild.meta.icon"></i
            ><span>{{ itemChild.meta.title }}</span></template
          >

          <el-menu-item
            v-show="!itemChild_Child.hidden"
            v-for="itemChild_Child in itemChild.children"
            :index="itemChild_Child.path"
            :key="itemChild_Child.index"
            @click.native.prevent="routeClick(itemChild_Child)"
          >
            <i
              :class="itemChild_Child.meta.icon"
              v-if="itemChild_Child.meta.icon"
            ></i
            ><span slot="title">{{ itemChild_Child.meta.title }}</span>
          </el-menu-item>
        </el-submenu>

        <el-menu-item
          v-else-if="!itemChild.hidden"
          :index="itemChild.path"
          :key="itemChild.index"
          @click.native.prevent="routeClick(itemChild)"
          ><i :class="itemChild.meta.icon" v-if="itemChild.meta.icon"></i
          ><span slot="title">{{ itemChild.meta.title }}</span></el-menu-item
        >
      </template>
    </el-submenu>

    <!-- <el-submenu v-else ref="subMenu" :index="resolvePath(item)" popper-append-to-body>
      <template slot="title">
        <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
      </template>
      <sidebar-item
        v-for="(child,index) in item.children"
        :key="child.path+index"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child)"
        class="nest-menu"
      />
    </el-submenu> -->
  </div>
</template>

<script>
import path from "path";
import { isExternal } from "@/utils/validate";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";
import { mapGetters, mapState } from "vuex";
import Cookies from "js-cookie";
import { getCheckTenant } from "@/api/login";
export default {
  name: "SidebarItem",
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    this.onlyOneChild = null;
    return {
      hostname: "",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["permission_routes"]),
    isDisabled() {
      return (
        this.item.meta &&
        this.item.meta.menuType === "M" &&
        !isExternal(this.item.path)
      );
    },
  },
  methods: {
    routeClick(item) {
      console.log(item);
      // if (item.linkUrl) {
        
      //   window.open(
      //     item.linkUrl + "?token=" + Cookies.get("nisbos_auth_token")
      //   );
      //   this.$router.push("/index");
      // }
      
      if (this.$store.getters.systemIdAndUrl[item.serviceId]) {
        Cookies.set(
          "url",
          this.$store.getters.systemIdAndUrl[item.meta.serviceId]
        );
        Cookies.set("serviceId", item.serviceId);
      } else {
        Cookies.set("serviceId", "");

        let url = Cookies.get("commonUrl");
        Cookies.set("url", url);
      }
    },
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(onlyOneChild) {
      const routePath = onlyOneChild.path;
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }

      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
