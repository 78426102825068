<template>
  <span style="cursor: pointer">
    <span v-if="text" style="color: #409EFF" @click.stop="copy(text)"><i class="el-icon-document" /> <slot>{{ text }}</slot></span>
    <span v-else>--</span>
  </span>
</template>

<script>
export default {
  name: 'Copy',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    text: {
      default: ''
      // type: [String, Number]
    }
  }
}
</script>
