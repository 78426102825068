import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import defaultSetting from '@/settings'
import { getToken, setToken } from '@/utils/auth'
import { getIdmToken } from '@/api/login'
import Cookies from 'js-cookie'
import request from '@/utils/request'
import iconImg from '@/assets/logo/favicon.png'
// import iconImg from '@/assets/logo/favicon.ico'
import { getCheckTenant } from "@/api/login";

NProgress.configure({ showSpinner: false })

const whiteList = ['/portal/portalListDetail', '/portal/portalList', '/portal/portalHomepage', '/newLogin', '/Incubation', '/insideUsers', '/login', '/reset', '/view', '/operationsManagement', '/website', '/articleDetail', '/serviceDevelopment', '/operationalArticles']
// let isFirst = 1



export function getCommonUrl() {
  return new Promise((resolve, reject) => {
    if (!Cookies.get('commonUrl')) {
      let hostname = location.hostname;
      if (hostname == 'localhost') {
        hostname = window.config.hostname ? window.config.hostname : 'dev-jmbpq.jomoo.cn';
      }
      if (!window.CUSTOM) {
        console.log('-----2-----')
        getCheckTenant(hostname)
            .then((res) => {
              Cookies.set('systemId', res.data.systemId);
              Cookies.set('url', res.data.domainApi);
              Cookies.set('commonUrl', res.data.domainApi);
              Cookies.set('type', res.data.type);
              //Cookies.set('companyList', res.data.companyList);
              Cookies.set('companyList', '[]');
              if (Cookies.get('companyObj')) {
                Cookies.set('systemId', Cookies.get('companyObj'));
              }
              resolve()
            })
      }
    } else {
      resolve()
    }
  })
}



router.beforeEach(async(to, from, next) => {
  NProgress.start()

  var icon = sessionStorage.getItem('iconUrl')?sessionStorage.getItem('iconUrl'):iconImg
  const link = document.querySelector("link[rel~='icon']");
  link.href = icon;

  // 获取到 token 直接存 cookie 免登录
  if (to.query.nisbos_auth_token) {
    // console.log(to.query.nisbos_auth_token)
    // console.log('#################################################################')
    Cookies.set('nisbos_auth_token', to.query.nisbos_auth_token)

    if (window.frames.length == parent.frames.length) {
      setTimeout(() => {
        next('/index')
      }, 500);
      return
    }
    
    // next()
    // 1.0 转 2.0 token 免登录
  } else if (to.query.token && to.query.service_type && to.query.s_appId && to.query.s_userCode && to.query.s_password && to.query.s_tenantId && to.query.s_url) {
    var reqData = {
      'appId': to.query.s_appId,
      'userCode': to.query.s_userCode,
      'password': to.query.s_password,
      'tenantId': to.query.s_tenantId,
      'domain': to.query.s_url.split('//')[1]
    }
    var token
    try {
      token = await request({
        baseURL: process.env.VUE_APP_NISMEUSERSERVICE_API,
        url: '/nismes-user-service/saas-user/nisbos-login',
        headers: {
          Authorization: 'Bearer ' + to.query.token
        },
        method: 'post',
        data: reqData
      })
      if (!token.success) {
        Message.error(token.message)
        next('/newLogin')
        return
      }
      Cookies.set('nisbos_auth_token', token.message)
      if (window.frames.length == parent.frames.length) {
        setTimeout(() => {
          next('/index')
        }, 500);
        return
      }
    } catch (error) {
      token = error
      Message.error(token.message)
      // next()
      next('/newLogin')
      return
    }
    // console.log(Cookies.get('nisbos_auth_token'))
  } else if (to.query.token) {
    // console.log(to.query)
    Cookies.set('nisbos_auth_token', to.query.token)
    store.commit('user/SET_TOKEN', to.query.token)
    if (window.frames.length == parent.frames.length) {
      setTimeout(() => {
        next('/index')
      }, 500);
      return
    }
    // Cookies.set('systemIds', to.query.systemId);

    // const param = {
    //   ssoCode: to.query.ssoCode
    // }
    // await getIdmToken(param).then(res => {
    //   setToken(res.data)
    //   store.commit('user/SET_TOKEN', res.data)
    // })
  }

  // console.log(store.state.configuration.siteconfig, to.path, to.fullPath)
  // console.group('【路由跟进日志】>>>>>>>>')
  // console.info('TOKEN>>', getToken())
  // 设置title
  to.meta.title && (document.title = `${to.meta.title}`)
  // const type = store.state.configuration.siteconfig.type ? store.state.configuration.siteconfig.type : store.state.configuration.defaultSetting.type
  // 单点登录
  if (to.query.ssoCode) {
    isFirst += 1
    // console.log(to.query)

    Cookies.set('systemIds', to.query.systemId)

    const param = {
      ssoCode: to.query.ssoCode
    }
    if (!Cookies.get('nisbos_auth_token')) {
      await getIdmToken(param).then(res => {
        setToken(res.data)
        store.commit('user/SET_TOKEN', res.data)
      })
    }
  } else {
    Cookies.remove('systemIds')
  }

  // 二级页面404
  if (to.path === '/404' && !whiteList.includes(to.path)) return next('/sub-404')

  if (getToken()) {
    /* has token*/
    if (to.path === '/login' || to.path == '/insideUsers') {
      // console.log('1')
      next()
      NProgress.done()
    } else {
      // console.info('ROLES>>', store.getters.roles)
      // 判断当前用户是否已拉取完user_info信息
      if (store.getters.roles.length === 0) {
        getCommonUrl().then(() => {
        Promise.all([store.dispatch('GetInfo'), store.dispatch('GenerateRoutes')])
          .then(res => {
            const accessRoutes = res[1]
            // 根据roles权限生成可访问的路由表
            // accessRoutes.forEach(item => {

            //   if (item.children && item.children.length == 0) {
            //     console.log(item)

            //     let obj = {
            //       alwaysShow: null,
            //       children: [],
            //       hidden: false,
            //       redirect: null,
            //       path:item.path,
            //       component:item.component,
            //       name:item.meta.title,
            //       meta:item.meta
            //     }
            //   //  item.children[0]=obj

            //   //  item.component='saas/index'
            //   }
            // })
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            // console.log(router)
            const index = store.state.configuration.siteconfig.indexPage ? store.state.configuration.siteconfig.indexPage : store.state.configuration.defaultSetting.indexPage

            if (to.query.ssoCode && Object.keys(to.query).length > 1) {
              delete to.query.ssoCode
              delete to.query.systemId
              if (to.matched.length > 1) {
                router.replace(to)
                next()
              } else {
                next({ ...to, replace: true })
              }
            } else if (index) {
              next()
            } else {
              next({ ...to, replace: true }) // back方法 确保addRoutes已完成
            }
          })
          .catch(err => {
            console.error('USER_INFO_ERROR>>', err)
            store.dispatch('FedLogOut').then(() => {
              Message.error(err)
              setTimeout(() => {
                location.reload()
              }, 2000)
            })
          })
        })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    // console.log('2', to.path)
    if (whiteList.includes(to.path)) {
      // console.log('2', to.path)
      // 在免登录白名单，直接进入

      next()
    } else {
      // console.log('2', to.path, to.fullPath)
      const type = Cookies.get('type')

      next(`/newLogin?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

