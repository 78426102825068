<template>
    <el-table-column :label="columnHeader.label" :prop="columnHeader.label" align="center">
        <template v-for="(item,index) in columnHeader.children">
        <template v-if="item.children && item.children.length">
            <v-table-column  :key="index" :column-header="item" :handleLink="handleLink"
                           :isShowBtn="isShowBtn"
                           :getDic_1="getDic_1"
                           :getTag="getTag"
                           :getDic="getDic"
                           :formatText="formatText"
                           :handleBtnClick="handleBtnClick"></v-table-column>
        </template>
        <template v-else>
            <el-table-column v-if="item.data" :key="index" v-bind="item">
                <template slot-scope="{ row }">
                    {{ getDic(item.data, row[item.prop]) }}
                </template>
            </el-table-column>

            <el-table-column v-else-if="item.data_1" :key="index" v-bind="item">
                <template slot-scope="{ row }">
            <span
                    v-if="getDic_1(item.data_1, row[item.prop]).color"
                    class="dict-radio"
                    :style="{
                background: getDic_1(item.data_1, row[item.prop]).color,
              }"
            />

                    <span
                            :style="{
                color: getDic_1(item.data_1, row[item.prop]).textColor,
              }"
                    >
              {{ getDic_1(item.data_1, row[item.prop]).dictLabel || "未知" }}
            </span>
                </template>
            </el-table-column>
            <el-table-column v-else-if="item.imgProp" :key="index" v-bind="item">
                <template slot-scope="{ row }">
                    <el-image
                            style="width: 65px; height: 65px"
                            :src="row[item.imgProp]"
                            :preview-src-list="[row[item.imgProp]]"
                    >
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column v-else-if="item.options" :key="index" v-bind="item">
                <template slot-scope="{ row }">
                    <!-- {{ formatData(row,item.options) }} -->
                    <el-tooltip
                            v-if="item.options.type === 'length'"
                            effect="dark"
                            placement="top"
                    >
                        <div slot="content">
                            {{ row[item.options.name] }}
                        </div>
                        <span>{{
                formatText(row[item.options.name], item.options)
              }}</span>
                    </el-tooltip>
                    <el-tag
                            v-else-if="item.options.type === 'tag'"
                            :type="getTag(item.options.data, row[item.options.name])"
                            disable-transitions
                    >{{ row[item.options.name] }}</el-tag
                    >
                    <span v-else>{{
              formatText(row[item.options.name], item.options)
            }}</span>
                </template>
            </el-table-column>

            <!-- 直接展示值 -->
            <el-table-column
                    v-else-if="item.prop !== 'customsBtns' && !item.custom"
                    :key="index"
                    v-bind="item"
            />

            <el-table-column v-else :key="index" v-bind="item">
                <template slot-scope="{ row }">
                    <!--      按钮组      -->
                    <div v-if="item.prop === 'customsBtns'">
              <span v-for="(b, bIndex) in item.btnArr" :key="bIndex">
                <el-button
                        v-if="isShowBtn(row, b) && b.permissions"
                        v-permission="b.permission || []"
                        size="mini"
                        type="text"
                        :icon="b.icon"
                        style="margin-left: 10px"
                        @click.stop="handleBtnClick(b, row, item)"
                >{{ b.name }}
                </el-button>
              </span>
                    </div>

                    <!--     复制     -->
                    <copy
                            v-else-if="item.custom.type === 'copy'"
                            :text="row[item.custom.key]"
                    />

                    <!--     链接     -->
                    <span
                            v-else-if="item.custom.type == 'link'"
                            style="color: #409eff; cursor: pointer"
                            @click="handleLink(row, item.custom)"
                            v-text="item.name ? item.name : row[item.custom.key]"
                    ></span>

                    <!--     正常     -->
                    <span v-else v-text="row[item.custom.key]" />
                </template>
            </el-table-column>
        </template>
        </template>
    </el-table-column>
</template>
<script>
    export default {
        name: 'vTableColumn',
        props: {
            columnHeader: {
                type: Object,
                required: true
            },
            handleLink: {
                type: Function,
                default: null
            },
            isShowBtn: {
                type: Function,
                default: null
            },
            getDic_1: {
                type: Function,
                default: null
            },
            getTag: {
                type: Function,
                default: null
            },
            getDic: {
                type: Function,
                default: null
            },
            handleBtnClick: {
                type: Function,
                default: null
            },
            formatText:{
                type: Function,
                default: null
            }
        },
        methods: {
        }
    }
</script>
