var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.dom.dom, function (item) {
        return _c(
          "div",
          { key: item },
          [
            _c(
              "el-divider",
              {
                staticStyle: { margin: "10px 0" },
                attrs: { "content-position": "left" },
              },
              [_vm._v(_vm._s(item.title))]
            ),
            item.topBtn
              ? _c("lsTableBtns", {
                  attrs: { btns: item.topBtn },
                  on: {
                    btnClick: _vm.btnClick,
                    add: _vm.btnsOpenAddDialog,
                    edit: _vm.btnsOpenEditDialog,
                    delete: _vm.btnsOpenConfirm,
                    import: _vm.btnsOpenImportDialog,
                    export: _vm.btnsOpenConfirm,
                    detail: _vm.btnsOpenDetailDialog,
                  },
                })
              : _vm._e(),
            _c("vTable", {
              ref: "table",
              refInFor: true,
              attrs: { table: item },
            }),
          ],
          1
        )
      }),
      _c("detail-dialog", {
        ref: "dialog-detail",
        on: {
          done: function ($event) {
            _vm.table.isThree ? _vm.getTreeList() : _vm.getList()
          },
        },
      }),
      _c("add-dialog", {
        ref: "dialog-add",
        on: {
          done: function ($event) {
            _vm.table.isThree ? _vm.getTreeList() : _vm.getList()
          },
        },
      }),
      _c(
        "import-dialog",
        _vm._b(
          {
            ref: "ImportDialog",
            on: {
              done: function ($event) {
                _vm.table.isThree ? _vm.getTreeList() : _vm.getList()
              },
            },
          },
          "import-dialog",
          _vm.importDialogConfig,
          false
        )
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }