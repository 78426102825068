<style lang="scss" scoped>
</style>

<template>
  <el-col>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-if="dom.component === 'el-input'"
    >
      <!--   输入框   -->
      <el-input
        v-model="formValue"
        v-bind="dom"
        :placeholder="dom.placeholder || '请输入'"
        @keyup.enter.native="$emit('query')"
        @clear="clearValue"
      >
        <template slot="prepend" v-if="dom.prepend">{{ dom.prepend }}</template>
        <template slot="append" v-if="dom.append">{{ dom.append }}</template>
      </el-input>
    </el-form-item>

    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'el-checkbox'"
    >
      <!--   多选组   -->
      <lsCheckBox
        v-model="formValueArray"
        v-bind="dom"
        :dom="dom"
        :form="form"
        style="width: 100%"
        @change="handleChange"
      ></lsCheckBox>
    </el-form-item>

    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'el-upload'"
    >
      <!--   图片/头像导入   -->
      <template>
        <vUpload
          v-model="formValue"
          v-bind="dom"
          :dom="dom"
          :form="form"
        ></vUpload>
      </template>
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'el-button'"
    >
      <!-- 按钮 -->
      <el-button v-bind="dom" @click="$emit('handleBtnClick', dom)">
        {{ dom.name }}
      </el-button>
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'addressSelect'"
    >
      <!--  地址选择  -->
      <lsAddressSelect
        v-bind="dom"
        :dom="dom"
        :form.sync="form"
        @changeForm="changeForm"
      />
    </el-form-item>

    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'el-cascader'"
    >
      <lsCascader
        v-model="formValueArray"
        v-bind="dom"
        :dom="dom"
        :form="form"
        style="width: 100%"
        @change="handleChange"
      ></lsCascader>
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'el-radio'"
    >
      <!--   单选   -->
      <template>
        <lsRadio
          v-model="formValue"
          v-bind="dom"
          :dom="dom"
          style="width: 100%"
          @change="handleChange"
        ></lsRadio>
      </template>
    </el-form-item>

    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'el-select'"
    >
      <!--   下拉选择   -->
      <template>
          <v-select
                  is="v-select"
                  v-model="formValueArray"
                  v-bind="dom"
                  :dom="dom"
                  :form="form"
                  style="width: 89%"
                  @relationData="getData"
                  v-if="dom.multiple"
                  @change="handleChange"
                  @setLinkVal="setLinkVal"
          />
        <v-select
                is="v-select"
                v-model="formValue"
                v-bind="dom"
                :dom="dom"
                style="width: 89%"
                :form="form"
                @relationData="getData"
                v-else
                @change="handleChange"
                @setLinkVal="setLinkVal"
        />
<!--        <lsSelect-->
<!--          v-model="formValueArray"-->
<!--          v-bind="dom"-->
<!--          :dom="dom"-->
<!--          :form="form"-->
<!--          style="width: 100%"-->
<!--          @relationData="getData"-->
<!--          v-if="dom.multiple"-->
<!--          @change="handleChange"-->
<!--        ></lsSelect>-->
<!--        <lsSelect-->
<!--          v-model="formValue"-->
<!--          v-bind="dom"-->
<!--          :dom="dom"-->
<!--          style="width: 100%"-->
<!--          :form="form"-->
<!--          @relationData="getData"-->
<!--          v-else-->
<!--          @change="handleChange"-->
<!--        ></lsSelect>-->
      </template>
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'el-transfer'"
    >
      <!--   下拉选择   -->
      <template>
        <lsTransfer
          v-model="formValueArray"
          v-bind="dom"
          :dom="dom"
          :form="form"
          style="width: 100%"
          @change="handleChange"
        ></lsTransfer>
      </template>
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'el-date-picker'"
    >
      <!--   单选   -->
      <template>
       <vSpaDatepicker
          v-model="formValue"
          v-bind="dom"
          :dom="dom"
          style="width: 80%"
          @change="handleChange"
        />
      </template>
        <!-- <lsDatePicker
          v-model="formValue"
          v-bind="dom"
          :dom="dom"
          style="width: 100%"
          @change="handleChange"
        ></lsDatePicker> -->
      <!-- </template> -->
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'el-time-select'"
    >
      <!--   单选   -->
      <template>
        <lsTimeSelect
          v-model="formValue"
          v-bind="dom"
          :dom="dom"
          style="width: 100%"
          @change="handleChange"
        />
      </template>
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'lsDateTimePicker'"
    >
      <!--   单选   -->
      <template>
        <lsDatePicker
          v-model="formValue"
          v-bind="dom"
          :dom="dom"
          style="width: 100%"
          @change="handleChange"
        ></lsDatePicker>
      </template>
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'el-color-picker'"
    >
      <!--   单选   -->
      <template>
        <el-color-picker v-model="formValue"></el-color-picker>
      </template>
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'tinymce'"
    >
      <!--   单选   -->
      <template>
        <tinymce v-model="formValue" :dom="dom" :forms="form"></tinymce>
      </template>
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'v-tree'"
    >
      <template>
        <v-tree
          v-model="formValue"
          v-bind="dom"
          :dom="dom"
          style="width: 100%"
          :forms="form"
          @change="handleChange"
        />
      </template>
    </el-form-item>
    <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'treeSelect'"
    >
      <template>
        <treeSelect
          v-model="formValue"
          v-bind="dom"
          :dom="dom"
          style="width: 100%"
          @change="handleChange"
          :form="form"
        />
      </template>
    </el-form-item>
     <el-form-item
      v-bind="dom"
      class="myFormItem"
      v-else-if="dom.component === 'fancyTable'"
    >
      <template>
        <fancyTable
          v-model="formValue"
          v-bind="dom"
          :dom="dom"
          style="width: 100%"
          @change="handleChange"
          :form="form"
        />
      </template>
    </el-form-item>
    <el-form-item v-bind="dom" class="myFormItem" v-else>
      <component
        :is="dom.component"
        v-model="formValue"
        v-bind="dom"
        :dom="dom"
        style="width: 100%"
        @change="handleChange"
        @linkVal="linkVal"
        :form="form"
      />
    </el-form-item>
   
  </el-col>
</template>

<script>


import tinymce from "../../../src/views/lowCode/component/tinymce/index";
import treeSelect from "./treeSelect";
import vTree from "./vTree";
import vText from "./vText";
import vSpaDatepicker from './vSpaDatepicker'
import vUpload from './vUpload.vue'
// import searchFilter from '@/components/saas/searchFilter';
import vSelect from './vSelect'

export default {
  name: "SaasForm",
  components: {
   treeSelect,
    tinymce,
    vTree,
    vUpload,
    vText,

vSpaDatepicker,
      
    

    vSelect,

  },
  props: {
    // dom结构
    dom: {
      type: Object,
      default: () => ({}),
    },
    // eslint-disable-next-line vue/require-prop-types
    value: {
      // type: [Object, Array, Number, String, Boolean, Date]
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // (默认图片)
      src: "http://demo-bucket.fjoss.xstore.ctyun.cn/file/20210914/1.jfif",
      // 加载状态
      loading: false,
      // 表单
      // form: {},
      // 表单值
      formValue: undefined,
      formValueArray: [],

      linkVals: undefined,
    };
  },

  watch: {
    // 使用父组件的传值
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        // console.log(this.dom);
        if (
          this.dom.component === "el-checkbox" ||
          this.dom.component === "el-cascader" ||
          (this.dom.component === "el-select" && this.dom.multiple)
        ) {
          this.formValueArray = Array.isArray(val) ? val : [];
        } else {
          // console.log(val);
          this.formValue = val;
        }
      },
    },
    // 表单值改变时向父组件发起更新
    formValue: {
      deep: true,
      handler(val) {
        console.log(val, this.dom);
        this.$emit("input", val);
        this.$emit("change", val);
      },
    },
    linkVals: {
      deep: true,
      handler(val) {
        console.log(val);
        this.$emit("update:linkVals", val);
      },
    },
    // 表单值改变时向父组件发起更新
    formValueArray: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
        this.$emit("change", val);
      },
    },
    form: {
      immediate: true,
      deep: true,
      handler(val) {
        // console.log(val,this.dom);
      },
    },
  },
  mounted() {
    console.log(window.location.origin);
    // console.log(this.form, this.formValueArray, this.formValue, this.dom);
  },
  methods: {
    clearValue(val) {
      console.log(val);
      // this.value = null;
    },

    onJsonChange(value) {
      console.log("value:", value);
      // this.strData = value.toString().replace(/(?:\s*['"]*)?([a-zA-Z0-9]+)(?:['"]*\s*)?:/g, "'$1':");
    },
    onJsonSave(value) {
      console.log("value:", value);
    },

    /**
     *  获取裁剪过的人脸信息
     * */
    getEditHumanFace(imgUrl, key) {
      this.formValue = imgUrl;
    },
    handleChange(val) {
      console.log("val", val);
      this.$emit("input", val);
    },
    getData(data) {
      // this.$emit("sendRelation",data)
      // console.log(data)
    },
    linkVal(val, val2) {
      //  Object.assign(this.form,val)
      //  this.form[val]=val2
      this.$emit("linkVal", val, val2);
      console.log(val, val2, this.form);
    },
    changeForm(form) {
      this.$emit("update:form", form);
    },
    setLinkVal(val) {
      console.log(val);
      Object.assign(this.form, val);
      console.log(this.form);
    },
  },
};
</script>

<style  scoped lang='scss'>
</style>
