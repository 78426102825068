<template>
  <el-dialog
    :title="dom.title || '新增'"
    :visible.sync="open"
    :width="dialogWidth"
    :close-on-click-modal="false"
    class="pub_dialog"
    append-to-body
    @close="close"
    style="z-index: 99"
  >
    <div class="sketch_content">
      <el-form
        ref="form"
        :model="form"
        :label-width="dom.labelWidth ? dom.labelWidth : '120px'"
        :rules="dom.rules || {}"
      >
        <el-row :gutter="10">
          <el-col
            v-for="(d, index) in dom.dom"
            :key="index"
            :span="Number(d.col) || 24"
          >
            <template
              v-if="
                !isEdit ||
                !(d.component === 'regionTreeSelect' && form[d.prop] === 0)
              "
            >
              <saas-form-item
                v-if="
                  form[d.showProp] == 1 ||
                  (!d.isshow && !d.showProps) ||
                  form[d.showProp] == '1,-1' ||
                  form[d.showProp] == '-1,1' ||
                  isShowProp(form, d)
                "
                @linkVal="linkVal"
                v-model="form[d.prop]"
                :form.sync="form"
                :dom="d"
                @sendRelation="getData"
              />
            </template>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">{{
        dom.fail ? dom.fail.name : "取 消"
      }}</el-button>
      <el-button type="primary" :loading="loading" @click="handleSubmit"
        >{{
        dom.name ? dom.name : "确 定"
      }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogMixin from "./dialogMixin";
import { saasRequest, saasRequestNew } from "../../../api/request";
import saasFormItem from "../saasFormItem";
import { Message } from "element-ui";
import { downloadFile } from "../../../api/blob";
export default {
  name: "DialogAdd",
  components: {
    saasFormItem,
  },
  mixins: [dialogMixin],
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    const validateEmail = (rule, value, callback) => {
      const regEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regEmail.test(value)) {
        return callback();
      } else {
        callback(new Error("请输入合法的邮箱"));
      }
    };
    const validateIdNum = (rule, value, callback) => {
      const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!value) {
        return callback(new Error("证件号码不能为空"));
      } else if (!reg.test(value)) {
        return callback(new Error("证件号码不正确"));
      } else {
        callback();
      }
    };

    return {
      src: "http://demo-bucket.fjoss.xstore.ctyun.cn/file/20210914/1.jfif", // (默认图片)
      open: false,
      loading: false,
      validatePhone: validatePhone,
      validateEmail: validateEmail,
      validateIdNum: validateIdNum,
      form: {},
      dom: {},
      isEdit: false,
      syWatch: [],
      relaData: {},
      linkkey: "",
      linkValue: "",
      linkVals: "",
      treeId: null,
      treeObj: {},
      params: {},
    };
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(newVal) {
        console.log(this.dom, newVal);
        if (this.dom.api == "/idm-user/addUser") {
          this.form.createId = this.$store.state.user.userInfo.userId;
        }
        if (this.dom.api == "/idm-user/updateUser") {
          this.form.updateId = this.$store.state.user.userInfo.userId;
        }
        if (this.dom.dom) {
          this.dom.dom.forEach((item) => {
            if (item.splicing) {
              let name = "";
              item.splicing.forEach((i, index) => {
                console.log(item);
                if (this.form[i]) {
                  if (index == item.splicing.length - 1) {
                    name += this.form[i];
                  } else {
                    name += this.form[i] + item.symbol;
                  }
                }
              });
              this.form[item.prop] = name;
            }
          });
        }

        // 同步值
        for (const newValKey in newVal) {
          if (this.syWatch[newValKey]) {
            newVal[this.syWatch[newValKey]] = newVal[newValKey];
          }
        }
      },
    },
    "dom.dom": {
      deep: true,
      immediate: true,
      handler(newVal) {
        console.log(this.dom, newVal);
      },
    },
    "dom.rules": {
      deep: true,
      immediate: true,
      handler(newVal) {},
    },
    linkVals: {
      deep: true,
      immediate: true,
      handler(newVal) {
        console.log(this.dom, newVal);
      },
    },
  },
  mounted() {},
  methods: {
    isShowProp(form, d) {
      console.log(form, this.dom);

      if (
        d.showProps &&
        !d.showProps.include &&
        d.showProps.value.indexOf(form[d.showProps.key]) > -1
      ) {
        console.log(!d.include);
        return true;
      }
      if (
        d.showProps &&
        !d.showProps.include &&
        d.showProps.value.indexOf(form[d.showProps.key]) < 0
      ) {
        console.log(d);
        return false;
      }
      if (
        d.showProps &&
        d.showProps.include &&
        d.showProps.value == form[d.showProps.key]
      ) {
        console.log(d);
        return true;
      }
      if (
        d.showProps &&
        d.showProps.include &&
        d.showProps.value != form[d.showProps.key]
      ) {
        console.log(d);
        return false;
      }
      // {
      //   console.log(form);
      // }
    },
    //达到某个条件渲染某个表单项
    showFormItem(d) {},
    // 打开弹框
    show(data, dom, obj, treeId, treeObj) {
      console.log(this.$store.state.user);
      this.$bus.$emit("getTreeList");
      for (let i in dom.rules) {
        dom.rules[i].forEach((item) => {
          if (item.validator && item.validator == "validatePhone") {
            item.validator = this.validatePhone;
          } else if (item.validator && item.validator == "validateEmail") {
            item.validator = this.validateEmail;
          } else if (item.validator && item.validator == "validateIdNum") {
            item.validator = this.validateIdNum;
          }
        });
      }
      this.treeObj = treeObj;
      this.treeId = treeId;
      if (dom.oldLink) {
        data[dom.oldLink.key] = data[dom.oldLink.newKey];
      }
      console.log("打开弹框", dom, data, obj, this.form, this.treeId, treeObj);
      if (dom.addTableParams) {
        this.treeObj[dom.addTableParams] = data[dom.addTableParams];
      }
      this.getDetailData(dom.dataApi, data, dom)
        .then((res) => {
          console.log(res);
          if (res && Object.keys(res).length) {
            data = res;
          }
          console.log("最终结果", data, res);

          this.form = JSON.parse(JSON.stringify(data || {}));

          this.dom = dom;
          this.open = true;
          this.isEdit = obj.type.indexOf("edit") > -1;

          console.log(this.form, this.dom);
          if (this.isEdit) {
            // 编辑
            this.dom.title = this.dom.title || "编辑";

            // for (let k in obj) {
            //   if (typeof obj[k] == "object" && obj[k] != null) {
            //     obj[k] = obj[k].join();
            //   }
            //   console.log(k + ":" + typeof obj[k]);
            // }
            this.dom.dom.forEach((domItem) => {
              // console.log(domItem);
              if (
                (domItem.transformArr && this.form[domItem.prop]) ||
                (domItem.transformArr && this.form[domItem.prop] == 0)
              ) {
                this.form[domItem.prop] = this.form[domItem.prop]
                  .toString()
                  .split(",");
                console.log("this.dom", this.form);
              }
              if (domItem.formKey) {
                this.form[domItem.prop] = this.form[domItem.formKey];
              }
              if (domItem.value && domItem.value.row) {
                this.$set(
                  this.form,
                  domItem.prop,
                  domItem.value.row ? obj.row[domItem.value.row] + "" : null
                );
              }
              this.pushWatch(dom, domItem);
            });
            if (this.dom.api == "/idm-user/updateAccountInfo") {
              this.form.password = "";
            }
            console.log(this.form);
          } else {
            // 新增
            // 初始赋值
            console.log(this.form, this.dom);
            this.dom.dom.forEach((domItem) => {
              // console.log("domItem", domItem);
              if (domItem.relation) {
                this.$set(
                  this.form,
                  domItem.relation.relaProp,
                  domItem.relation.value || null
                );
              }

              Object.assign(this.form, this.treeObj);
              if (typeof domItem.value !== "object") {
                this.$set(this.form, domItem.prop, domItem.value || null);
              } else {
                this.$set(
                  this.form,
                  domItem.prop,
                  domItem.value ? obj.row[domItem.value.row] : null
                );
              }

              this.pushWatch(dom, domItem);
              console.log(this.form);
            });
          }
          if (this.dom.query) {
            saasRequest(this.dom.query, this.treeObj)
              .then((res) => {
                console.log(res);
                Object.assign(this.form, res.data);
                console.log(this.form);
              })
              .finally(() => {
                this.loading = false;
              });
          }
          console.log(this.form);
          // 清空校验状态
          this.$nextTick(() => {
            this.$refs.form.clearValidate();
          });
        })
        .catch((err) => {
          console.log(err);
          this.msgError("详情数据设置异常");
        });
    },
    // 添加需要同步的列表
    pushWatch(dom, domItem) {
      if (domItem.model) {
        this.syWatch[domItem.model] = domItem.prop;
      }
    },
    cancel() {
      if (this.dom.fail) {
        if (this.validateForm()) {
          let obj = Object.assign({}, this.form);
          this.loading = true;
          if (this.dom.fail) {
            Object.assign(obj, this.dom.fail.params);
          }
          console.log(this.dom);
          {
            saasRequest(this.dom.fail, obj)
              .then((res) => {
                if (res.success) {
                  console.log(res);
                  this.msgSuccess();
                  this.open = false;
                  this.$emit("done");
                } else {
                  this.loading = false;
                  Message.error(res.message || "未知异常");
                }
              })
              .finally((err) => {
                console.log(err);
                this.loading = false;
                //  Message.error(err.data.message || '未知异常')
              })
              .catch((err) => {
                let message = "";
                if (err.args) {
                  err.args.forEach((item) => {
                    this.dom.dom.forEach((i) => {
                      if (item.field == i.prop) {
                        message += i.label + item.msg;
                        console.log(message);
                      }
                    });
                  });
                  Message.error(message || "未知异常");
                }
                console.log(err, this.dom.dom);
              });
          }
        }
      } else {
        this.open = false;
      }
    },
    // 发起提交事件
    handleSubmit() {
      console.log(">>>", this.dom, this.form);
      let obj = Object.assign({}, this.form);
      if (
        this.dom.api == "/subscription-config/save" ||
        this.dom.api == "/subscription-config/update"
      ) {
        for (let k in obj) {
          if (typeof obj[k] == "object" && obj[k] != null) {
            obj[k] = obj[k].join();
          }
          console.log(k + ":" + typeof obj[k]);
        }
      }

      console.log(obj);
      //       let val = [];
      //       let key= Object.keys(this.form)
      //       let obj={}
      //       let newObj
      //       // Object.keys(this.form).forEach((item) => {

      //       //   key.push(item);

      //       // });
      //       Object.values(this.form).map((item) => {
      //         if (Array.isArray(item)) {
      //           item = item.join(",");
      //         }

      //       });
      //  this.form()
      //       console.log(this.form,b);
      if (this.treeObj) {
        Object.assign(obj, this.treeObj);
      }
      if (this.dom.dom) {
        const v = this.dom.dom.find((item) => {
          return item.showProp;
        });

        console.log(v, this.form);
        if (v && obj[v.showProp] == "-1") {
          this.dom.dom.filter((item) => {
            if (item.showProp) {
              obj[item.prop] = -1;
              obj["orgCodes"] = "";
            }
          });
        }
      }
      if (this.dom.delete) {
        delete obj[this.dom.delete];
      }
      if (this.dom.dfCode) {
        obj.dfCode = this.dom.dfCode;
      }
      if (this.dom.params) {
        Object.assign(obj, this.dom.params);
      }
      if (this.validateForm()) {
        this.loading = true;
        console.log(this.dom);
        if (this.dom.type == "downLoad") {
          let arr = JSON.parse(JSON.stringify(obj));
          arr.ids = arr.ids.join(",");
          console.log(this.dom);
          downloadFile(this.dom.api, arr, this.dom.method)
            .then((res) => {
              console.log(res);
              let blob = new Blob([res.data], {
                type: "application/zip",
              }); //type为所需要下载的文件格式，以xls文件为例
              console.log(blob);
              this.msgSuccess();
              this.open = false;
              this.$emit("done");
              let link = document.createElement("a"); // 创建a标签
              link.style.display = "none";
              let objectUrl = URL.createObjectURL(blob);
              link.href = objectUrl;
              link.download = arr.projectName;
              link.click();
              URL.revokeObjectURL(objectUrl);
            })
            .finally((err) => {
              console.log(err);
              this.loading = false;
              //  Message.error(err.data.message || '未知异常')
            })
            .catch((err) => {
              console.log(err, this.dom.dom);
            });
        } else {
          if(this.dom.api){
            saasRequest(this.dom, obj)
            .then((res) => {
              if (res.success) {
                console.log(res);
                this.msgSuccess();
                this.open = false;
                this.$emit("done");
              } else {
                this.loading = false;
                Message.error(res.message || "未知异常");
              }
            })
            .finally((err) => {
              console.log(err);
              this.loading = false;
              //  Message.error(err.data.message || '未知异常')
            })
            .catch((err) => {
              let message = "";
              if (err.args) {
                err.args.forEach((item) => {
                  this.dom.dom.forEach((i) => {
                    if (item.field == i.prop) {
                      message += i.label + item.msg;
                      console.log(message);
                    }
                  });
                });
                Message.error(message || "未知异常");
              }
              console.log(err, this.dom.dom);
            });
          }else{
            this.open=false
          }
          
        }
      }
    },
    /**
     *  获取裁剪过的人脸信息
     * */
    getEditHumanFace(imgUrl, key) {
      this.form[key] = imgUrl;
    },
    evalHide(str) {
      // eslint-disable-next-line no-eval
      return eval(str);
    },
    clicks(d) {
      console.log(d);
    },
    linkVal(val, val2) {
      this.form[val] = val2;
      this.linkkey = val;
      this.linkValue = val2;

      console.log(val, this.form);
    },
    close() {
      this.$bus.$emit("close");
    },
    getData(data) {
      // let reldata = {};
      // this.dom.dom.forEach((item) => {
      //   if (item.relation) {
      //     item.relation.dom.data = data.data;
      //     reldata = item.relation.dom;
      //     console.log(reldata);
      //   }
      //   // if (item.prop == data.prop) {
      //   //   // item.data=new Array
      //   //   console.log(item.data);
      //   //   data.data.forEach((element) => {
      //   //     item.data.push(element);
      //   //     console.log(item.data);
      //   //   });
      //   // }
      // });
      // this.$set(this.dom.dom, 1, reldata);
      // //  this.dom.dom.push(reldata)
      // console.log(data, this.dom);
    },
  },
};
</script>
<style scoped lang='scss'>
/deep/ .pub_dialog {
  z-index: 99 !important;
}
.tox-tinymce-aux {
  z-index: 5000 !important;
}
.sketch_content {
  overflow: auto;
  height: 434px;

  padding: 0px 30px 11px 27px;
}
</style>
