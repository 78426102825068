// 智慧园区 会议管理
import Layout from '@/components/Layout'
export default {
  path: '/meetings',
  component: Layout,
  hidden: true,
  meta: { title: '会议管理', icon: 'documentation', noCache: true },
  children: [
    {
      path: '/meeting/types',
      hidden: false,
      component: () => import('@/views/meeting/types/index'),
      name: 'Types',
      meta: { title: '会议室类别管理', icon: "", noCache: true }
    },
    {
      path: '/meeting',
      hidden: false,
      component: () => import('@/views/meeting/index'),
      name: 'Index',
      meta: { title: '会议室管理', icon: "", noCache: true }
    },
    {
      path: 'edit',
      hidden: true,
      component: () => import('@/views/meeting/edit/index'),
      name: 'Edit',
      meta: { title: '会议室管理', icon: "", noCache: true }
    },
    {
      path: "/meeting/adminAppoint",
      hidden: false,
      component: () =>
        import(
          /* webpackChunkName: "meetingadminAppointsplit" */ "@/views/meeting/adminAppoint/index"
        ),
      name: "adminAppoint",
      meta: { title: "会议室预定（管理员）", icon: "", noCache: true },
    },
    {
      path: "/meeting/superAppoint",
      hidden: false,
      component: () =>
        import(
          /* webpackChunkName: "meetingsuperAppointsplit" */ "@/views/meeting/superAppoint/index"
        ),
      name: "superAppoint",
      meta: { title: "会议室预定（超管）", icon: "", noCache: true },
    },
    {
      path: "/meeting/appoint",
      hidden: false,
      component: () =>
        import(
          /* webpackChunkName: "meetingappointsplit1" */ "@/views/meeting/appoint/index"
        ),
      name: "Appoint",
      meta: { title: "会议室预定", icon: "", noCache: true },
    },
    {
      path: "/meeting/month",
      hidden: false,
      component: () =>
        import(
          /* webpackChunkName: "meetingMonthsplit" */ "@/views/meeting/month/index"
        ),
      name: "meetingMonth",
      meta: { title: "我的会议", icon: "", noCache: true },
    },
    {
      path: "/meeting/minutes",
      hidden: false,
      component: () =>
        import(
          /* webpackChunkName: "meetingmtminutessplit" */ "@/views/meeting/minutes/index"
        ),
      name: "minutes",
      meta: { title: "会议纪要", icon: "", noCache: true },
    },
    {
      path: "/meeting/mtappointAttendeeref",
      hidden: true,
      component: () =>
        import(
          /* webpackChunkName: "meetingmtappointAttendeerefsplit" */ "@/views/meeting/mtappointAttendeeref/index"
        ),
      name: "mtappointAttendeeref",
      meta: { title: "会议签到", icon: "", noCache: true },
    },
    {
      path: 'appointment',
      hidden: true,
      component: () => import('@/views/meeting/appointment/index'),
      name: 'Appointment',
      meta: { title: '会议室预定', icon: 'documentation', noCache: true }
    },
    // {
    //   path: '/meeting/minutes',
    //   hidden: false,
    //   component: () => import('@/views/meeting/minutes/index'),
    //   name: 'Minutes',
    //   meta: { title: '会议纪要', icon: 'documentation', noCache: true }
    // },
    {
      path: '/meeting/minutes/edit',
      hidden: true,
      component: () => import('@/views/meeting/minutes/edit/index'),
      name: 'MinutesEdit',
      meta: { title: '会议纪要编辑', icon: 'documentation', noCache: true }
    },
    {
      path: 'mtappointAttendeerefintment',
      hidden: true,
      component: () => import('@/views/meeting/mtappointAttendeeref/index'),
      name: 'MtappointAttendeerefintment',
      meta: { title: '会议室签到', icon: 'documentation', noCache: true }
    },
    // {
    //   path: 'mtappointAttendeerefintment',
    //   hidden: false,
    //   component: () => import('@/views/meeting/mtappointAttendeeref/index'),
    //   name: 'MtappointAttendeerefintment',
    //   meta: { title: '会议室签到', icon: 'documentation', noCache: true }
    // },
    // {
    //   path: 'doorplateTemplate',
    //   component: () => import('@/views/meeting/doorplateTemplate/index'),
    //   name: 'doorplateTemplate',
    //   meta: { title: '门牌显示配置', icon: 'documentation', noCache: true }
    // },
    // {
    //   path: 'doorplateTemplate/add',
    //   hidden: true,
    //   component: () => import('@/views/meeting/doorplateTemplate/add'),
    //   name: 'doorplateTemplate/add',
    //   meta: {
    //     title: '门牌显示配置详情',
    //     icon: 'documentation',
    //     noCache: true
    //   }
    // }
  ]
}

