import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
import app from './modules/app'
import tagsView from '@/store/modules/tagsView';
import permission from '@/store/modules/permission';
import settings from '@/store/modules/settings';
import dict from '@/store/modules/dict';

import configuration from '@/store/modules/configuration'


Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    app,
    tagsView,
    permission,
    settings,
    dict,
    
    configuration,
    
  },
  getters
})

export default store
