// 发票管理
import Layout from '@/components/Layout'
export default {
  path: '/invoice',
  component: Layout,
  hidden: true,
  meta: { title: '发票管理', icon: 'list', noCache: true },
  children: [
    {
      path: 'managementInvoices',
      component: () => import('@/views/managementInvoices/invoice'),
      name: 'InvoiceManagementInvoices',
      meta: { title: '发票列表', icon: 'user', noCache: false }
    },
    {
      path: 'invoiceHeader-client',
      component: () => import('@/views/managementInvoices/invoiceHeader-client'),
      name: 'InvoiceInvoiceHeader',
      meta: { title: '发票抬头管理', icon: 'user', noCache: true }
    },
    {
      path: 'invoicingRecords-client',
      component: () => import('@/views/managementInvoices/invoicingRecords-client'),
      name: 'InvoiceInvoicingRecords',
      meta: { title: '开票记录', icon: 'user', noCache: false }
    },
    {
      path: 'redLetterConfirmationForm',
      component: () => import('@/views/managementInvoices/redLetterConfirmationForm'),
      name: 'InvoiceRedLetterConfirmationForm',
      meta: { title: '红字确认单', icon: 'user', noCache: false }
    },
    {
      path: 'scarletInitiationForm-client',
      component: () => import('@/views/managementInvoices/scarletInitiationForm-client'),
      name: 'InvoiceScarletInitiationFormClient',
      meta: { title: '红字发起单', icon: 'user', noCache: false }
    }
  ]
}
