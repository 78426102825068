/*
  用法 v-componentpermissions:[ arg ]=" value "
  示例 v-componentpermissions:[$store.state.user.btnPermission]="['1424484136']"
  arg Object
  value Array
*/
import store from '@/store'
export default {
  update(el, binding, vnode) {
    var { arg, value } = binding
    var userPermission = Array.isArray(value) ? value : []
    if (userPermission.length === 0) {
      el.style.display = 'none'
      return
    }
    let isExist = false
    var Permission = typeof arg === 'object' ? arg : store.state.user.btnPermission
    // const permissionObj = JSON.parse(Cookies.get('getRoutersPermission'))
    for (const i in Permission) {
      if (userPermission.includes(i)) {
        isExist = true
      }
    }
    el.style.display = (isExist ? 'inline-block' : 'none')
    // !isExist && el.parentNode.removeChild(el)
  }
}
