import { adminRouter, constantRoutes, empRouter } from '@/router'
// import store from '@/store';
// import Layout from '@/layout/index';
import { getRouters } from '@/api/login'
import Layout from '@/components/Layout'
import Layout2 from '@/components/Layout2'
import { getSystemId } from '@/utils/auth'
import Cookies from 'js-cookie'

// import { getRouters } from '@/apiOld/menu';
// import Layout from '@/layout/index';

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    routeMenu: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    },
    SET_ROUTESMENU: (state, routes) => {
      state.routeMenu = routes
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }) {
      return new Promise((resolve) => {
        // ====动态路由=====
        // 向后端请求路由数据
        getRouters({
          //systemIds:getSystemId()
          systemIds:Cookies.get('systemId2')
        }).then((res) => {
          // console.log(res)
          const accessedRoutes = filterAsyncRouter(res.data)
          // console.log(accessedRoutes)
          accessedRoutes.push({
            path: '*',
            redirect: '/404',
            hidden: true,
            affix: false
          })
          // pushChild(accessedRoutes)
          // console.log(accessedRoutes)
          commit('SET_ROUTES', accessedRoutes)
          resolve(accessedRoutes)
        })

        // ====本地路由====
        // const accessedRoutes = asyncRoutes;
        // commit('SET_ROUTES', accessedRoutes);
        // resolve(accessedRoutes);
      })
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter((route) => {
    delete route.name
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout
      } else if (route.component === 'Layout2') {
        route.component = Layout2
      } else {
        route.component = 'saas/index'
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children)
    }
    return true
  })
}

export const loadView = (view) => {
  // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission
