<template>
  <el-dialog
    :title="title"
    :visible.sync="isOpen"
    :before-close="closeDialog"
    :width="dialogWidth"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    append-to-body
    destroy-on-close
  >
    <slot />
    <template #footer>
      <el-button type="primary" :loading="isLoading" @click="submitForm">确 定</el-button>
      <el-button @click="$emit('update:isOpen', false)">取 消</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'FormDialog',
  props: {
    dialogWidth: {
      type: String,
      default: '500px'
    },
    isOpen: { // 弹窗是否显示
      type: Boolean,
      default: false
    },
    isLoading: { // 按钮加载状态
      type: Boolean,
      default: false
    },
    showsee: { // 按钮加载状态
      type: Boolean,
      default: false
    },
    title: { // 弹窗标题
      type: String,
      default: ''
    }
  },
  methods: {
    submitForm() {
      this.$emit('submitForm') // 触发父组件提交表单
    },
    closeDialog() {
      this.$emit('update:isOpen', false) // 触发父组件关闭弹窗
    }
  }
}
</script>
