import {
  getStructure as apiGetStructure,
  getStructureByApi as apiGetStructureByApi
} from '@/api/login';

export function getStructure(apiOrCode) {
  return apiGetStructure(apiOrCode)
}

export function getStructureByApi(api) {
  return apiGetStructureByApi(api)
}
