import { render, staticRenderFns } from "./Link.vue?vue&type=template&id=2c52e731&"
import script from "./Link.vue?vue&type=script&lang=js&"
export * from "./Link.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/nisparkv2/front/nisbos-front-admin/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c52e731')) {
      api.createRecord('2c52e731', component.options)
    } else {
      api.reload('2c52e731', component.options)
    }
    module.hot.accept("./Link.vue?vue&type=template&id=2c52e731&", function () {
      api.rerender('2c52e731', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Layout/components/Sidebar/Link.vue"
export default component.exports