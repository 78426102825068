<template>
  <div>
    <treeselect
        v-show="!isDetail"
        v-model="localVal"
        :options="deptOptions"
        :normalizer="normalizer"
        :load-options="loadOptions"
        :disabled="disabled"
        
        show-count
        :placeholder="placeholder || '请选择'"
        @select="changeVal"
        @input="clearVal"
    />
    <span v-if="isDetail">{{ text }}</span>
  </div>

</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect';
import request from '../../api/request';
import Cookies from "js-cookie";
import service from "../../api/request";

export default {
  components: {
    Treeselect
  },
  model: {
    prop: 'value', //
    event: 'change'// 当组件的值发生改变时要emit的事件名
  },
  props: {
    dom: {
      type: Object,
      default: () => {
        return {}
      }
    },
    value: {
      type: [String, Number, Boolean],
      default: undefined
    },
    keyName: {
      type: String,
      default: 'orgCode'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    api: {
      type: String,
      default: ''
    },
    method: {
      type: String,
      default: 'POST'
    },
    queryParams: {
      type: Object,
      default: () => ({"pageSize": 10, "pageNumber": 1, "currentPage": 1})
    },
    normalizerStr: {
      type: Object,
      default: () => ({})
    },
    form:{
      
    }
  },
  data() {
    return {
      deptOptions: undefined,
      localVal: undefined,
      // queryParams: {
      //   currentPage: 1,
      //   pageSize: 10
      // },
      text: ''
    };
  },
  watch: { // 这里检测data中的值，一旦发生变化就提交事件到父组件
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        this.deptOptions && (this.localVal = newVal);
      }
    },
    deptOptions(cur) {
      if (cur.length && this.value) {
        this.localVal = this.value
      }
    },
    form1: {
      immediate: true,
      handler(val, o) {
        console.log("select", this.form, this.dom,this.options,val,o);
        if (this.dom.selectLinkKey) {
          console.log("select", this.form, this.dom);

          if (
            val[this.dom.selectLinkKey] &&o&&
            val[this.dom.selectLinkKey] != o[this.dom.selectLinkKey]
          ) {
            let param = {};
            this.formValue = null;
            param[this.dom.selectLinkKey] = val[this.dom.selectLinkKey];
            Object.assign(this.queryParams,param)
            this.getTreeSelect(this.dom, param);
          }else{
            this.options = this.dom.data?this.dom.data:[]
          }
          console.log(this.dom,this.options);
        }
        if (this.dom.selectLinkKey && val[this.dom.selectLinkKey]&&this.form.id) {
          let param = {};
          param[this.dom.selectLinkKey] = val[this.dom.selectLinkKey];
            Object.assign(this.queryParams,param)
          this.getTreeSelect(this.dom, param);
          console.log(this.dom,this.options);
        } else if (this.dom.dictUrl&&!this.dom.selectLinkKey) {
          console.log(this.dom,this.options);
            Object.assign(this.queryParams,param)
          this.getTreeSelect(this.dom);
        }else{
            this.options = this.dom.data?this.dom.data:[]
          }
        if (this.dom.remoteMethod) {
          let param = {};
          param[this.dom.paramsKey] = this.form[this.dom.paramsKey];
            Object.assign(this.queryParams,param)
          this.getTreeSelect(this.dom, param);
        }
      },
    },
  },
  mounted() {
   this.$bus.$on("getTreeList", (data) => {
      console.log("1");
      this.getTreeSelect()
    });
  },
  computed: {
    form1() {
      return JSON.parse(JSON.stringify(this.form));
    },
  },
  methods: {
    async loadOptions({action/*, callback*/}) {
      if (action === LOAD_ROOT_OPTIONS) {
        await this.getTreeSelect();
      }
    },
    clearVal(node, a) {
      this.text = ''
      if (!node) {
        this.$emit('change', undefined); // 事件传值
      } else if (this.isDetail) {
        this.$nextTick(() => {
          const node = this.$el.querySelector('.vue-treeselect__single-value')
          this.text = node && node.textContent
        })
      }
    },
    changeVal(node) {
      console.log(node)
      this.$emit('change', node[this.keyName]); // 事件传值
    },
    /** 查询区域下拉树结构 */
    getTreeSelect() {
      console.log(this.dom)
      if (!this.api) {
        console.log('未获取到列表api地址')
        return
      }
      if (Array.isArray(this.list) && this.list.length) {
        return;
      }

      this.sendApi().then(response => {
        this.total = Number(response.data.total)
        let data = response.data
        if (!Array.isArray(data)) {
          data = response.data.records
        }

        const treeKey = this.dom.treeKey || 'id'
        const treeParentKey = this.dom.treeParentKey || 'parentId'

        data.forEach(item => {
          if (item[treeParentKey] === null || item[treeParentKey] === '') {
            item[treeParentKey] = 0;
          }
        });
        console.log('datadatadata', data)
        // this.deptOptions = this.handleTree(data || [], treeKey, treeParentKey);
        this.deptOptions = data
         console.log(this.deptOptions,this.dom)
      }).finally(() => {
        this.loading = false;
      });
    },
    sendApi() {
      const path = Cookies.get('url')
      if (path) {
        console.log('service', this.queryParams)
        return service({
          url: window.location.protocol+ '//' + path + this.api,
          method: this.method || 'POST',
          params: this.queryParams,
          data: this.queryParams
        })
      } else {
        return request({
          url: this.api,
          method: this.method || 'POST',
          params: this.queryParams,
          data: this.queryParams
        })
      }
    },
    normalizer(node) {
      // console.log(node)
      node.childrenData && !node.childrenData.length && delete node.childrenData;
      // console.log(this.normalizerStr)
      if (this.normalizerStr && Object.keys(this.normalizerStr).length) {
        const result = {}
        result.children = 'childrenData'
        for (const key in this.normalizerStr) {
          result[key] = node[this.normalizerStr[key]]
        }
        
        return result;
      } else {
        // eslint-disable-next-line no-throw-literal
        throw 'normalizerStr必填'
      }
    }
  }
};
</script>

<style scoped>

</style>
