var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.formValue,
        expression: "formValue",
      },
    ],
    staticStyle: { visibility: "hidden" },
    attrs: {
      id: _vm.tinymceId,
      dom: _vm.dom,
      placeholder: _vm.dom.placeholder ? _vm.dom.placeholder : "请输入",
    },
    domProps: { value: _vm.formValue },
    on: {
      input: function ($event) {
        if ($event.target.composing) {
          return
        }
        _vm.formValue = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }