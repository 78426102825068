import Vue from 'vue'
import VueRouter from 'vue-router'
import Router from 'vue-router'
import Layout from '@/components/Layout'

// 系统设置
import systemSettingsRoute from './systemSettingsT.js'
// 设备管理
import devices from './device'
//办公室管理
// import officeRouter from './office'
// 访客中心
import visiter from './visiter'
// 访单中心
import visitOrders from './visitOrders'
// 财务管理 financialManagement
import financialManagementRoute from './financialManagement'
// 小程序装修
import smallRoutineDeviseRoute from './smallRoutineDevise.js'
// 智慧园区 空间管理
import spSpacsRouter from './smartPark/space'
// 发票管理
import invoiceRouter from './managementInvoices.js'

// NISBOS-WWW 能耗管理
import energyRouter from './migrate_NISBOS-WWW/energy'
// 环安消息
import noticeRouter from './migrate_NISBOS-WWW/notice-order'
// 火警管理
import fireManageRouter from './migrate_NISBOS-WWW/fireManagement'
// 设备中心
import equipmentManagementRouter from './migrate_NISBOS-WWW/equipmentManagement'
// 能源管理 设置
import energyManagementSettingsRouter from './migrate_NISBOS-WWW/energyManagementSettings'
// 能源管理 表单制作
import formMakerRouter from './migrate_NISBOS-WWW/formMaker'
// 门户内容管理
import portalContentRouter from './portalContent.js'
// 会议管理
import meetingRouter from './migrate_NISBOS-WWW/meeting'


// import org from '@/router/module/org';

Vue.use(VueRouter)

// /** 无需再手动引入路由 */
// const asyncRoutesModules = require.context('./module', false, /\.js$/)
// const otherRoutes = asyncRoutesModules.keys().reduce((pre, cur, i) => {
//   pre[i] = asyncRoutesModules(cur).default
//   return pre
// }, [])

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
    affix                        // 如果设置为false，则tag不展示
  }
 */

export const constantRoutes = [
  systemSettingsRoute,
  financialManagementRoute,
  smallRoutineDeviseRoute,
  spSpacsRouter,
  invoiceRouter, // 发票管理
  energyRouter, // NISBOS-WWW 能耗管理
  ...noticeRouter,
  ...fireManageRouter,
  equipmentManagementRouter, // 设备中心
  energyManagementSettingsRouter, // 能源管理 设置
  formMakerRouter, // 能源管理 表单制作
  visiter, // 访客中心
  visitOrders, // 访单中心
  ...devices, // 设备管理
  // officeRouter,  //办公室管理
  meetingRouter, //会议管理
  ...portalContentRouter, // 门户内容管理
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },

  {
    path: '/example',
    component: Layout,
    hidden: true,
    meta: { title: '示例', icon: 'list', noCache: true },
    children: [
      {
        path: 'building',
        component: () => import('@/views/example/building'),
        name: 'ExampleBuilding',
        meta: { title: '楼宇管理', icon: 'user', noCache: false }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: (resolve) => require(['@/views/homes/index-new'], resolve),
        name: '首页',
        meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true }
      }
    ]
  },
  
  // {
  //   path: '',
  //   component: Layout,
  //   redirect: 'index',
  //   hidden: true,
  // },
  // {
  //   path: '/index',
  //   hidden: true,
  //   component: () => import('@/views/importHtml'),
  //   meta: { title: '数据导入' }
  // },
  {
    path: '/led',
    hidden: true,
    component: () => import('@/views/home'),
    meta: { title: '大屏', noCache: true }
  },
  {
    path: '/workCenter',
    component: Layout,
    hidden: true,
    meta: { title: '新-工单中心', icon: 'list', noCache: true },
    children: [
      {
        path: '/workType',
        component: () => import('@/views/workCenter/workType'),
        name: 'WorkCenterWorkType',
        meta: { title: '工单类型', noCache: true }
      },
      {
        path: '/workType/flow',
        hidden: true,
        component: () => import('@/views/workCenter/workType/flow.vue'),
        name: 'WorkCenterWorkTypeFlow',
        meta: { title: '工单流程', noCache: true }
      },
      {
        path: '/workRecord',
        component: () => import('@/views/workCenter/workRecord'),
        name: 'WorkCenterWorkRecord',
        meta: { title: '工单记录', noCache: true }
      },
      {
        path: '/workRecord/detail',
        hidden: true,
        component: () => import('@/views/workCenter/workRecord/detail.vue'),
        name: 'WorkCenterWorkRecordDetail',
        meta: { title: '工单详情', noCache: true }
      }
    ]
  },

  {
    path: '/eventCenter',
    hidden: true,
    component: Layout,
    meta: { title: '事件中心', icon: 'list', noCache: true },
    children: [
      {
        path: '/list',
        component: () => import('@/views/eventCenter/list'),
        name: 'EventCenterList',
        meta: { title: '事件类型列表', noCache: true }
      },
      {
        path: '/config',
        component: () => import('@/views/eventCenter/config'),
        name: 'EventCenterconfig',
        meta: { title: '事件配置列表', noCache: true }
      },
      {
        path: '/person',
        component: () => import('@/views/eventCenter/person'),
        name: 'EventCenterperson',
        meta: { title: '事件责任人列表', noCache: true }
      },
      {
        path: '/record',
        component: () => import('@/views/eventCenter/record'),
        name: 'EventCenterrecord',
        meta: { title: '事件记录', noCache: true }
      },
      {
        path: '/region',
        component: () => import('@/views/eventCenter/region'),
        name: 'EventCenterregion',
        meta: { title: '区域管理', noCache: true }
      },
    ]
  },

  {
    path: '/office',
    hidden: true,
    component: Layout,
    meta: { title: '办公室管理', icon: 'list', noCache: true },
    children: [
      {
        path: '/office',
        hidden: true,
        component: () => import('@/views/office/index'),
        name: 'Office',
        meta: { title: '办公室管理', noCache: true }
      }
    ]
  },

  {
    path: '/newsCenter',
    component: Layout,
    hidden: true,
    meta: { title: '消息中心', icon: 'list', noCache: true },
    children: [
      {
        path: '/newsTemplate',
        component: () => import('@/views/newsCenter/newsTemplate'),
        name: 'NewsCenterNewsTemplate',
        meta: { title: '消息模板', noCache: true }
      },
      {
        path: '/newsList',
        component: () => import('@/views/newsCenter/newsList'),
        name: 'NewsCenterNewsList',
        meta: { title: '消息提醒', noCache: true }
      },
      {
        path: '/notice',
        component: () => import('@/views/newsCenter/notice'),
        name: 'NewsCenterNotice',
        meta: { title: '系统通知', noCache: true }
      },
      {
        path: '/pushRecord',
        component: () => import('@/views/newsCenter/pushRecord'),
        name: 'NewsCenterPushRecord',
        meta: { title: '消息推送记录', noCache: true }
      }
    ]
  },

  {
    path: '/ledData',
    component: Layout,
    hidden: true,
    meta: { title: '大屏数据', icon: 'list', noCache: true },
    children: [
      {
        path: 'dataDetail',
        component: () => import('@/views/importHtml'),
        name: 'LedDataDataDetail',
        meta: { title: '数据导入', noCache: true }
      },

      {
        path: 'zonghe',
        component: () => import('@/views/importHtml/zonghe'),
        name: 'LedDataZonghe',
        meta: { title: '综合态势', noCache: true }
      },
      {
        path: 'qiye',
        component: () => import('@/views/importHtml/qiye'),
        name: 'LedDataQiye',
        meta: { title: '企业态势', noCache: true }
      },
      {
        path: 'anfang',
        component: () => import('@/views/importHtml/anfang'),
        name: 'LedDataAnfang',
        meta: { title: '安防态势', noCache: true }
      },
      {
        path: 'tongxing',
        component: () => import('@/views/importHtml/tongxing'),
        name: 'LedDataTongxing',
        meta: { title: '通行态势', noCache: true }
      },
      {
        path: 'zichan',
        component: () => import('@/views/importHtml/zichan'),
        name: 'LedDataZichan',
        meta: { title: '资产态势', noCache: true }
      },
      {
        path: 'gongyu',
        component: () => import('@/views/importHtml/gongyu'),
        name: 'LedDataGongyu',
        meta: { title: '公寓态势', noCache: true }
      },
      {
        path: 'wulian',
        component: () => import('@/views/importHtml/wulian'),
        name: 'LedDataWulian',
        meta: { title: '物联设施', noCache: true }
      },
      {
        path: 'gaojing',
        component: () => import('@/views/importHtml/gaojing'),
        name: 'LedDataGaojing',
        meta: { title: '告警中心', noCache: true }
      },
      {
        path: 'dangjian',
        component: () => import('@/views/importHtml/dangjian'),
        name: 'LedDataDangjian',
        meta: { title: '智慧党建', noCache: true }
      }
    ]
  },

  // {
  //   path: '',
  //   component: Layout,
  //   redirect: 'index',
  //   hidden: true, // 为true时,不显示该路由
  //   children: [
  //     {
  //       path: 'editPage',
  //       component: (resolve) => require(['../../ls-form/formGenerator'], resolve),
  //       name: '页面编辑',
  //       meta: { title: '页面编辑', icon: 'dashboard',serviceId:'10008' }
  //     }
  //   ]
  // },

  {
    path: '/user',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'profile',
        component: () => import('@/views/user/profile'),
        name: 'UserProfile',
        meta: { title: '个人中心', icon: 'user', noCache: true }
      }
    ]
  },

  // {
  //   path: '/importData',
  //   component: Layout,
  //   name: '数据导入',
  //   meta: { title: "数据导入", icon: "documentation", noCache: true },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/importHtml'),
  //       name: '数据导入',
  //       meta: { title: '数据导入', icon: 'dashboard'}
  //     }
  //   ]
  // },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login')
  },
  {
    path: '/newLogin',
    name: 'NewLogin',
    component: () => import('../views/newLogin')
  },
  {
    path: '/finance',
    component: Layout,
    hidden: true,
    meta: { title: '企业', icon: 'list', noCache: true },
    children: [
      {
        path: '/hatch',
        component: () => import('@/views/enterprise/hatch'),
        name: 'FinanceHatch',
        meta: { title: '企业入孵', noCache: true }
      },
      {
        path: '/hatch/manage',
        component: () => import('@/views/enterprise/hatch/manage'),
        name: 'FinanceHatchManage',
        meta: { title: '企业入孵管理', noCache: true }
      },

      {
        path: '/manage', // 管理端
        component: () => import('@/views/enterprise/manage'),
        name: 'FinanceManage',
        meta: { title: '企业列表', noCache: true }
      },
      {
        path: '/manage/detail', // 管理端
        hidden: true,
        component: () => import('@/views/enterprise/manage/detail'),
        name: 'FinanceManageDetail',
        meta: { title: '企业详情', noCache: true }
      },
      {
        path: '/manage/business', // 管理端
        component: () => import('@/views/enterprise/manage/business'),
        name: 'FinanceManageBusiness',
        meta: { title: '企业工商变更', noCache: true }
      },
      {
        path: '/manage/kongjian', // 管理端
        component: () => import('@/views/enterprise/manage/kongjian'),
        name: 'FinanceManageKongjian',
        meta: { title: '企业场所变更管理', noCache: true }
      },
      {
        path: '/manage/applyList', // 管理端
        component: () => import('@/views/enterprise/manage/applyList'),
        name: 'FinanceManageApplyList',
        meta: { title: '企业申报管理', noCache: true }
      },
      {
        path: '/manage/applyDetail', // 管理端
        hidden: true,
        component: () => import('@/views/enterprise/manage/applyDetail'),
        name: 'FinanceManageApplyDetail',
        meta: { title: '企业申报详情', noCache: true }
      },
      {
        path: '/manage/graduation', // 管理端
        component: () => import('@/views/enterprise/manage/graduation'),
        name: 'FinanceManageGraduation',
        meta: { title: '企业毕业提醒', noCache: true }
      },
      {
        path: '/client/index', // 客户端
        component: () => import('@/views/enterprise/client/index'),
        name: 'FinanceClient',
        meta: { title: '我的企业', noCache: true }
      },
      {
        path: '/client/applyList', // 客户端
        component: () => import('@/views/enterprise/client/applyList'),
        name: 'FinanceClientApplyList',
        meta: { title: '企业日常申报', noCache: true }
      },
      {
        path: '/client/apply', // 客户端
        hidden: true,
        component: () => import('@/views/enterprise/client/apply'),
        name: 'FinanceClientApply',
        meta: { title: '申报填写', noCache: true }
      },
      {
        path: '/client/applyLook', // 客户端
        hidden: true,
        component: () => import('@/views/enterprise/client/applyLook'),
        name: 'FinanceClientApplyLook',
        meta: { title: '申报详情', noCache: true }
      },
      {
        path: '/client/applyDetail', // 客户端
        hidden: true,
        component: () => import('@/views/enterprise/client/applyDetail'),
        name: 'FinanceClientApplyDetail',
        meta: { title: '申报内容', noCache: true }
      },
      {
        path: '/client/business', // 客户端
        component: () => import('@/views/enterprise/client/business'),
        name: 'FinanceClientBusiness',
        meta: { title: '企业工商变更', noCache: true }
      },
      {
        path: '/client/placeList', // 客户端
        component: () => import('@/views/enterprise/client/placeList'),
        name: 'FinanceClientPlaceList',
        meta: { title: '企业场所变更', noCache: true }
      },
      {
        path: '/client/kongjian', // 客户端
        hidden: true,
        component: () => import('@/views/enterprise/client/kongjian'),
        name: 'FinanceClientKongjian',
        meta: { title: '企业场所变更', noCache: true }
      },
      {
        path: '/client/jingyin', // 客户端
        component: () => import('@/views/enterprise/client/jingyin'),
        name: 'FinanceClientJingyin',
        meta: { title: '经营信息', noCache: true }
      },
      {
        path: '/client/fazhan', // 客户端
        component: () => import('@/views/enterprise/client/fazhan'),
        name: 'FinanceClientFazhan',
        meta: { title: '企业发展', noCache: true }
      },
      {
        path: '/client/zhishi', // 客户端
        component: () => import('@/views/enterprise/client/zhishi'),
        name: 'FinanceClientZhishi',
        meta: { title: '知识产权', noCache: true }
      },
      {
        path: '/client/xiangmu', // 客户端
        component: () => import('@/views/enterprise/client/xiangmu'),
        name: 'FinanceClientXiangmu',
        meta: { title: '项目成果', noCache: true }
      },
      {
        path: '/client/chanpin', // 客户端
        component: () => import('@/views/enterprise/client/chanpin'),
        name: 'FinanceClientChanpin',
        meta: { title: '企业产品', noCache: true }
      },
      {
        path: '/client/huamingce', // 客户端
        component: () => import('@/views/enterprise/client/huamingce'),
        name: 'FinanceClientHuamingce',
        meta: { title: '花名册', noCache: true }
      }
    ]
  },

  {
    path: '/nisbos_park',
    component: Layout,
    hidden: true,
    name: '报事报修',
    meta: { title: '报事报修', icon: 'list', noCache: true },
    children: [
      {
        path: '/repair/type',
        name: 'NisbosNarkRepairType',
        component: () => import('@/views/nisbos_park/repair/type'),
        meta: { title: '报事报修类型', noCache: true }
      },
      {
        path: '/repair/rule',
        name: 'NisbosNarkRepairRule',
        component: () => import('@/views/nisbos_park/repair/rule'),
        meta: { title: '责任规则', noCache: true }
      },
      {
        path: '/repair/ruleDetail',
        name: 'NisbosNarkRepairRuleDetail',
        hidden: true,
        component: () => import('@/views/nisbos_park/repair/rule/detail'),
        meta: { title: '责任规则详情', noCache: true }
      },
      {
        path: '/repair/device',
        name: 'NisbosNarkRepairDevice',
        component: () => import('@/views/nisbos_park/repair/device'),
        meta: { title: '设备管理', noCache: true }
      },
      {
        path: '/repair/list',
        name: 'NisbosNarkRepairList',
        component: () => import('@/views/nisbos_park/repair/list'),
        // name: 'repair/list',
        meta: { title: '报事报修列表', noCache: true }
      },
      {
        path: '/repair/repairDetail',
        name: 'NisbosNarkRepairRepairDetail',
        // hidden: true,
        component: () => import('@/views/nisbos_park/repair/list/detail'),
        meta: { title: '报事报修详情', noCache: true }
      },
      // {
      //   path: '/repair/region',
      //   component: () => import('@/views/nisbos_park/repair/region'),
      //   meta: { title: '区域管理' }
      // },

      {
        path: '/assets/type',
        name: 'NisbosNarkAssetsType',
        component: () => import('@/views/nisbos_park/assets/type'),
        meta: { title: '资产分类', noCache: true }
      },
      {
        path: '/assets/board',
        name: 'NisbosNarkAssetsBoard',
        component: () => import('@/views/nisbos_park/assets/board'),
        meta: { title: '资产看板', noCache: true }
      },
      {
        path: '/assets/rule/list',
        name: 'NisbosNarkAssetsRuleList',
        component: () => import('@/views/nisbos_park/assets/rule/list'),
        meta: { title: '流程规则列表', noCache: true }
      },
      {
        path: '/assets/rule/detail',
        name: 'NisbosNarkAssetsRuleDetail',
        component: () => import('@/views/nisbos_park/assets/rule/detail'),
        meta: { title: '流程规则详情', noCache: true }
      },
      {
        path: '/assets/list',
        name: 'NisbosNarkAssetsList',
        component: () => import('@/views/nisbos_park/assets/list'),
        meta: { title: '资产列表', noCache: true }
      },
      {
        path: '/assets/list/add',
        name: 'NisbosNarkAssetsListAdd',
        component: () => import('@/views/nisbos_park/assets/list/add'),
        meta: { title: '编辑资产信息', noCache: false }
      },
      {
        path: '/assets/list/detail',
        name: 'NisbosNarkAssetsListDetail',
        component: () => import('@/views/nisbos_park/assets/list/detail'),
        meta: { title: '资产详情', noCache: true }
      },
      {
        path: '/assets/warehousing',
        name: 'NisbosNarkAssetsWarehousing',
        component: () => import('@/views/nisbos_park/assets/warehousing'),
        meta: { title: '资产入库列表', noCache: true }
      },
      {
        path: '/assets/warehousing/add',
        name: 'NisbosNarkAssetsWarehousingAdd',
        component: () => import('@/views/nisbos_park/assets/warehousing/add'),
        meta: { title: '编辑入库单', noCache: false }
      },
      {
        path: '/assets/warehousing/detail',
        name: 'NisbosNarkAssetsWarehousingDetail',
        component: () => import('@/views/nisbos_park/assets/warehousing/detail'),
        meta: { title: '入库单详情', noCache: true }
      },
      {
        path: '/assets/handle/list',
        name: 'NisbosNarkAssetsHandleList',
        component: () => import('@/views/nisbos_park/assets/handle/list'),
        meta: { title: '办理单列表(管理员)', noCache: true }
      },
      {
        path: '/assets/handle/detail',
        name: 'NisbosNarkAssetsHandleDetail',
        component: () => import('@/views/nisbos_park/assets/handle/detail'),
        meta: { title: '办理单详情', noCache: true }
      },
      {
        path: '/assets/handle/userList',
        name: 'NisbosNarkAssetsHandleUserList',
        component: () => import('@/views/nisbos_park/assets/handle/userList'),
        meta: { title: '办理单列表', noCache: true }
      },

    ]
  },

  {
    path: '/gateway',
    component: Layout,
    hidden: true,
    meta: { title: '门户管理', icon: 'list', noCache: true },
    children: [
      {
        path: '/gateway/index',
        name: 'gateway',
        component: () => import('@/views/gateway/index'),
        meta: { title: '门户装修(管理员)', noCache: true }
      },
      {
        path: '/gateway/user',
        name: 'gatewayUser',
        component: () => import('@/views/gateway/user'),
        meta: { title: '门户装修', noCache: true }
      }
    ]
  },
  // {
  //   path: '/noLogin',
  //   hidden: true,
  //   meta: { title: "免登录", icon: "list", noCache: true },
  //   children: [
  //     {
  //       path: '/index',
  //       component: () => import('@/views/noLogin/index'),
  //       name: '企业入孵',
  //       meta: { title: '企业入孵' }
  //     },
  //   ]
  // },

  {
    path: '/Incubation',
    hidden: true,
    component: () => import('@/views/noLogin/index'),
    name: 'Incubation',
    meta: { title: '企业入孵', noCache: true }
  },

  {
    path: '/view',
    name: '空白页',
    component: () => import('../views/view')
  },
  {
    path: '/common_admin/index',
    name: '首页',
    component: () => import('../views/common_admin/index')
  },

  {
    path: '/reset',
    name: '忘记密码',
    component: () => import('../views/reset')
  },
  {
    path: '/404',
    component: (resolve) => require(['../views/login'], resolve),
    hidden: true
  },
]

export default new Router({
  mode: 'history', // 去掉url中的#
  base: '/console',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

