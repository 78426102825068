<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="360px"
    @close="$refs.upload.clearFiles()"
  >
    <el-upload
      ref="upload"
      class="upload-container"
      :limit="1"
      accept=".xlsx, .xls"
      :headers="upload.headers"
      :disabled="upload.isUploading"
      :on-progress="handleFileUploadProgress"
      :on-success="handleFileSuccess"
      :on-error="handleFileError"
      :auto-upload="false"
      action="upload"
      :http-request="handleUploadFile"
      drag
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text">
        将文件拖到此处，或<em>点击上传</em>
      </div>

      <div slot="tip" class="el-upload__tip">
        <el-link class="download-template" type="primary" @click="importTemplate">下载模板</el-link>
      </div>
      <div slot="tip" class="el-upload__tip" style="color:red">提示：仅允许导入“xls”或“xlsx”格式文件！</div>
    </el-upload>

    <span slot="footer" class="dialog-footer mt20 ">
      <el-button @click="hide">取 消</el-button>
      <el-button type="primary" :loading="upload.isUploading" @click="onSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { downloadFile } from '../utils/nisbos';
import request from '../api/request';

// import { importServiceConsult } from '@/apiOld/user/service';
export default {
  name: 'ImportDialog',
  props: {
    title: {
      type: String,
      default: '导入'
    },
    templateUrl: {
      type: String,
      require: true,
      default: ''
    },
    api: {
      type: String,
      require: true,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      upload: {
        // 是否禁用上传
        isUploading: false,
        // 设置上传的请求头部
        headers: { 'Content-Type': 'text/plain' }
        // headers: { Authorization: 'Bearer ' + getToken() },
      }
    }
  },

  methods: {
    show() {
      this.upload.isUploading = false
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    /** 文件上传中处理 */
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    /** 文件上传成功处理*/
    handleFileSuccess(response, file, fileList) {
      this.upload.isUploading = false
      this.$refs.upload.clearFiles()
      this.$msgbox.alert(response.message, '导入结果', { dangerouslyUseHTMLString: true })
      this.hide()
      this.$emit('done')
    },
    /** 上传失败 */
    handleFileError(err) {
      this.upload.isUploading = false
      console.error(err)
    },
    /** 自定义上传excel */
    handleUploadFile(params) {
      const fileObject = params.file
      const file = new FormData()
      file.append('file', fileObject)
      this.upload.isUploading = true;
      // apiOld
      if (!this.api) {
        this.msgError('未设置上传链接！')
        return
      }
      const data = file
      return request({
        url: this.api,
        method: 'post',
        data,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      })
    },
    onSubmit() {
      this.$refs.upload.submit()
    },
    /** 下载模板操作 */
    importTemplate() {
      downloadFile(this.templateUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container /deep/ {
  .el-upload-dragger .el-upload__text em {
    color: inherit;
  }

  text-align: center;

  .el-upload__tip {
    text-align: left;
  }
}

.download-template {
  color: #409EFF;

  //&.el-link--info:hover {
  //  color: #409EFF;
  //  &.is-underline:hover:after {
  //    border-bottom: 1px solid #409EFF;
  //  }
  //}
}
</style>
