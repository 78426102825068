// 设备巡检
import Layout from '@/components/Layout'
const devices = [
  // {
  //   path: '/device',
  //   component: Layout,
  //   hidden: true,
  //   meta: { title: '设备管理', icon: 'list', noCache: true },
  //   children: [
  //     {
  //       path: 'deviceType',
  //       component: () => import('@/views/device/deviceManage/deviceType/index'),
  //       name: 'deviceType',
  //       meta: { title: '设备类型管理', icon: 'documentation', noCache: true }
  //     },
  //     {
  //       path: 'deviceArchives',
  //       component: () => import('@/views/device/deviceManage/deviceArchives/index'),
  //       name: 'deviceArchives',
  //       meta: { title: '设备档案管理', icon: 'documentation', noCache: true }
  //     }
  //   ]
  // },
  {
    path: '/device/checkingConfig',
    meta: { title: '巡检配置', icon: 'documentation', noCache: true },
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'checkingType',
        component: () => import('@/views/device/checkingConfig/checkingType/index'),
        name: 'checkingType',
        meta: { title: '巡检类型管理', icon: 'documentation', noCache: true }
      },
      {
        path: 'checkingPoint',
        component: () => import('@/views/device/checkingConfig/checkingPoint/index'),
        name: 'checkingPoint',
        meta: { title: '巡检点管理', icon: 'documentation', noCache: true }
      },
      {
        path: 'checkingCycle',
        component: () => import('@/views/device/checkingConfig/checkingCycle/index'),
        name: 'checkingCycle',
        meta: { title: '巡检周期管理', icon: 'documentation', noCache: true }
      },
      {
        path: 'checkingContent',
        component: () => import('@/views/device/checkingConfig/checkingContent/index'),
        name: 'checkingContent',
        meta: { title: '巡检内容管理', icon: 'documentation', noCache: true }
      },
      {
        path: 'optionTemplate',
        component: () => import('@/views/device/checkingConfig/optionTemplate/index'),
        name: 'optionTemplate',
        meta: { title: '选项模板管理', icon: 'documentation', noCache: true }
      }
    ]
  },
  {
    path: '/device/checkingTask',
    meta: { title: '巡检任务', icon: 'documentation', noCache: true },
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'checkingPlan',
        component: () => import('@/views/device/checkingTask/checkingPlan/index'),
        name: 'checkingPlan',
        meta: { title: '巡检计划管理', icon: 'documentation', noCache: true }
      },
      {
        path: 'task',
        component: () => import('@/views/device/checkingTask/task/index'),
        name: 'task',
        meta: { title: '巡检任务管理', icon: 'documentation', noCache: true }
      }
    ]
  },
  {
    path: '/device/checkingCount',
    meta: { title: '巡检统计', icon: 'documentation', noCache: true },
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'checkingRecord',
        component: () => import('@/views/device/checkingCount/checkingRecord/index'),
        name: 'checkingRecord',
        meta: { title: '巡检记录', icon: 'documentation', noCache: true }
      },
      {
        path: 'checkingDetail',
        hidden: true,
        component: () => import('@/views/device/checkingCount/checkingRecord/detail'),
        name: 'CheckingDetail',
        meta: { title: '巡检记录详情', icon: 'documentation', noCache: true }
      },
      {
        path: 'taskProgress',
        component: () => import('@/views/device/checkingCount/taskProgress/index'),
        name: 'taskProgress',
        meta: { title: '巡检任务进度', icon: 'documentation', noCache: true }
      },
      {
        path: 'taskCount',
        component: () => import('@/views/device/checkingCount/taskCount/index'),
        name: 'taskCount',
        meta: { title: '巡检统计', icon: 'documentation', noCache: true }
      }
    ]
  }
  // {
  //   path: '/device/energyAlarm',
  //   meta: { title: '用能警报', icon: 'documentation', noCache: true },
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     // {
  //     //   path: 'alarmMatter',
  //     //   component: () => import('@/views/device/energyAlarm/alarmMatter/index'),
  //     //   name: 'AlarmMatter',
  //     //   meta: { title: '用能巡检计划', icon: 'documentation', noCache: true }
  //     // },
  //     {
  //       path: 'alarmMatter/edit',
  //       hidden: true,
  //       component: () => import('@/views/device/energyAlarm/alarmMatter/edit'),
  //       name: 'Edit',
  //       meta: { title: '用能巡检计划详情', icon: 'documentation', noCache: true }
  //     }
  //     // {
  //     //   path: 'alarmRecord',
  //     //   component: () => import('@/views/device/energyAlarm/alarmRecord/index'),
  //     //   name: 'AlarmRecord',
  //     //   meta: { title: '用能巡检警报记录', icon: 'documentation', noCache: true }
  //     // }
  //   ]
  // }
]

export default devices
