var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-date-picker",
        _vm._b(
          {
            attrs: {
              "append-to-body": true,
              type: _vm.cDom.type ? _vm.cDom.type : "date",
              "start-placeholder": "开始日期",
              "range-separator": "至",
              "end-placeholder": "结束日期",
              placeholder: _vm.dom.placeholder || "请输入",
            },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.formValue,
              callback: function ($$v) {
                _vm.formValue = $$v
              },
              expression: "formValue",
            },
          },
          "el-date-picker",
          _vm.dom,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }