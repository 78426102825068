import { saasRequest } from '../../../api/request';

export default {
  data() {
    return {
      open: false,
      refName: 'form'
    }
  },
  computed: {
    dialogWidth() {
      switch (this.dom.size) {
        case 'mini ':
          return '350px'
        case 'small':
          return '500px'
        case 'big':
          return '800px'
        default:
          if (this.dom.size && this.dom.size.indexOf('px')) {
            return this.dom.size
          }
          return '500px'
      }
    }
  },
  methods: {
    /**
     * 验证表单
     * @return Boolean
     * */
    validateForm() {
      let result = true
      this.$refs[this.refName].validate((valid,obj) => {
        console.log(valid,obj)
        if (!valid) {
          result = valid
        }
      })
      return result
    },
    /** 获取详情数据 */
    getDetailData(dataApi, rowObj,dom) {
      console.log(dom,dataApi)
      if (!dataApi || !dataApi.api) {
        console.log('没有配置dataApi,不调用详情数据接口', dataApi)
        // 没有返回dataApi 直接返回空对象
        return Promise.resolve({})
      } else {
        
        const params = {}
        if (Array.isArray(dataApi.params)) {
          console.log(rowObj)
          // 设置请求参数
          for (const paramsElement of dataApi.params) {
            params[paramsElement] = rowObj[paramsElement]
          }
        }
        if(dataApi.linkParams){
          params[dataApi.linkParams.key]=rowObj[dataApi.linkParams.linkey]
        }
        if(dom&&dom.dfCode){
          Object.assign(params,{dfCode:dom.dfCode})
        }
        console.log(params)
        return saasRequest(dataApi, params).then(res => {
          console.log('详情接口数据', dataApi.dataKey, res)
          res = res[dataApi.dataKey || 'data']

          let result = {}
          if (!dataApi.defaultDataKey && (typeof res !== 'object' || Array.isArray(res))) {
            // 如果返回的数据是字符串，数组需要一个键名
            return Promise.reject('缺少必须配置 dataApi.defaultDataKey')
          }

          if (dataApi.defaultDataKey) {
            console.log('设置返回的数据key', dataApi.defaultDataKey)
            result[dataApi.defaultDataKey || 'defaultDataKey'] = res
          } else {
            result = res
          }

          const changeDataKey = dataApi.changeDataKey || {}
          if (Object.keys(changeDataKey).length && typeof result === 'object') {
            // 改变详情键值
            console.log('改变详情键值')
            for (const cKey in changeDataKey) {
              if (result[cKey]) {
                result[changeDataKey[cKey]] = result[cKey]
                delete result[cKey]
              }
            }
          }

          if (dataApi.isConcatRow) {
            for (const resultKeyKey in result) {
              rowObj[resultKeyKey] = result[resultKeyKey]
            }
            result = rowObj
          }
          // 只返回对象类型
          return result
        })
      }
    }
  }
}
