import request from '@/utils/request'

// 获取合同列表分页
export function cateTreeList(data) {
  return request({
    url: '/admin/sys-info-cate/treeList',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'post',
    data
  })
}

// 获取合同列表分页
export function contentPage(data) {
  return request({
    url: '/admin/sys-info-content/page',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'post',
    data
  })
}

// 创建资讯栏目
export function cateCreate(data) {
  return request({
    url: '/admin/sys-info-cate/create',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'post',
    data
  })
}

// 删除资讯栏目
export function cateDelete(params) {
  return request({
    url: '/admin/sys-info-cate/delete',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'post',
    params
  })
}

// 获得资讯栏目
export function cateGetById(params) {
  return request({
    url: '/admin/sys-info-cate/getById',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'get',
    params
  })
}

// 更新资讯栏目
export function cateUpdate(data) {
  return request({
    url: '/admin/sys-info-cate/update',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'post',
    data
  })
}

// 创建资讯内容
export function contentCreate(data) {
  return request({
    url: '/admin/sys-info-content/create',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'post',
    data
  })
}

// 获得资讯内容
export function contentGetById(params) {
  return request({
    url: '/admin/sys-info-content/getById',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'get',
    params
  })
}

// 更新资讯内容
export function contentUpdate(data) {
  return request({
    url: '/admin/sys-info-content/update',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'post',
    data
  })
}

// 删除资讯内容
export function contentDelete(params) {
  return request({
    url: '/admin/sys-info-content/delete',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'post',
    params
  })
}

// 获得一级栏目列表 全部
export function infoFirstCateList(params) {
  return request({
    url: '/portal/info/firstCateList',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'get',
    params
  })
}

// 获得所有轮播图配置表列表
export function bannerList(params) {
  return request({
    url: '/portal/banner/list',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'get',
    params
  })
}

// 获取三个一级栏目及内容
export function infoThreeCateWithContent(params) {
  return request({
    url: '/portal/info/threeCateWithContent',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'get',
    params
  })
}

// 根据parentId获得栏目列表
export function infoCateListByParentId(params) {
  return request({
    url: '/portal/info/cateListByParentId',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'get',
    params
  })
}

// 获得资讯内容 分页
export function infoContentPage(data) {
  return request({
    url: '/portal/info/contentPage',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'post',
    data
  })
}

// 获得资讯内容
export function infoContentById(params) {
  return request({
    url: '/portal/info/contentById',
    baseURL: process.env.VUE_APP_ADMIN_API,
    method: 'get',
    params
  })
}
