<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" class="logo" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="true"
        :active-text-color="settings.theme"
        :collapse-transition="false"
        mode="vertical"
        router
        @select="routeClick"
      >
        <div v-for="(item,index) in permission_routes" :key="index">
          <sidebar-item
            v-if="item.singleShow"
            :key="item.path + index"
            :item="item"
            :base-path="item.path"
          />
          <el-submenu v-if="item.meta && !item.hidden && !item.singleShow" :index="item.path">
            <template slot="title">
              <i class="title-icon" :class="'el-icon-menu' || (item.meta && item.meta.icon)" />
              <span>{{ item.meta && item.meta.title }}</span>
            </template>
            <sidebar-item
              v-for="(route, index) in item.children"
              :key="route.path + index"
              :item="route"
              :base-path="route.path"
            />
          </el-submenu>

        </div>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/assets/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  mounted() {
  },
  methods: {
    routeClick(item) {
      console.log(item, this.$store.getters.systemIdAndUrl)
    }
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['permission_routes', 'sidebar', 'routeMenu']),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>
<style lang='scss'  scoped>
.el-menu--vertical .nest-menu .el-submenu > .el-submenu__title:hover, .el-menu--vertical .el-menu-item:hover {
  color: white !important;
}
.router-link-active:hover span{
  color: white !important;
}
.logo{
  box-shadow: 1px 0px 0px 0px rgba(220,222,227,1);

}

</style>
