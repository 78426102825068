var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        "close-on-click-modal": false,
        width: "360px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: function ($event) {
          return _vm.$refs.upload.clearFiles()
        },
      },
    },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-container",
          attrs: {
            limit: 1,
            accept: ".xlsx, .xls",
            headers: _vm.upload.headers,
            disabled: _vm.upload.isUploading,
            "on-progress": _vm.handleFileUploadProgress,
            "on-success": _vm.handleFileSuccess,
            "on-error": _vm.handleFileError,
            "auto-upload": false,
            action: "upload",
            "http-request": _vm.handleUploadFile,
            drag: "",
          },
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v(" 将文件拖到此处，或"),
            _c("em", [_vm._v("点击上传")]),
          ]),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [
              _c(
                "el-link",
                {
                  staticClass: "download-template",
                  attrs: { type: "primary" },
                  on: { click: _vm.importTemplate },
                },
                [_vm._v("下载模板")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              staticStyle: { color: "red" },
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v("提示：仅允许导入“xls”或“xlsx”格式文件！")]
          ),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer mt20 ",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.hide } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.upload.isUploading },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }