import axios from 'axios'
// import Message from 'vue-m-message'
import { Message } from 'element-ui'
import { getToken, removeToken, getSystemId } from '@/utils/auth'
import router from '@/router'
import store from '@/store'
import qs from 'qs'
import Cookies from 'js-cookie'
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
// axios.defaults.headers['x-app-id'] = '476449634043998215';
// axios.defaults.headers['skipTokenAuthorize'] = true
// axios.defaults.headers['x-user-id'] = '476501801144520705'
// axios.defaults.headers['x-app-id'] = '511610133850107907'

// axios.defaults.headers['x-tenant-id'] = '476501800058195973'
if (Cookies.get('type') == 2) {
  axios.defaults.headers['x-tenant-id'] = Cookies.get('companyId')
} else {
  // console.log(store)
  // axios.defaults.headers['x-tenant-id'] = '476501800058195973'
}

// 创建axios实例

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '',
  // 超时
  timeout: 60000
})

// request拦截器
service.interceptors.request.use(config => {
  // console.log('config', config,process.env.NODE_ENV)
  // console.log(process.env.VUE_APP_BASE_API)
  if (config.url == 'http://api-develop.dev.nisbos.com/task-file/upload/task') {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }
  if (getSystemId()) {
    config.headers['systemId'] = getSystemId()
  } else {
    config.headers['systemId'] = '0'
  }
  if (sessionStorage.getItem('session_token')) {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('session_token')
  }
  if (getToken()) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  if (config.url.indexOf('niscommon') !== -1) { // url带有niscommon跳过权限判断
    config.headers['x-noauth'] = true
  }

  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // console.log(res)
  // 如果返回的数据中date不为0 则说明有异常 进行error提示, 并返回catch
  if (!res.data.success) {
    if(res.data.code === 10013){
      Message.error(res.data.message || '未知异常')
      store.dispatch('FedLogOut').then(() => {
        store.commit('SET_ROLES', [])
        router.push({ path: '/newLogin' })
      })
    }else if (res.data.code === 10001) {
      store.dispatch('refreshToken').then(() => {
        
      })
      
    }else{
      Message.error(res.data.message || '未知异常')
    }
    throw res.data
  }
  // 未设置状态码则默认成功状态
  return res.data
},
error => {
  console.log('err' + error, error.name, error.message, error.data)
  let { message } = error
  if (message === 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (error.message.includes('登录已过期')) {
    if (message.substr(message.length - 3) * 1 === 10001) {
      removeToken()
      location.reload()
      Message({
        message: '登录超时！',
        type: 'error'
      })
      return
    }
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  if (error.response && error.response.data && error.response.data.error) {
    message = error.response.data.error
  }
  // Message({
  //   message: message,
  //   type: 'error'
  // });
  return Promise.reject(error)
})

export default service

// 字典初始化
export function initDict(dom) {
  const path = Cookies.get('url')
  dom.forEach(col => {
    if (col['dictUrl']) {
      service({

        url: location.hostname == 'localhost' ? 'https:' + '//' + path + col['dictUrl'] : window.location.protocol + '//' + path + col['dictUrl'],
        method: col['dictMethod'] || 'get',
        data: {
          t: 1
        }
      }).then(res => {
        if (col['dictProps']) {
          col['data'] = res.data.map(item => {
            return {
              dictLabel: item[col['dictProps'].dictLabel],
              dictValue: item[col['dictProps'].dictValue]
            }
          })
        } else {
          col['data'] = res.data
        }
      })
    }
  })
}

export function saasRequest(a, params = {}) {
  const defaultParams = a.defaultParams || {}
  const changeParamsKey = a.changeParamsKey || {}
  params = JSON.parse(JSON.stringify(params))

  if (Object.keys(defaultParams).length && typeof params === 'object') {
    // 添加默认参数
    for (const pKey in defaultParams) {
      if (!params[pKey]) {
        params[pKey] = defaultParams[pKey]
      }
    }
  }

  if (Object.keys(changeParamsKey).length && typeof params === 'object') {
    // 改变参数键值
    for (const cKey in changeParamsKey) {
      if (params[cKey]) {
        params[changeParamsKey[cKey]] = params[cKey]
        delete params[cKey]
      }
    }
  }

  const jsonData = params
  let formData = params
  // console.log(a.api, a.method)
  if (a.paramsType && (a.paramsType.toLowerCase() === 'json' || a.paramsType.toLowerCase() === 'array')) {
    // console.log('清除formData')
    formData = undefined
  }
  const path = Cookies.get('url')
  // console.log(path)
  return service({
    url: location.hostname == 'localhost' ? 'https:' + '//' + path + a.api : window.location.protocol + '//' + path + a.api,
    baseURL: '',
    method: a.method || 'post',
    data: jsonData, // json
    params: formData // form

  }).then(res => {
    // console.log(res)
    return res
  })
}
export function saasRequestPost(a, params = {}) {
  const defaultParams = a.defaultParams || {}
  const changeParamsKey = a.changeParamsKey || {}
  params = JSON.parse(JSON.stringify(params))

  if (Object.keys(defaultParams).length && typeof params === 'object') {
    // 添加默认参数
    for (const pKey in defaultParams) {
      if (!params[pKey]) {
        params[pKey] = defaultParams[pKey]
      }
    }
  }

  if (Object.keys(changeParamsKey).length && typeof params === 'object') {
    // 改变参数键值
    for (const cKey in changeParamsKey) {
      if (params[cKey]) {
        params[changeParamsKey[cKey]] = params[cKey]
        delete params[cKey]
      }
    }
  }

  const jsonData = params
  let formData = params
  // console.log(a.api, a.method)
  if (a.paramsType && (a.paramsType.toLowerCase() === 'json' || a.paramsType.toLowerCase() === 'array')) {
    // console.log('清除formData')
    formData = undefined
  }
  const path = Cookies.get('url')
  // console.log(path)
  return service({
    url: location.hostname == 'localhost' ? 'https:' + '//' + path + a.api : window.location.protocol + '//' + path + a.api,
    baseURL: '',
    method: a.method || 'post',
    data: jsonData, // json
    params: formData // form
  }).then(res => {
    // console.log(res)
    return res
  })
}
export function formDataRequest(a, params = {}) {
  const path = Cookies.get('url')
  // console.log(path)
  return service({
    url: location.hostname == 'localhost' ? 'https:' + '//' + path + a.api : window.location.protocol + '//' + path + a.api,
    baseURL: '',
    method: a.method || 'post',
    data: jsonData, // json
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  }).then(res => {
    // console.log(res)
    return res
  })
}

// export function saasRequest(a, params = {}) {
//   const defaultParams = a.defaultParams || {}
//   const changeParamsKey = a.changeParamsKey || {}
//   params = JSON.parse(JSON.stringify(params))

//   if (Object.keys(defaultParams).length && typeof params === 'object') {
//     // 添加默认参数
//     for (const pKey in defaultParams) {
//       if (!params[pKey]) {
//         params[pKey] = defaultParams[pKey]
//       }
//     }
//   }

//   if (Object.keys(changeParamsKey).length && typeof params === 'object') {
//     // 改变参数键值
//     for (const cKey in changeParamsKey) {
//       if (params[cKey]) {
//         params[changeParamsKey[cKey]] = params[cKey]
//         delete params[cKey]
//       }
//     }
//   }

//   const jsonData = params
//   let formData = params
//   console.log(a.api, a.method)
//   if (a.paramsType && (a.paramsType.toLowerCase() === 'json' || a.paramsType.toLowerCase() === 'array')) {
//     console.log('清除formData')
//     formData = undefined
//   }

//   return service({
//     url: a.api,
//     baseURL: '',
//     method: a.method || 'post',
//     data: jsonData, // json
//     params: formData // form
//   })
// }

export function downloadFile(a, params = {}) {
  const defaultParams = a.defaultParams || {}
  const changeParamsKey = a.changeParamsKey || {}
  params = JSON.parse(JSON.stringify(params))

  // if (Object.keys(defaultParams).length && typeof params === 'object') {
  //   // 添加默认参数
  //   for (const pKey in defaultParams) {
  //     if (!params[pKey]) {
  //       params[pKey] = defaultParams[pKey]
  //     }
  //   }
  // }

  // if (Object.keys(changeParamsKey).length && typeof params === 'object') {
  //   // 改变参数键值
  //   for (const cKey in changeParamsKey) {
  //     if (params[cKey]) {
  //       params[changeParamsKey[cKey]] = params[cKey]
  //       delete params[cKey]
  //     }
  //   }
  // }
  // console.log(params)
  // params.ids=params.ids.join(',')
  const jsonData = params
  let formData = params
  // console.log(a.api, a.method)
  if (a.paramsType && (a.paramsType.toLowerCase() === 'json')) {
    // console.log('清除formData')
    formData = undefined
  }

  const path = Cookies.get('url')
  return service({
    url: location.hostname == 'localhost' ? 'https:' + '//' + path + a.api : window.location.protocol + '//' + path + a.api,
    baseURL: '',
    method: 'get',
    data: jsonData, // json
    params: formData, // form
    responseType: 'blob'
  }).then(res => {
    // console.log(res)
    return res
  })
}

export function saasRequestNew(a, params = {}) {
  const defaultParams = a.defaultParams || {}
  const changeParamsKey = a.changeParamsKey || {}
  params = JSON.parse(JSON.stringify(params))

  if (Object.keys(defaultParams).length && typeof params === 'object') {
    // 添加默认参数
    for (const pKey in defaultParams) {
      if (!params[pKey]) {
        params[pKey] = defaultParams[pKey]
      }
    }
  }

  if (Object.keys(changeParamsKey).length && typeof params === 'object') {
    // 改变参数键值
    for (const cKey in changeParamsKey) {
      if (params[cKey]) {
        params[changeParamsKey[cKey]] = params[cKey]
        delete params[cKey]
      }
    }
  }

  const jsonData = params
  let formData = params
  // console.log(a.api, a.method)
  if (a.paramsType && (a.paramsType.toLowerCase() === 'json' || a.paramsType.toLowerCase() === 'array')) {
    formData = undefined
  }
  const path = Cookies.get('url')
  return axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '',
    url: path + a.api,
    method: a.method || 'post',
    data: jsonData, // json
    params: formData, // form
    // 超时
    timeout: 60000
  })
}
