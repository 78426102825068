var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "pub_dialog",
      attrs: {
        title: _vm.dom.title || "查看详情",
        visible: _vm.open,
        width: _vm.dialogWidth,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.open = $event
        },
      },
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "60%",
            title: "编辑",
            visible: _vm.innerVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerVisible = $event
            },
          },
        },
        [
          _c("search-filter", {
            attrs: {
              table: _vm.editDialogDom.table,
              "query-dom": _vm.editDialogDom.queryDom,
            },
            on: { query: _vm.getLists },
          }),
          _c("v-table", {
            ref: "table",
            attrs: {
              table: _vm.editDialogDom.table,
              "select-row": _vm.selectRow,
              ids: _vm.ids,
              idsArr: _vm.idsArr,
            },
            on: {
              "update:selectRow": function ($event) {
                _vm.selectRow = $event
              },
              "update:select-row": function ($event) {
                _vm.selectRow = $event
              },
              "update:ids": function ($event) {
                _vm.ids = $event
              },
              "update:idsArr": function ($event) {
                _vm.idsArr = $event
              },
              "update:ids-arr": function ($event) {
                _vm.idsArr = $event
              },
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.innerVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.isLoading },
                  on: {
                    click: function ($event) {
                      return _vm.handleSubmit(_vm.editDialogDom)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "sketch_content" },
        [
          _vm.dom.dom
            ? _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "detail-form",
                  attrs: { "label-width": "120px" },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    _vm._l(_vm.dom.dom, function (d, index) {
                      return _c(
                        "el-col",
                        { key: index, attrs: { span: d.col || 24 } },
                        [
                          !(
                            d.component === "regionTreeSelect" &&
                            _vm.detailData[d.prop] == 0
                          ) && d.component != "fancyTable"
                            ? _c(
                                "el-form-item",
                                _vm._b(
                                  { attrs: { label: d.label } },
                                  "el-form-item",
                                  d,
                                  false
                                ),
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.detailData[d.prop])),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm.dom.table
            ? _c(
                "div",
                [
                  _c("search-filter", {
                    attrs: {
                      table: _vm.dom.table,
                      "query-dom": _vm.dom.queryDom,
                    },
                    on: { query: _vm.getList },
                  }),
                  _c("v-table", {
                    ref: "tables",
                    attrs: {
                      table: _vm.dom.table,
                      selectKey: _vm.dom.table.selectKey,
                      multiple: _vm.dom.multiple,
                      ids: _vm.idss,
                      idsArr: _vm.idsArrs,
                      tableRadio: _vm.tableRadio,
                    },
                    on: {
                      "update:multiple": function ($event) {
                        return _vm.$set(_vm.dom, "multiple", $event)
                      },
                      "update:ids": function ($event) {
                        _vm.idss = $event
                      },
                      "update:idsArr": function ($event) {
                        _vm.idsArrs = $event
                      },
                      "update:ids-arr": function ($event) {
                        _vm.idsArrs = $event
                      },
                      "update:tableRadio": function ($event) {
                        _vm.tableRadio = $event
                      },
                      "update:table-radio": function ($event) {
                        _vm.tableRadio = $event
                      },
                      "dialog-detail": _vm.openDetailDialog,
                      "dialog-add": _vm.openAddDialog,
                      "dialog-edit": _vm.openEditDialog,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.open = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.isLoading },
              on: { click: _vm.handleSubmits },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }