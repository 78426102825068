// 工单中心
import Layout from '@/components/Layout'
export default [{
  path: '/old/workOrder',
  component: Layout,
  hidden: true,
  meta: { title: '工单中心', icon: 'documentation', noCache: true },
  children: [
    {
      path: 'eventEdit',
      hidden: true,
      component: () => import('@/views/workOrder/event/edit'),
      name: 'WorkOrderEdit',
      meta: { title: '处理记录', icon: 'documentation', noCache: true }
    },
    {
      path: 'index',
      component: () => import('@/views/workOrder/event/index'),
      name: 'WorkOrderList',
      meta: { title: '工单中心', icon: 'documentation', noCache: true }
    }
  ]
},
{
  path: '/old/newsCenter',
  component: Layout,
  hidden: true,
  meta: { title: '消息中心', icon: 'documentation', noCache: true },
  children: [

    {
      path: 'newsList',
      component: () => import('@/views/oldNewsCenter/newsList'),
      name: 'newsList',
      meta: { title: '消息列表', icon: 'documentation', noCache: true }
    },
    {
      path: 'newsType',
      component: () => import('@/views/oldNewsCenter/newsType'),
      name: 'newsType',
      meta: { title: '消息类型', icon: 'documentation', noCache: true }
    },
    {
      path: 'newsTemplate',
      component: () => import('@/views/oldNewsCenter/newsTemplate'),
      name: 'newsTemplate',
      meta: { title: '消息模板', icon: 'documentation', noCache: true }
    }

  ]
},
{
  path: '/old/eventCenter',
  component: Layout,
  hidden: true,

  meta: { title: '事件中心', icon: 'documentation', noCache: true },
  children: [

    {
      path: 'eventList',
      component: () => import('@/views/oldEventCenter/eventList'),
      name: 'eventList',
      meta: { title: '事件列表', icon: 'documentation', noCache: true }
    },
    {
      path: 'eventLv',
      component: () => import('@/views/oldEventCenter/eventLv'),
      name: 'eventLv',
      meta: { title: '事件等级', icon: 'documentation', noCache: true }
    },
    {
      path: 'eventType',
      component: () => import('@/views/oldEventCenter/eventType'),
      name: 'eventType',
      meta: { title: '事件类型', icon: 'documentation', noCache: true }
    },
    {
      path: 'eventTenant',
      component: () => import('@/views/oldEventCenter/eventTenant'),
      name: 'eventTenant',
      meta: { title: '租户事件配置', icon: 'documentation', noCache: true }
    },
    {
      path: 'eventTenant/add',
      hidden: true,
      component: () => import('@/views/oldEventCenter/eventTenant/add.vue'),
      name: 'eventTenant',
      meta: { title: '租户事件配置', icon: 'documentation', noCache: true }
    },
    {
      path: 'eventTenantByTenant',
      hidden: true,
      component: () => import('@/views/oldEventCenter/eventTenantByTenant/index.vue'),
      name: 'eventTenantByTenant',
      meta: { title: '租户事件转移', icon: 'documentation', noCache: true }
    }
  ]
}
]

