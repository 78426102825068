// 门户内容管理
import Layout from '@/components/Layout'
import portalLayout from '@/views/portalContent/portalInformation/components/PortalLayout'
export default [
  {
    path: '/portal',
    component: portalLayout,
    hidden: true,
    meta: { title: '门户', icon: 'list', noCache: true },
    children: [
      {
        path: 'portalHomepage',
        component: () => import('@/views/portalContent/portalInformation/portalHomepage.vue'),
        name: 'PortalHomepage',
        meta: { title: '门户首页', icon: 'user', noCache: false }
      },
      {
        path: 'portalList',
        component: () => import('@/views/portalContent/portalInformation/portalList.vue'),
        name: 'PortalList',
        meta: { title: '门户列表页', icon: 'user', noCache: false }
      },
      {
        path: 'portalListDetail',
        component: () => import('@/views/portalContent/portalInformation/portalListDetail.vue'),
        name: 'PortalListDetail',
        meta: { title: '门户详情页', icon: 'user', noCache: false }
      }

    ]
  },
  {
    path: '/portalManage',
    component: Layout,
    hidden: true,
    meta: { title: '门户管理', icon: 'list', noCache: true },
    children: [
      {
        path: 'portalContent',
        component: () => import('@/views/portalContent/informationManage/content.vue'),
        name: 'PortalContent',
        meta: { title: '内容管理', icon: 'user', noCache: false }
      },
      {
        path: 'addContent',
        component: () => import('@/views/portalContent/informationManage/editContent.vue'),
        name: 'PortaleditContent',
        meta: { title: '新增内容', icon: 'user', noCache: false }
      },
      {
        path: 'editContent',
        component: () => import('@/views/portalContent/informationManage/editContent.vue'),
        name: 'PortaleditContent',
        meta: { title: '编辑内容', icon: 'user', noCache: false }
      }
    ]
  }
]

