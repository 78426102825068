<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="setInfo">
      <div class="right-menu">
        <!-- <template v-if="device !== 'mobile'">
          <search id="header-search" class="right-menu-item" />

          <screenfull id="screenfull" class="right-menu-item hover-effect" />
          <el-tooltip content="布局大小" effect="dark" placement="bottom">
            <size-select
              id="size-select"
              class="right-menu-item hover-effect"
            />
          </el-tooltip> </template
        > -->

        <!-- <el-dropdown
          class="right-menu-item new_item"
          style="margin-right: 10px; position: relative"
        > -->
        <el-popover
          v-model="visible"
          class="right-menu-item new_item right-menu-margin"
          placement="bottom"
          width="326"
          padding="0"
          :visible-arrow="false"
          trigger="hover"
        >
          <div class="new_item_box">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item index="1">
                <el-badge
                  :is-dot="n1"
                  class="item"
                  style="height: 20px; line-height: 20px"
                >
                  通知
                </el-badge>
              </el-menu-item>
              <el-menu-item index="2">
                <el-badge
                  :is-dot="n2"
                  class="item"
                  style="height: 20px; line-height: 20px"
                >
                  消息
                </el-badge>
              </el-menu-item>
              <el-menu-item index="3">
                <el-badge
                  :is-dot="n3"
                  class="item"
                  style="height: 20px; line-height: 20px"
                >
                  工单
                </el-badge>
              </el-menu-item>
            </el-menu>
          </div>
          <div v-if="activeIndex == 1" class="tongzhi">
            <div class="list">
              <div
                v-for="(item, index) in list1"
                :class="{ noborder: index + 1 == list1.length }"
              >
                <p class="p1">
                  <span v-if="item.status == 0" />{{ item.title }}
                </p>
                <p class="p2">{{ item.createDate }}</p>
              </div>
            </div>
            <div class="bottom">
              <a class="a1" @click="readCkeck()">清除未读</a>
              <a @click="goRouter(1)">查看全部</a>
            </div>
          </div>
          <div v-if="activeIndex == 2" class="tongzhi">
            <div class="list list2">
              <div
                v-for="(item, index) in list2"
                :class="{ noborder: index + 1 == list1.length }"
              >
                <img src="@/assets/image/tongzhi.png" class="limg">
                <div>
                  <p class="p1">{{ item.title }}</p>
                  <p class="p2">{{ item.createDate }}</p>
                </div>
              </div>
            </div>
            <div class="bottom">
              <a class="a2" @click="goRouter(2)">查看全部</a>
            </div>
          </div>
          <div v-if="activeIndex == 3" class="tongzhi">
            <div class="list list2 list3">
              <div
                v-for="(item, index) in list3"
                :class="{ noborder: index + 1 == list1.length }"
              >
                <div class="p_box">
                  <p class="p1">{{ item.title }}</p>
                  <p class="p2">{{ item.createDate }}</p>
                </div>
                <div v-if="item.level == 1" class="status_box">重要</div>
                <div v-if="item.level == 2" class="status_box c1">紧急</div>
                <div v-if="item.level == 3" class="status_box c2">普通</div>
                <div v-if="item.level == 4" class="status_box c3">较低</div>
              </div>
            </div>
            <div class="bottom">
              <a class="a2" @click="goRouter(3)">查看全部</a>
            </div>
          </div>
          <div slot="reference">
            <el-badge
              :value="num == 0 ? null : num"
              class="item"
              :max="99"
              style="height: 31px"
            >
              <span class="el-dropdown-link newsIcon">
                <i class="el-icon-bell" />
              </span>
            </el-badge>
          </div>
        </el-popover>
        <!-- </el-dropdown> -->

        <el-dropdown v-if="type == 2" class="right-menu-item">
          <span class="el-dropdown-link">
            {{ company.name }}<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in companyList"
              :key="item.id"
              @click.native="change(item)"
            >{{ item.name }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown
          class="avatar-container right-menu-item hover-effect"
          trigger="click"
        >
          <div class="avatar-wrapper">
            <!--          <svg-icon-->
            <!--            slot="prefix"-->
            <!--            icon-class="user"-->
            <!--            class="el-input__icon user-avatar"-->
            <!--          />-->
            <img :src="(!avatar||avatar.length===0)?require('@/assets/image/DefaultUserAvatar.png'):avatar" class="user-avatar">
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/user/profile">
              <el-dropdown-item>个人中心</el-dropdown-item>
            </router-link>
            <el-dropdown-item @click.native="logout">
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- <div v-if="$store.getters.name" class="corporate">
        {{ $store.getters.userInfo.companyName }} {{ $store.getters.name }}
      </div> -->
      <div v-if="$store.getters.name" class="corporate">
        {{ $store.getters.name }}
      </div>
      <div v-else class="corporate">欢迎您!</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import Cookies from 'js-cookie'
import { saasRequest } from '../../../../ls-form/api/request'
// import Screenfull from '@/jsonDemo/Screenfull'
// import SizeSelect from '@/jsonDemo/SizeSelect'
// import Search from '@/jsonDemo/HeaderSearch'
import { allSysBatchUpdateStatus, getEventTotal, getWorkListTopFive, getMsgListTopFive, getSysMsgListTopFive } from '@/api/work'
export default {
  components: {
    Breadcrumb,
    Hamburger
    // Screenfull
    // SizeSelect,
    // Search
  },
  data() {
    return {
      type: null,
      num: null,
      n1: false,
      n2: false,
      n3: false,
      visible: false,
      activeIndex: '1',
      list1: [],
      list2: [],
      list3: []
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'device',
      'avatar',
      'companyList',
      'config',
      'company'
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    }
  },
  // watch: {
  //   isFullscreen(cur) {
  //     const fixedHeader = document.querySelector('.fixed-header')
  //     const appMain = document.querySelector('.app-main')
  //     console.log(cur)
  //     if (cur) {
  //       fixedHeader && (fixedHeader.style.display = 'none')
  //       appMain && (appMain.style.paddingTop = 0)
  //     } else {
  //       fixedHeader && (fixedHeader.style.display = 'block')
  //       appMain && (appMain.style.paddingTop = '84px')
  //     }
  //   }
  // },
  mounted() {
    this.getEventTotal()
    this.getWorkListTopFive()
    this.getMsgListTopFive()
    this.getSysMsgListTopFive()
    this.type = Cookies.get('type')
    if (this.type == 2) {
      saasRequest({ api: '/user/getCompanyList', method: 'post' }, {}).then(
        (res) => {
          this.$store.commit('setCompany', res.data)
          this.$store.commit('setCompanyId', res.data[0])
          Cookies.set('companyId', res.data[0].id)
          console.log(this.$store, this.config)
        }
      )
    }
  },
  methods: {
    getEventTotal() {
      getEventTotal().then(res => {
        if (res.success) {
          const total = res.data.msgNoReadTotal + res.data.sysMsgNoReadTotal + res.data.workOrderNoProcessTotal
          if (total > 0) {
            this.num = total
          } else {
            this.num = null
          }
          if (res.data.msgNoReadTotal > 0) {
            this.n2 = true
          } else {
            this.n2 = false
          }
          if (res.data.sysMsgNoReadTotal > 0) {
            this.n1 = true
          } else {
            this.n1 = false
          }
          if (res.data.workOrderNoProcessTotal > 0) {
            this.n3 = true
          } else {
            this.n3 = false
          }
        }
      })
    },
    getWorkListTopFive() {
      getWorkListTopFive().then(res => {
        if (res.success) {
          this.list3 = res.data
        }
      })
    },
    getMsgListTopFive() {
      getMsgListTopFive().then(res => {
        if (res.success) {
          this.list2 = res.data
        }
      })
    },
    getSysMsgListTopFive() {
      getSysMsgListTopFive().then(res => {
        if (res.success) {
          this.list1 = res.data
        }
      })
    },
    handleSelect(index, path) {
      console.log(index, path)
      this.activeIndex = index + ''
    },
    readCkeck() {
      allSysBatchUpdateStatus().then(res => {
        if (res.success) {
          this.getEventTotal()
          this.getSysMsgListTopFive()
        } else {
          this.$message.error(res.msg ? res.msg : res.message)
        }
      })
    },
    goRouter(val) {
      if (val == 1) {
        this.$router.push({
          path: '/notice'
        })
      } else if (val == 2) {
        this.$router.push({
          path: '/newsList'
        })
      } else if (val == 3) {
        this.$router.push({
          path: '/workRecord'
        })
      }
    },
    change(item) {
      console.log(item)
      this.$store.commit('setCompanyId', item)
      Cookies.set('companyId', item.id)
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
      // 手动触发windows.resize事件
      setTimeout(() => {
        try {
          if (document.body.clientWidth <= 992) {
            return
          }
          const ev = document.createEvent('Event')
          ev.initEvent('resize', true, true)
          window.dispatchEvent(ev)
        } catch (e) {
          console.log(e)
        }
      })
    },
    logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        Cookies.remove('systemIds')
        this.$store.dispatch('FedLogOut').then(() => {
          location.reload()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.newsIcon {
  position: relative;
  top: -14px;
}
.el-popover {
  padding: 0 !important;
}
.noborder {
  border: none !important;
}
.a2 {
  width: 100% !important;
}
.tongzhi {
  p {
    margin: 0;
  }
  .list3 {
    > div {
      justify-content: space-between;
      padding-right: 10px !important;
    }
  }
  .list2 {
    > div {
      display: flex;
      width: 100%;
    }
    .p_box {
      max-width: 240px;
    }
    .limg {
      display: block;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .c1 {
      color: #e6a23c !important;
      border: 1px solid #e6a23c !important;
      background-color: #fdf6ec !important;
    }
    .c2 {
      color: #409eff !important;
      border: 1px solid #409eff !important;
      background-color: #ecf5ff !important;
    }
    .c3 {
      color: #67c23a !important;
      border: 1px solid #67c23a !important;
      background-color: #f0f9eb !important;
    }
    .status_box {
      font-size: 12px;
      color: #f56c6c;
      padding: 0 5px;
      border: 1px solid #f56c6c;
      background-color: #fef0f0;
      height: 25px;
      line-height: 25px;
      border-radius: 4px;
      margin-top: 7px;
    }
  }
  .list {
    margin-bottom: -12px;
    > div {
      padding: 10px 0 8px 0;
      border-bottom: 1px solid #eae7e7;
      overflow: hidden;
    }
    .p1 {
      font-size: 14px;
      line-height: 22px;
      color: #333;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      span {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 8px;
        margin-right: 5px;
      }
    }
    .p2 {
      font-size: 12px;
      line-height: 20px;
      color: #b3b1b1;
    }
  }
  .bottom {
    border-top: 1px solid #eae7e7;
    position: relative;
    width: 326px;
    left: -13px;
    bottom: -12px;
    a {
      display: inline-block;
      width: 50%;
      color: #409eff;
      text-align: center;
      height: 40px;
      line-height: 40px;
    }
    .a1 {
      border-right: 1px solid #eae7e7;
    }
  }
}
.new_item_box {
  width: 300px;
  li {
    width: 100px;
    text-align: center;
  }
}

.setInfo {
  display: flex;
  position: absolute;
  right: 35px;
}
.corporate {
  height: 46px;
  line-height: 46px;

  margin-right: 20px;
}

.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }
    .right-menu-margin {
      margin-right: 10px;
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 25px;
          height: 25px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
