// 公寓管理 系统设置
import Layout from '@/components/Layout'
const systemSettings = {
  path: '/systemSetting',
  component: Layout,
  hidden: true,
  meta: { title: '系统设置', icon: 'list', noCache: true },
  children: [
    {
      path: 'hydroelectricity',
      component: () => import('@/views/systemSettingsT/lease/hydroelectricity.vue'),
      name: 'SystemSettingHydroelectricity',
      meta: { title: '水电设置', icon: 'user', noCache: true }
    },
    {
      path: 'riskManagement',
      component: () => import('@/views/systemSettingsT/lease/riskManagement.vue'),
      name: 'SystemSettingRiskManagement',
      meta: { title: '预定规则设置', icon: 'user', noCache: true }
    },
    {
      path: 'expenseAccount',
      component: () => import('@/views/systemSettingsT/foundation/expenseAccount.vue'),
      name: 'SystemSettingExpenseAccount',
      meta: { title: '房源配置', icon: 'user', noCache: false }
    },
    {
      path: 'housingAllocation',
      component: () => import('@/views/systemSettingsT/foundation/housingAllocation.vue'),
      name: 'SystemSettingHousingAllocation',
      meta: { title: '费用科目', icon: 'user', noCache: false }
    },
    {
      path: 'managementInvoices',
      component: () => import('@/views/systemSettingsT/managementInvoices'),
      name: 'SystemSettingManagementInvoices',
      meta: { title: '发票管理', icon: 'user', noCache: false }
    }
  ]
}
export default systemSettings
