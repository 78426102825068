import request from '@/utils/request'

// 获取轮播图分页
export function bannerPage(data) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/sys-banner-config/page',
    method: 'post',
    data
  })
}

// 编辑轮播图内容
export function bannerUpdate(data) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/sys-banner-config/update',
    method: 'post',
    data
  })
}

// 新增轮播图内容
export function bannerCreate(data) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/sys-banner-config/create',
    method: 'post',
    data
  })
}

// 删除轮播图内容
export function bannerDelete(data) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/sys-banner-config/delete',
    method: 'post',
    params:data
  })
}

// 获取现有小程序配置
export function listByTenantId(params) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/sys-applet-layout/listByTenantId',
    method: 'get',
    params
  })
}

// 配置小程序内容
export function layoutCreate(data) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/sys-applet-layout/create',
    method: 'post',
    data
  })
}

// 配置园区内容
export function setParkInfo(data) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/sys-park-info/createOrUpdate',
    method: 'post',
    data
  })
}

// 获取现有园区内容
export function getParkInfo(params) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/sys-park-info/getParkInfo',
    method: 'get',
    params
  })
}

// 获取园区内容(无token)
export function getDefaultParkInfo(params) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/sys-park-info/noAuth/getDefaultParkInfo',
    method: 'get',
    params
  })
}

// 获取全部菜单
export function appMenuList(data) { 
  return request({
    url: '/auth/menu/appMenuList',
    method: 'post',
    data
  })
}

// 获取卡片分页
export function cardPage(data) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/card-statistics/page',
    method: 'post',
    data
  })
}

// 新增卡片
export function cardCreate(data) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/card-statistics/create',
    method: 'post',
    data
  })
}

// 编辑卡片
export function cardUpdate(data) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/card-statistics/update',
    method: 'post',
    data
  })
}

// 删除卡片
export function cardDelete(params) { 
  return request({
    baseURL: process.env.VUE_APP_ADMIN_API,
    url: '/admin/card-statistics/delete',
    method: 'post',
    params
  })
}