
<template>
  <div >
    <div>
      <span v-for="(d, index) in dom.dom" :key="index">
        <lsSelect :dom="d" v-model="form[d.prop]" @change="changeStatus" />
      </span>
    </div>
    <el-input
      placeholder="输入关键字进行过滤"
      v-model="filterText"
      style="width: 85%"
      v-if="dom.filter"
    >
    </el-input>
    <el-tree
      ref="tree"
      :data="data"
      :props="dom.props || { children: 'children', label: 'label' }"
      :node-key="dom.nodeKey || 'id'"
      :show-checkbox="dom.showCheckbox"
      v-bind="dom"
      @node-click="handleNodeClick"
      @check-change="checkChange"
      :filter-node-method="filterNode"
      :indent="5"
    />
  </div>
</template>

<script>
import { saasRequest } from "../../api/request";
import saasFormItem from "./saasFormItem.vue";

export default {
  name: "VTrees",
  components: {
    saasFormItem,
   
  },
  props: {
    dom: {
      type: Object,
      default: () => ({}),
    },
    forms: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [Array, String, Object,Number],
      default: () => [],
    },
  },
  data() {
    return {
      show:true,
      data: [],
      form: {},
      filterText: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    form: {
      immediate: true,
      handler(newVal, o) {
        console.log(this.dom, newVal);
        if (newVal.orgType == "EG") {
          this.getList({ rootParentCode: "00000000", orgType: "EG" });
          this.$bus.$emit("changeStatus");
        } else if (newVal.orgType == "BG") {
          this.getList({ rootParentCode: "BG_10000001", orgType: "BG" });
          this.$bus.$emit("changeStatus");
        }
        if (this.dom.selectLinkKey) {
          console.log("select", this.form1, this.dom);

          if (
            newVal[this.dom.selectLinkKey] &&
            newVal[this.dom.selectLinkKey] != o[this.dom.selectLinkKey]
          ) {
            let param = {};
            this.formValue = null;
            param[this.dom.selectLinkKey] = newVal[this.dom.selectLinkKey];
            this.getList({}, param);
          }
          console.log(this.dom);
        }
      },
    },
    form1: {
      immediate: true,
      handler(newVal, o) {
        console.log(this.dom, newVal);
        
        if (this.dom.selectLinkKey) {
          console.log("select", this.form1, this.dom);

          if (
            newVal[this.dom.selectLinkKey] &&
            newVal[this.dom.selectLinkKey] != o[this.dom.selectLinkKey]
          ) {
            let param = {};
            this.formValue = null;
            param[this.dom.selectLinkKey] = newVal[this.dom.selectLinkKey];
            this.getList({}, param);
          }
          console.log(this.dom);
        }else{
          this.getList({});
        }
      },
    },
    value(v) {
      Array.isArray(v) && this.setChecked(v);
    },
  },
  computed: {
    form1() {
      return JSON.parse(JSON.stringify(this.forms));
    },
  },
  mounted() {
   const queryDom = this.dom || []
      queryDom.forEach(dom => {
        console.log(dom)
        this.$set(this.form, dom.prop, dom.value || null)
      })
    console.log("prop", this.dom.prop);
    if (this.dom.selectLinkKey && this.form1[this.dom.selectLinkKey]) {
      let param = {};
      param[this.dom.selectLinkKey] = this.form1[this.dom.selectLinkKey];
      this.getList({}, param);
    } else {
      this.getList();
    }
     this.$bus.$on("close", (msg) => {
      // A发送来的消息
      console.log(msg);
      this.setChecked([])
     this.data=[]
    });
  },
  methods: {
    filterNode(value, data) {
      console.log(value, data, this.dom.props);
      if (!value) return true;
      return data[this.dom.props.label].indexOf(value) !== -1;
    },
    getList(rootParam, query) {
      console.log(this.dom, "树形结构", this.forms);

      let param = this.dom.queryParams ? this.dom.queryParams : {};
      if (this.dom.tableKey) {
        let obj = {};
        obj[this.dom.tableKey] = this.forms[this.dom.tableKey];
        Object.assign(param, obj);
      }
      Object.assign(param, rootParam);
      Object.assign(param, query);
      saasRequest(this.dom, param).then((res) => {
        console.log(res);
        // this.data = this.handleTree(res.data || [])
        this.data = res.data;
        this.noChildMenuId = this.filterMenu(this.data);
        Array.isArray(this.value) && this.setChecked(this.value);
        if(this.value===null){
          this.setChecked([]);
        }
        console.log(this.value);
      });
    },
    handleNodeClick(data, node) {
      let param = {};
      let query = {};

      let table = {};
      if (this.dom.table) {
        if (
          this.form[this.dom.table.isShow.key] == this.dom.table.isShow.value
        ) {
          table = this.dom.table;
        }
      }
      if (this.dom.nodeKeyArr) {
        this.dom.nodeKeyArr.forEach((item) => {
          param[item] = data[item];
        });
      }
      this.$emit(
        "nodeClickItem",
        this.dom.prop,
        data[this.dom.nodeKey],
        table,
        param
      );

      console.log(data, node, this.dom, this.form, param);
    },
    changeStatus(item) {
      this.$emit("changeStatus");
      console.log(item);
    },
    checkChange() {
      this.$emit("input", this.getCheckedKeys());
    },
    /**
     * 勾选已有的权限
     * */
    setChecked(checkedKeysArr) {
      console.log(checkedKeysArr,this.noChildMenuId);
      const result = checkedKeysArr.map((item) => {
        if (this.noChildMenuId.indexOf(item) > -1) {
          return item;
        }
      });
      console.log("resultresultresult", result);
      this.$refs.tree.setCheckedKeys(result);
    },
    /**
     * 过滤出没有子类的菜单code
     * @param menuList {Array} 菜单列表
     * @param result 筛选结果
     * */
    filterMenu(menuList = [], result = []) {
      const childrenKey = this.dom?.props?.children || "childrenData";
      menuList.forEach((item) => {
        if (!item[childrenKey] || !item[childrenKey].length) {
          result.push(item.id);
        } else {
          this.filterMenu(item[childrenKey], result);
        }
      });
      return result;
    },
    getCheckedKeys() {
      const nodes = this.$refs.tree.getCheckedNodes(false, true) || [];
      return nodes.map((item) => item[this.dom.nodeKey || "id"]);
    },
  },
};
</script>

<style scoped>
</style>

