import store from '@/store'

/**
 * 判断是否包含指定权限
 * @param el 指定绑定的dom
 * @param binding {Object}
 * */
function checkPermission(el, binding) {
  const { value } = binding
  // 接口返回的用户权限列表
  const permissions = store.getters && store.getters.permissions

  if (permissions.indexOf('*:*:*') >= 0) {
    // 管理员直接跳过判断
    return
  }

  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionRoles = value

      // some() 方法用于检测数组中的元素是否满足指定条件。
      const hasPermission = permissions.some(role => {
        return permissionRoles.includes(role)
      })

      if (!hasPermission) {
        // 如果不存在这个角色,则删除该dom
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  } else {
    throw new Error(`need permissions! Like v-permission="['admin','editor']"`)
  }
}

export default {
  // 当被绑定的元素插入到 DOM 中时
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  // 所在组件的 VNode 更新时调用
  update(el, binding) {
    checkPermission(el, binding)
  }
}
