<template>
  <div>
    <div v-if="dom.listType == 'text'">
      <el-upload
        class="upload-demo"
        :action="url"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :on-change="handleChange"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        multiple
        :headers="config"
        :on-exceed="handleExceed"
        :file-list="formValue"
        v-bind="dom"
        ref="upload"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </div>
    <div v-if="dom.listType == 'picture'">
      <el-upload
        class="avatar-uploader"
        :action="url"
        v-bind="dom"
        :headers="config"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        name="file"
        ref="upload"
      >
        <img v-if="formValue" :src="formValue" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  props: {
    dom: {
      type: Object,
      default: () => ({}),
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
    value: {
      type: [Object, Array, String],
      default: "",
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        console.log(this.value);

        if (!this.value) {
          this.formValue = [];
        } else {
          this.formValue = this.value;
        }
      },
    },
    dom: {
      handler(v) {
        console.log(v);
      },
      deep: true,
    },
    form1: {
      immediate: true,
      handler(val, o) {
        console.log("select", this.form, this.dom);
        if (this.dom.selectLinkKey) {
          console.log("select", this.form, this.dom);
          if (val[this.dom.selectLinkKey]) {
            this.url =
              window.location.protocol +
              "//" +
              Cookies.get("url") +
              "/file/upload/" +
              this.dom.prefix +
              "?" +
              this.dom.selectLinkKey +
              "=" +
              val[this.dom.selectLinkKey];
          }
        }
      },
    },
  },
  data() {
    return {
      formValue: null,
      token: "",
      url:this.dom.api? window.location.protocol +
        "//" +
        Cookies.get("url") +this.dom.api:
        window.location.protocol +
        "//" +
        Cookies.get("url") +
        "/file/upload/" +
        this.dom.prefix,
      fileList: [],
    };
  },
  mounted() {
    this.token = Cookies.get("nisbos_auth_token");
  },
  computed: {
    config() {
      return { Authorization: "Bearer " + this.token };
    },
    form1() {
      return JSON.parse(JSON.stringify(this.form));
    },
  },

  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      console.log(file, fileList);
    },
    beforeUpload(file) {
      console.log(this.dom);
      console.log(file);
      const isJPG = true;

      const isLt2M = file.size / 1024 / 1024 < this.dom.fileSize;
      //   if (this.dom.fileType) {
      //     console.log("1");
      //     if (this.dom.fileType.indexOf(file.type) > -1) {
      //       return true;
      //     } else {
      //       {
      //         this.$message.error(
      //           "上传头像图片只能是" + this.dom.fileType + "格式!"
      //         );
      //       }
      //       return false;
      //     }
      //   }

      if (this.dom.fileSize && this.dom.fileSize != 0) {
        if (!isLt2M) {
          this.$message.error("文件大小不能超过" + this.dom.fileSize + "M!");
        }
        return isLt2M;
      } else {
      }
    },
    handleSuccess(response, file, fileList) {
      console.log(response, file, fileList, this.formValue);
      if (response.success) {
        this.formValue = [];
        if (this.dom.allMessage) {
            this.formValue=fileList
          fileList.forEach((item) => {
            console.log(item)
            item.url= item.response.data,
            // let obj={
            //   name: item.name,
            //   size: item.size,
            //   url:item.url?item.url: item.response.data,

            //   type:item.type?item.type: item.raw.type,
            // }
           
            this.formValue=fileList
          });
        } else {
          fileList.forEach((item) => {
            this.formValue.push(item.response.data);
          });
        }

        console.log(this.formValue);
        this.$message.success(response.message);
        this.$emit("input", this.formValue);
      } else {
        this.formValue = [];
        this.fileList = [];
        this.$message.error(response.message);
      }

      // this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择  ${this.dom.limit} 个文件`);
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleAvatarSuccess(res, file) {
      this.formValue = res.data;
      console.log(res, file);
      this.$emit("input", this.formValue);
      this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
    },
    beforeAvatarUpload(file) {
      console.log(this.dom);
      console.log(file);
      const isJPG = true;

      const isLt2M = file.size / 1024 / 1024 < this.dom.fileSize;
      if (this.dom.fileType) {
        console.log("1");
        if (this.dom.fileType.indexOf(file.type) > -1) {
          return true;
        } else {
          {
            this.$message.error(
              "上传头像图片只能是" + this.dom.fileType + "格式!"
            );
          }
          return false;
        }
      }

      if (this.dom.fileSize != 0) {
        if (!isLt2M) {
          this.$message.error(
            "上传头像图片大小不能超过" + this.dom.fileSize + "!"
          );
        }
        return isLt2M;
      } else {
      }
    },
  },
};
</script>
<style scoped lang='scss'>
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 110px;
  height: 110px;
  line-height: 110px;
  text-align: center;
}
.avatar {
  width: 110px;
  height: 110px;
  display: block;
}
</style>