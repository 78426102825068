var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", {
            staticClass: "logo",
            attrs: { collapse: _vm.isCollapse },
          })
        : _vm._e(),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": true,
                "active-text-color": _vm.settings.theme,
                "collapse-transition": false,
                mode: "vertical",
                router: "",
              },
              on: { select: _vm.routeClick },
            },
            _vm._l(_vm.permission_routes, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  item.singleShow
                    ? _c("sidebar-item", {
                        key: item.path + index,
                        attrs: { item: item, "base-path": item.path },
                      })
                    : _vm._e(),
                  item.meta && !item.hidden && !item.singleShow
                    ? _c(
                        "el-submenu",
                        { attrs: { index: item.path } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", {
                              staticClass: "title-icon",
                              class:
                                "el-icon-menu" || (item.meta && item.meta.icon),
                            }),
                            _c("span", [
                              _vm._v(_vm._s(item.meta && item.meta.title)),
                            ]),
                          ]),
                          _vm._l(item.children, function (route, index) {
                            return _c("sidebar-item", {
                              key: route.path + index,
                              attrs: { item: route, "base-path": route.path },
                            })
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }