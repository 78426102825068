<template>
  <el-dialog
    :title="dom.title || '查看详情'"
    :visible.sync="open"
    :width="dialogWidth"
    class="pub_dialog"
    append-to-body
  >
    <el-dialog
      width="60%"
      title="编辑"
      :visible.sync="innerVisible"
      append-to-body
    >
      <search-filter
        :table="editDialogDom.table"
        :query-dom="editDialogDom.queryDom"
        @query="getLists"
      />
      <v-table
        ref="table"
        :table="editDialogDom.table"
        :select-row.sync="selectRow"
        :ids.sync="ids"
        :idsArr.sync="idsArr"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="isLoading"
          @click="handleSubmit(editDialogDom)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="sketch_content">
      <el-form
        v-if="dom.dom"
        ref="form"
        label-width="120px"
        class="detail-form"
      >
        <el-row :gutter="10">
          <el-col
            v-for="(d, index) in dom.dom"
            :key="index"
            :span="d.col || 24"
          >
            <el-form-item
              v-if="
                !(
                  d.component === 'regionTreeSelect' && detailData[d.prop] == 0
                ) && d.component != 'fancyTable'
              "
              :label="d.label"
              v-bind="d"
            >
              <span>{{ detailData[d.prop] }}</span>
            </el-form-item>
            <!-- <template v-if="d.component === 'fancyTable'">
              <component
                :is="d.component"
                v-model="detailData[d.prop]"
                v-bind="d"
                disabled
                is-detail
              />
            </template> -->
          </el-col>
        </el-row>
      </el-form>
      <div v-else-if="dom.table">
        <search-filter
          :table="dom.table"
          :query-dom="dom.queryDom"
          @query="getList"
        />
        <v-table
          ref="tables"
          :table="dom.table"
          :selectKey="dom.table.selectKey"
          :multiple.sync="dom.multiple"
          :ids.sync="idss"
          :idsArr.sync="idsArrs"
          :tableRadio.sync="tableRadio"
          @dialog-detail="openDetailDialog"
          @dialog-add="openAddDialog"
          @dialog-edit="openEditDialog"
        />
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="open = false">取 消</el-button>
      <el-button type="primary" :loading="isLoading" @click="handleSubmits"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import searchFilter from "../searchFilter.vue";
import dialogMixin from "./dialogMixin";
import vText from "../vText";

import vTable from "../vTable.vue";
import { getStructure } from "../../../api";
import { saasRequest, saasRequestNew } from "../../../api/request";
export default {
  name: "DetailDialog",

  mixins: [dialogMixin],
  components: { vText, vTable, searchFilter, },
  data() {
    return {
      src: "http://demo-bucket.fjoss.xstore.ctyun.cn/file/20210914/1.jfif", // (默认图片)
      open: false,
      isLoading: false,
      detailData: {},
      dom: {},
      treeId: "",
      innerVisible: false,
      editDialogDom: {},
      ids: [],
      idss: [],
      selectRow: {},
      idsArr: [],
      idsArrs: [],
      data: {},
      tableRadio: "",
    };
  },
  mounted() {},
  methods: {
    getList(item) {
      console.log(this.queryParams, item);

      this.$refs.tables.getList(false, item);
    },
    getLists(item) {
      console.log(this.queryParams, item);

      this.$refs.table.getList(false, item);
    },
    show(data, dom, obj, treeId) {
      console.log(data, dom, treeId);
      let that = this;
      that.treeId = treeId;
      this.open = true;
      this.dom = dom;
      if (dom.dataApi) {
        this.getDetailData(dom.dataApi, data, dom).then((res) => {
          if (res && Object.keys(res).length) {
            data = res;
          }

          this.detailData = JSON.parse(JSON.stringify(data))
            ? JSON.parse(JSON.stringify(data))
            : data;
          console.log("最终结果", data, res, this.detailData);
          this.dom = dom;
          if (this.dom.dom) {
            this.dom.dom.forEach((element) => {
              if (element.data) {
                element.data.forEach((item) => {
                  if (item.dictValue == this.detailData[element.prop]) {
                    this.detailData[element.prop] = item.dictLabel;
                  }
                });
              }
            });
          }
        });
      }

      if (dom.table) {
        let params = {};
        if (dom.paramsKey) {
          params[dom.paramsKey] = data[dom.paramsKey];
        } else if (dom.linkParams) {
          params[dom.linkParams.key] = data[dom.linkParams.linkey];
        }

        if (treeId) {
          params["orgCode"] = treeId;
        }
        if (this.$route.query) {
          Object.assign(params, this.$route.query);
        }
        if (this.dom.params) {
          this.dom.params.forEach((item) => {
            this.detailData[item] = data[item];
          });
          console.log(this.detailData);
        }
        this.dom = dom;
        this.$nextTick(() => {
          console.log(this.$refs);
          setTimeout(() => {
            this.$refs.tables.getList(false, params, data);
          }, 500);
        });
      }
    },
    openDetailDialog(obj) {
      getStructure(obj).then((res) => {
        console.log(res);
        this.detailDialogDom = res.data;
        let data = this.detailDialogDom.data;
        if (
          !this.detailDialogDom.data ||
          !Object.keys(this.detailDialogDom.data).length
        ) {
          // 如果没有传数据 则使用表格行的数据
          data = obj.row;
        }
        console.log(3455, this.detailDialogDom);
        this.$refs["dialog-detail"].show(
          data,
          this.detailDialogDom,
          obj,
          this.treeId,
          this.treeObj
        );
      });
    },
    /** 表格内发起新增弹框 */
    openAddDialog(obj) {
      getStructure(obj).then((res) => {
        this.addDialogDom = res.data;
        let data = this.addDialogDom.data;
        console.log(res);
        if (
          !this.addDialogDom.data ||
          !Object.keys(this.addDialogDom.data).length
        ) {
          // 如果没有传数据 则使用表格行的数据
          data = obj.row;
        }
        console.log(data);
        this.$refs["dialog-add"].show(
          data,
          this.addDialogDom,
          obj,
          this.treeId,
          this.treeObj
        );
      });
    },
    /** 表格内发起编辑弹框 */
    openEditDialog(obj) {
      getStructure(obj).then((res) => {
        this.editDialogDom = res.data;
        let data = this.editDialogDom.data;
        console.log(res);
        if (
          !this.editDialogDom.data ||
          !Object.keys(this.editDialogDom.data).length
        ) {
          // 如果没有传数据 则使用表格行的数据
          data = obj.row;
        }
        this.data = data;
        console.log(data, this.editDialogDom, obj);
        this.innerVisible = true;
        this.$nextTick(() => {
          console.log(this.$refs);
          setTimeout(() => {
            this.$refs.table.getList(false, {}, data);
          }, 500);
        });
      });
    },
    // 发起提交事件
    handleSubmit(editDialogDom) {
      console.log(">>>", editDialogDom, this.idsArr);
      // let obj = Object.assign({}, this.form);

      let obj = {};

      this.data[editDialogDom.prop] = this.idsArr;

      Object.assign(obj, this.data);
      if (editDialogDom.api) {
        this.loading = true;
        saasRequest(editDialogDom, obj)
          .then(() => {
            this.msgSuccess();
            this.innerVisible = false;
            if (this.dom.table) {
              let params = {};
              params[this.dom.paramsKey] = this.detailData[this.dom.paramsKey];
              if (this.treeId) {
                params["orgCode"] = this.treeId;
              }

              this.$nextTick(() => {
                console.log(this.$refs);
                setTimeout(() => {
                  this.$refs.tables.getList(false, params);
                }, 500);
              });
            }
            this.$emit("done");
          })
          .finally(() => {
            this.innerVisible = false;
          });
      } else {
        this.innerVisible = false;
      }
    },
    // 发起提交事件
    handleSubmits() {
      console.log(">>>", this.idsArrs, this.idss, this.dom, this.detailData);
      // let obj = Object.assign({}, this.form);
      let that = this;
      let obj = {};
      console.log(this.dom.table, this.detailData);
      if (this.dom.table && this.dom.table.selectKeyObj) {
        this.detailData[this.dom.prop] = this.idsArrs;
      } else {
        that.detailData[that.dom.prop] = [];
        that.detailData[that.dom.prop] = that.idss;
      }
      if (this.dom.table && this.dom.table.showRadio) {
        that.detailData[that.dom.prop] = this.tableRadio;
      }
      if (this.treeId) {
        this.detailData["orgCode"] = this.treeId;
      }

      if (this.$route.query) {
        Object.assign(obj, this.$route.query);
      }
      Object.assign(obj, this.detailData);
      console.log(obj);
      if (this.dom.api) {
        this.loading = true;
        saasRequest(this.dom, obj)
          .then(() => {
            this.msgSuccess();
            this.innerVisible = false;
            if (this.dom.table) {
              let params = {};
              params[this.dom.paramsKey] = this.detailData[this.dom.paramsKey];
              if (this.treeId) {
                params["orgCode"] = this.treeId;
              }
            }
            this.$emit("done");
          })
          .finally(() => {
            this.open = false;
          });
      } else {
        this.open = false;
      }
    },
  },
};
</script>

<style scoped lang='scss'>
::-webkit-scrollbar {
  display: none;
}
.sketch_content {
  overflow: auto;
  height: 434px;
  border-top: 1px solid #eff1f4;
  border-bottom: 1px solid #eff1f4;
  padding: 0px 30px 11px 27px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8798af;
    border-radius: 2px;
  }

  // 滚动条里面的轨道
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
/deep/ .detail-form .el-form-item {
  margin-bottom: 5px;
}
</style>
