<template>
  <div>
    
      <el-date-picker
        v-model="formValue"
        v-bind="dom"
        :append-to-body="true"
        :type="cDom.type ? cDom.type : 'date'"
        start-placeholder="开始日期"
         range-separator="至"
        end-placeholder="结束日期"
        :placeholder="dom.placeholder || '请输入'"
        @change="handleChange"
      />
   
  </div>
</template>

<script>
import { parseTime } from "../../utils/nisbos";

export default {
  name: "Vspadatepicker",
  props: {
    dom: {
      type: Object,
      default: () => ({}),
    },
    value: {
      default: "",
    },
  },
  data() {
    return {
      cDom: {}, // 组件配置,
      formValue: "",
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        console.log(this.value);
        this.formValue = this.value;
      },
    },
    dom: {
       immediate: true,
      handler(v) {
        this.cDom = JSON.parse(JSON.stringify(v));
        console.log(v);
      },
      deep: true,
    },

    "cDom.defaultTime"(v) {
      // 监测默认时间输入框
      this.setDefaultTime(v);
    },
    "cDom.pickerOptions"(v) {
      // 监测可选日期输入框
      this.setPickerOptions(v);
    },
    "cDom.value"(v) {
      this.$emit("input", v);
    },
  },
  mounted() {
    // console.log('1111', this.dom.defaultTime)
    // this.setPickerOptions(this.dom.pickerOptions);
    // this.setDefaultTime(this.dom.defaultTime);
  },
  methods: {
    handleChange(item) {
      console.log(item, this.formValue);
      // const value = parseTime(this.formValue, '{y}-{m}-{d}');
      // console.log(value)
      // this.$emit('input', parseTime(this.formValue, '{y}-{m}-{d}'))
      this.$emit("input", this.formValue);
    },
    /**
     * 设置默认时间（默认值为0时默认选择今天；为负数时默认选择n天前；为正数时默认选择n天后）
     */
    setDefaultTime(n) {
      // Number(v) === 0 ? this.dom.value = '' : this.dom.value = parseTime(new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * Number(v)), '{y}-{m}-{d}')
      const value = parseTime(
        new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * Number(n)),
        "{y}-{m}-{d}"
      );
      this.$set(this.cDom, "value", value);
      console.log("setDefaultTime", value);
    },
    /**
     * 设置可选择时间（默认值为0时所有日期都可选；为负数可选择现在n天前；为正数时可选择n天后）
     */
    setPickerOptions(n) {
      if (typeof n !== "object") {
        // 点击组件下拉时n={}，逻辑判断筛除该情况
        console.log("setPickerOptions");
        let canPickTime = ""; // 可选择的日期的时间戳
        canPickTime = new Date().getTime() + 24 * 60 * 60 * 1000 * Number(n);
        this.cDom.pickerOptions = {
          disabledDate(time) {
            return time.getTime() > canPickTime;
          },
        };
      }
    },
  },
};
</script>

<style>
</style>
