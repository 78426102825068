<template>
  <div
    class="sidebar-logo-container"
    :class="{ collapse: collapse }"
    @click="addMenu"
  >
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <img :src="logo2" class="sidebar-logo small-logo">
        <!--<img v-if="configSystem.icon" :src="configSystem.icon" class="sidebar-logo" style="width:45px;height:45px"/>-->
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <!--<img  v-if="configSystem.logo" :src="configSystem.logo" class="sidebar-logo" />
        <img  v-else :src="logoImg1" class="sidebar-logo" />-->
        <img :src="logo1" class="sidebar-logo">
      </router-link>
    </transition>
  </div>
</template>

<script>
// 智慧园区
import logoImg1 from '@/assets/logo/newS-logo.png'
import logoImg2 from '@/assets/logo/newS-logo2.png'

// 三创
// import logoImg1 from '@/assets/logo/new-logo-sc.png'
// import logoImg2 from '@/assets/logo/new-logo-sc2.png'

import { mapGetters, mapState } from 'vuex'
import { Message } from 'element-ui'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: '',
      logo1: logoImg1,
      logo2: logoImg2
    }
  },
  computed: {
    ...mapGetters(['configSystem'])
  },
  mounted() {
    if(sessionStorage.getItem('logoUrl')){
      this.logo1 = sessionStorage.getItem('logoUrl')
    }
    if(sessionStorage.getItem('iconUrl')){
      this.logo2 = sessionStorage.getItem('iconUrl')
    }
    window.addEventListener("setItem", () => {
      console.log('LOGO变化了')
      if(sessionStorage.getItem('logoUrl')){
        this.logo1 = sessionStorage.getItem('logoUrl')
      }else{
        this.logo1 = logoImg1
      }

      if(sessionStorage.getItem('iconUrl')){
        this.logo2 = sessionStorage.getItem('iconUrl')
      }else{
        this.logo2 = logoImg2
      }


    });
    // console.log(this.configSystem)
  },
  methods: {
    addMenu() {
      const obj2 = JSON.stringify(obj)
      // addMenuStructure({
      //   menuId: 193,

      //   structure: obj2,
      // }).then((res) => {});
      // updateMenuStructure({
      //   menuId: 158,
      //   structure: obj2,
      // }).then((res) => {});
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #ffffff;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    position: relative;

    & .sidebar-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      // width: 150px;
      width: 200px;
      height: 40px;
      // vertical-align: middle;
      // margin-right: 12px;
    }
    .small-logo{
      width: 36px;
      height: 40px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
