<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Watermark from '@/utils/watermark' // 路径不要写错
import { getCheckTenant } from '@/api/login'
import Cookies from 'js-cookie'

import { getSystemId, setToken } from '@/utils/auth'
import { saasRequest } from '../ls-form/api/request'

export default {
  data() {
    return {
      hostname: '',
      systemId: 0
    }
  },
  watch: {
    $route(to, from) {
      // console.log(to, from)
      // if (to.path != '/login') {
      // }
    }
  },
  mounted() {
    this.hostname = location.hostname
    const config = this.$store.getters.config
    const defaultSetting = this.$store.getters.defaultSetting
    this.systemId = getSystemId()
    if (this.hostname != defaultSetting.domain) {
      // console.log(this.hostname, config, defaultSetting)
      if (this.hostname == 'localhost') {
        this.hostname =
         'nispark-cloud.nistone.com'
        //'sanchuang-ops.nispark-dev.nistone.com'
      }
      if (!window.CUSTOM) {
        getCheckTenant(this.hostname)
          .then((res) => {
            this.systemId = res.data.systemId
            Cookies.set('systemId', res.data.systemId)
            Cookies.set('systemId2', res.data.systemId)
            // console.log('systemId', Cookies.get('systemId'))
            Cookies.set('url', res.data.domainApi)
            Cookies.set('commonUrl', res.data.domainApi)
            Cookies.set('type', res.data.type)
            //Cookies.set('companyList', res.data.companyList)
            Cookies.set('companyList', '[]');
            // console.log(Cookies.get('companyObj'), typeof Cookies.get('companyObj'))
            if (Cookies.get('companyObj')) {
              Cookies.set('systemId', Cookies.get('companyObj'))
              // console.log('systemId', Cookies.get('systemId'))
            }
            config.systemId = res.data.systemId
            config.domainApi = res.data.domainApi
            config.domain = res.data.domain
            config.type = res.data.type
            // const router = this.$store.state.configuration.siteconfig
              .indexPage
              ? this.$store.state.configuration.siteconfig.indexPage
              : this.$store.state.configuration.defaultSetting.indexPage

            if (
              Cookies.get('nisbos_auth_token')

            ) {
              saasRequest(
                { api: '/configSystem/queryConfigSystem', method: 'post' },
                {}
              ).then((res) => {
                this.$store.commit('setConfigSystem', res.data)
                // console.log(this.$store) // token
              })
              saasRequest({
                api: '/niscommon/getRoutersPermission',
                method: 'post'
              }).then((ress) => {
                this.$store.commit('setBtnPermission', ress.data)
                // Cookies.set('getRoutersPermission', JSON.stringify(ress.data), { expires: 7 })
              })
              saasRequest(
                { api: '/orgService/getSystemIdAndUrl', method: 'post' },
                { serviceName: '' }
              ).then((ress) => {
                // console.log(this.$store)
                this.$store.commit('setUrl', ress.data)

                if (Cookies.get('serviceId')) {
                  Cookies.set(
                    'url',
                    this.$store.getters.systemIdAndUrl[
                      Cookies.get('serviceId')
                    ]
                  )
                } else {
                  // console.log(res.data.domainApi)
                  Cookies.set('url', res.data.domainApi)
                }
                // console.log(ress)
              })
            }

            // this.$router.push({ path: router || "/" });
            // //  this.$router.push({ path: router  });
          })
          .catch(() => {
            // location.replace(defaultAppUrl)
          })
      }
    } else {
      Cookies.set('url', defaultSetting.domainApi)
      Cookies.set('commonUrl', defaultSetting.domain)
    }
    // console.log(this.$store.state);//string

    Watermark.set('')
  },
  methods: {}
}
</script>
<style lang="scss">
body {
  font-size: 14px !important;
}
#app {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  //background: #F0F2F5;
}
.el-message-box__wrapper {
  z-index: 9999 !important;
}
.mb20 {
  margin-bottom: 20px;
}
.tox-tinymce-aux {
  z-index: 5000 !important;
}
.app-container{
  .search-bbox {
    padding: 20px 20px 1px;
    margin-bottom: 16px;
    background-color: #fff;
  }
}
.container {
  background-color: #f0f2f5;
  min-height: calc(100vh - 84px);

  .container-main {
    background: white;
    padding: 20px;
    // min-height: 500px;
    height: 100vh;
    // min-height: calc(100vh - 124px);
  }

  .container-box {
    padding: 20px;
    min-height: calc(100vh - 124px);
  }

  .search-bbox {
    padding: 20px 20px 1px;
    margin-bottom: 16px;
    background-color: #fff;
  }
  .list-data-box {
    padding: 20px;
    background: #fff;
    margin-bottom: 15px;
  }
}

// 设备巡检管理全局样式
.work-wrap {
  background-color: #f0f2f5;
  min-height: calc(100vh - 84px);
}
.dashboard-editor-container {
  padding: 20px;
}

//element ui重写样式
.overwrite-style {
  &.el-tabs {
    .el-tabs__item {
      font-size: 16px;
      color: #666869;
      height: inherit;
      line-height: inherit;
      padding: 20px;
      font-weight: 400;
      &:nth-child(2) {
        &.is-top {
          padding-left: 20px;
        }
      }
      &.is-active {
        color: #02a4f5;
      }
    }
    .el-tabs__header {
      margin-bottom: 0;
    }
    .el-tabs__nav-wrap {
      padding: 0 10px;
      &::after {
        height: 1px;
      }
    }
  }
  //折叠栏
  &.el-collapse {
    border: none;
    .el-collapse-item {
      .el-collapse-item__header {
        background: #f7fafc;
        border: none;
        height: 48px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 48px;
      }
    }
  }
  //tag
  &.el-tag {
    &.el-tag--success {
      color: #10c469;
      border-color: #10c469;
      background: none;
    }
  }
  //表单
  &.el-form {
    .el-form-item {
      display: flex;
    }
    .el-form-item__label {
      color: #666869;
      flex-shrink: 0;
    }
    .el-form-item__content {
      flex: 1;
      text-align: left;
      color: #2c2d2d;
    }
    .el-input__inner,
    .el-select {
      width: 100%;
    }
  }
  //textarea
  &.el-textarea {
    font-size: 14px;
  }
  //表格
  &.el-table {
    th {
      .el-table__cell {
        .cell {
          font-size: 14px;
          color: #2c2d2d;
        }
      }
    }
    .cell {
      overflow: inherit;
    }
    .el-table__expanded-cell {
      padding: 0;
    }
  }
  &.el-image {
    width: 100px;
    height: 100px;
    margin: 8px;
    background: #efefef;
  }
  &.el-dialog__wrapper {
    text-align: center;
    white-space: nowrap;
    overflow: auto;

    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
    .el-dialog {
      margin: 30px auto !important;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      white-space: normal;
      // height: calc(100vh - 60px);
      // overflow: auto;
      .el-dialog__body {
        max-height: calc(100vh - 176px);
        overflow: auto;
        padding: 20px 20px 10px 20px;
      }
      .el-dialog__header {
        text-align: left;
        color: #2c2d2d;
        font-weight: 600;
        padding: 16px 24px;
        line-height: 1;
        // box-shadow: inset 0px -1px 0px 0px #ebeff2;
        border-bottom: 1px solid #eee;
        border-radius: 2px 2px 0px 0px;
      }
      .el-dialog__footer {
        padding: 10px 25px;
        // box-shadow: inset 0px 1px 0px 0px #ebeff2;
        border-top: 1px solid #eee;
        border-radius: 0px 0px 2px 2px;
      }
      // .el-dialog__title {
      //   font-size: 16px;
      //   color: #2c2d2d;
      //   font-weight: 700;
      // }
      // .el-dialog__body {
      //   padding-top: 20px;
      //   border-top: 1px solid #e1e7f5;
      //   border-bottom: 1px solid #e1e7f5;
      // }
    }
  }
  &.pd {
    padding: 10px 20px;
  }
}
.common-form-box {
  padding: 20px;
  background: #fff;
  margin-bottom: 16px;
  padding-bottom: 0;
}
.common-content-box {
  background: #fff;
  .common-content-top {
    position: relative;
    .ct-rtop {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }
  }
}
.dialog-layout {
  height: 100%;
  // height: 600px;
  display: flex;
  .dialog-layout-left,
  .dialog-layout-right {
    height: 100%;
    overflow: auto;
  }
  .dialog-layout-left {
    width: 160px;
  }
  .dialog-layout-right {
    flex: 1;
    padding: 16px;
    height: 100%;
    overflow: auto;
  }
}
.common-block {
  margin-bottom: 16px;
  border-bottom: 1px solid #ebeff2;
  .common-block-top {
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    color: #2c2d2d;
    margin-bottom: 16px;
  }
  .common-block-content {
    padding-bottom: 20px;
    .el-form-item {
      margin-bottom: 5px;
    }
  }
  &.no-margin {
    .el-form-item {
      margin-bottom: 0px;
    }
  }
  &.pd-style {
    .el-form-item {
      padding: 5px 0;
    }
  }
}
.bill-tag {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 30px;
  text-align: center;
  height: 24px;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-style: inherit;
  &.style-s {
    background: #10c469;
  }
  &.style-e {
    background: #ffa64d;
  }
}
.pd25 {
  padding: 25px;
}
.dtitle {
  margin-bottom: 16px !important;
}
.dtitle {
  .formTitle {
    // padding-left: 2%;
    margin-bottom: 16px !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: #555 !important;
    position: relative !important;
  }
}
.el-cascader-menu__wrap {
    height: 100%!important;
}
.list-header {
  display: flex;
}
.list-header .list-query {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.list-header .list-query .form-control {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
}
@media (min-width: 1200px) {
  .list-header .list-query .form-control {
    width: 33%;
  }
 }
 .form-control label {
  min-width: 60px;
  margin-right: 10px;
  font-weight: normal;
  color:rgba(0, 0, 0, 0.6);
  font-size:12px;
}
.form-control .el-input {
  flex: 1;
}
.operate-box{
  padding: 10px 0;
}
.fdialog  .el-form-item{
    margin-bottom: 5px!important;
}
.text  .el-form-item__content{
    width: 100%;
}
.c_input .el-col{
  padding:0 12px;
}
.c_input .el-form-item__content{
    width: 100%;
}
.c_input  .el-form-item{
  width: 100%;
}
.main2  .el-form-item__label{
    width: 150px!important;
    text-align: left;
}
/* // 清除 @/assets/theme/index.css element dialog 最大高度样式
.el-dialog__wrapper{
  .el-dialog{
    max-height: 80%;
    .el-dialog__body{
      max-height: none;
    }
  }
} */
 .el-tooltip__popper {
  border: 1px solid #dcdfe6 !important;
}
.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-right-color: #cdcfd4 !important;
}
.el-tooltip__popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #cdcfd4 !important;
}
.el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #cdcfd4 !important;
}
.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: #cdcfd4 !important;
}
</style>
