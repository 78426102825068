<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'Layout2'
}
</script>
<style scoped>
</style>
