var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("treeselect", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isDetail,
            expression: "!isDetail",
          },
        ],
        attrs: {
          options: _vm.deptOptions,
          normalizer: _vm.normalizer,
          "load-options": _vm.loadOptions,
          disabled: _vm.disabled,
          "show-count": "",
          placeholder: _vm.placeholder || "请选择",
        },
        on: { select: _vm.changeVal, input: _vm.clearVal },
        model: {
          value: _vm.localVal,
          callback: function ($$v) {
            _vm.localVal = $$v
          },
          expression: "localVal",
        },
      }),
      _vm.isDetail ? _c("span", [_vm._v(_vm._s(_vm.text))]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }