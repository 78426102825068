var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", { style: Object.assign({}, _vm.dom.style) }, [
      _vm._v(
        _vm._s(
          _vm.dom.options
            ? _vm.formatText(_vm.dom.text || _vm.value, _vm.dom.options)
            : _vm.dom.text || _vm.value
        )
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }