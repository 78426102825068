// 智慧园区 空间管理
import Layout from '@/components/Layout'
export default {
  path: '/house',
  component: Layout,
  hidden: true,
  meta: { title: '公寓管理', icon: 'list', noCache: true },
  children: [
    {
      path: 'workbench',
      component: () => import('@/views/apartment/workbench'),
      name: 'HouseWorkbench',
      meta: { title: '工作台', icon: 'user', noCache: false }
    },
    {
      path: 'building',
      component: () => import('@/views/apartment/building'),
      name: 'HouseBuilding',
      meta: { title: '楼宇列表', icon: 'user', noCache: false }
    },
    {
      path: 'roomType',
      component: () => import('@/views/apartment/roomType'),
      name: 'HouseRoomType',
      meta: { title: '房型模板', icon: 'user', noCache: false }
    },
    {
      path: 'houseSource',
      component: () => import('@/views/apartment/houseSource'),
      name: 'HouseHouseSource',
      // hidden: true,
      meta: { title: '房态列表', icon: 'user', noCache: false }
    },
    {
      path: 'sourceDetails',
      component: () => import('@/views/apartment/houseSource/details'),
      name: 'HouseSourceDetails',
      hidden: true,
      meta: { title: '房源详情', icon: 'user', noCache: false }
    },
    {
      path: 'tenant',
      component: () => import('@/views/apartment/lease/tenant'),
      name: 'HouseTenant',
      // hidden: true,
      meta: { title: '公寓预定', icon: 'user', noCache: false }
    },
    {
      path: 'contractList',
      component: () => import('@/views/apartment/lease/contractList'),
      name: 'HouseContractList',
      // hidden: true,
      meta: { title: '合同列表', icon: 'user', noCache: false }
    },
    {
      path: 'agreement',
      component: () => import('@/views/apartment/agreement/index'),
      name: 'HouseAgreement',
      meta: { title: '签约列表', icon: 'user', noCache: false }
    },
    {
      path: 'officeBuilding',
      component: () => import('@/views/apartment/officeBuilding'),
      name: 'HouseOfficeBuilding',
      meta: { title: '写字楼管理', icon: 'user', noCache: false }
    },
    {
      path: 'officeBuildingDetails',
      component: () => import('@/views/apartment/officeBuilding/details'),
      name: 'HouseOfficeBuildingDetails',
      hidden: true,
      meta: { title: '单元详情', icon: 'user', noCache: false }
    }
  ]
}

