var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _vm.dom.component === "el-input"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              _c(
                "el-input",
                _vm._b(
                  {
                    attrs: { placeholder: _vm.dom.placeholder || "请输入" },
                    on: { clear: _vm.clearValue },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.$emit("query")
                      },
                    },
                    model: {
                      value: _vm.formValue,
                      callback: function ($$v) {
                        _vm.formValue = $$v
                      },
                      expression: "formValue",
                    },
                  },
                  "el-input",
                  _vm.dom,
                  false
                ),
                [
                  _vm.dom.prepend
                    ? _c("template", { slot: "prepend" }, [
                        _vm._v(_vm._s(_vm.dom.prepend)),
                      ])
                    : _vm._e(),
                  _vm.dom.append
                    ? _c("template", { slot: "append" }, [
                        _vm._v(_vm._s(_vm.dom.append)),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm.dom.component === "el-checkbox"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              _c(
                "lsCheckBox",
                _vm._b(
                  {
                    staticStyle: { width: "100%" },
                    attrs: { dom: _vm.dom, form: _vm.form },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.formValueArray,
                      callback: function ($$v) {
                        _vm.formValueArray = $$v
                      },
                      expression: "formValueArray",
                    },
                  },
                  "lsCheckBox",
                  _vm.dom,
                  false
                )
              ),
            ],
            1
          )
        : _vm.dom.component === "el-upload"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c(
                  "vUpload",
                  _vm._b(
                    {
                      attrs: { dom: _vm.dom, form: _vm.form },
                      model: {
                        value: _vm.formValue,
                        callback: function ($$v) {
                          _vm.formValue = $$v
                        },
                        expression: "formValue",
                      },
                    },
                    "vUpload",
                    _vm.dom,
                    false
                  )
                ),
              ],
            ],
            2
          )
        : _vm.dom.component === "el-button"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              _c(
                "el-button",
                _vm._b(
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("handleBtnClick", _vm.dom)
                      },
                    },
                  },
                  "el-button",
                  _vm.dom,
                  false
                ),
                [_vm._v(" " + _vm._s(_vm.dom.name) + " ")]
              ),
            ],
            1
          )
        : _vm.dom.component === "addressSelect"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              _c(
                "lsAddressSelect",
                _vm._b(
                  {
                    attrs: { dom: _vm.dom, form: _vm.form },
                    on: {
                      "update:form": function ($event) {
                        _vm.form = $event
                      },
                      changeForm: _vm.changeForm,
                    },
                  },
                  "lsAddressSelect",
                  _vm.dom,
                  false
                )
              ),
            ],
            1
          )
        : _vm.dom.component === "el-cascader"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              _c(
                "lsCascader",
                _vm._b(
                  {
                    staticStyle: { width: "100%" },
                    attrs: { dom: _vm.dom, form: _vm.form },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.formValueArray,
                      callback: function ($$v) {
                        _vm.formValueArray = $$v
                      },
                      expression: "formValueArray",
                    },
                  },
                  "lsCascader",
                  _vm.dom,
                  false
                )
              ),
            ],
            1
          )
        : _vm.dom.component === "el-radio"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c(
                  "lsRadio",
                  _vm._b(
                    {
                      staticStyle: { width: "100%" },
                      attrs: { dom: _vm.dom },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.formValue,
                        callback: function ($$v) {
                          _vm.formValue = $$v
                        },
                        expression: "formValue",
                      },
                    },
                    "lsRadio",
                    _vm.dom,
                    false
                  )
                ),
              ],
            ],
            2
          )
        : _vm.dom.component === "el-select"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _vm.dom.multiple
                  ? _c(
                      "v-select",
                      _vm._b(
                        {
                          tag: "v-select",
                          staticStyle: { width: "89%" },
                          attrs: { dom: _vm.dom, form: _vm.form },
                          on: {
                            relationData: _vm.getData,
                            change: _vm.handleChange,
                            setLinkVal: _vm.setLinkVal,
                          },
                          model: {
                            value: _vm.formValueArray,
                            callback: function ($$v) {
                              _vm.formValueArray = $$v
                            },
                            expression: "formValueArray",
                          },
                        },
                        "v-select",
                        _vm.dom,
                        false
                      )
                    )
                  : _c(
                      "v-select",
                      _vm._b(
                        {
                          tag: "v-select",
                          staticStyle: { width: "89%" },
                          attrs: { dom: _vm.dom, form: _vm.form },
                          on: {
                            relationData: _vm.getData,
                            change: _vm.handleChange,
                            setLinkVal: _vm.setLinkVal,
                          },
                          model: {
                            value: _vm.formValue,
                            callback: function ($$v) {
                              _vm.formValue = $$v
                            },
                            expression: "formValue",
                          },
                        },
                        "v-select",
                        _vm.dom,
                        false
                      )
                    ),
              ],
            ],
            2
          )
        : _vm.dom.component === "el-transfer"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c(
                  "lsTransfer",
                  _vm._b(
                    {
                      staticStyle: { width: "100%" },
                      attrs: { dom: _vm.dom, form: _vm.form },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.formValueArray,
                        callback: function ($$v) {
                          _vm.formValueArray = $$v
                        },
                        expression: "formValueArray",
                      },
                    },
                    "lsTransfer",
                    _vm.dom,
                    false
                  )
                ),
              ],
            ],
            2
          )
        : _vm.dom.component === "el-date-picker"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c(
                  "vSpaDatepicker",
                  _vm._b(
                    {
                      staticStyle: { width: "80%" },
                      attrs: { dom: _vm.dom },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.formValue,
                        callback: function ($$v) {
                          _vm.formValue = $$v
                        },
                        expression: "formValue",
                      },
                    },
                    "vSpaDatepicker",
                    _vm.dom,
                    false
                  )
                ),
              ],
            ],
            2
          )
        : _vm.dom.component === "el-time-select"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c(
                  "lsTimeSelect",
                  _vm._b(
                    {
                      staticStyle: { width: "100%" },
                      attrs: { dom: _vm.dom },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.formValue,
                        callback: function ($$v) {
                          _vm.formValue = $$v
                        },
                        expression: "formValue",
                      },
                    },
                    "lsTimeSelect",
                    _vm.dom,
                    false
                  )
                ),
              ],
            ],
            2
          )
        : _vm.dom.component === "lsDateTimePicker"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c(
                  "lsDatePicker",
                  _vm._b(
                    {
                      staticStyle: { width: "100%" },
                      attrs: { dom: _vm.dom },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.formValue,
                        callback: function ($$v) {
                          _vm.formValue = $$v
                        },
                        expression: "formValue",
                      },
                    },
                    "lsDatePicker",
                    _vm.dom,
                    false
                  )
                ),
              ],
            ],
            2
          )
        : _vm.dom.component === "el-color-picker"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c("el-color-picker", {
                  model: {
                    value: _vm.formValue,
                    callback: function ($$v) {
                      _vm.formValue = $$v
                    },
                    expression: "formValue",
                  },
                }),
              ],
            ],
            2
          )
        : _vm.dom.component === "tinymce"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c("tinymce", {
                  attrs: { dom: _vm.dom, forms: _vm.form },
                  model: {
                    value: _vm.formValue,
                    callback: function ($$v) {
                      _vm.formValue = $$v
                    },
                    expression: "formValue",
                  },
                }),
              ],
            ],
            2
          )
        : _vm.dom.component === "v-tree"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c(
                  "v-tree",
                  _vm._b(
                    {
                      staticStyle: { width: "100%" },
                      attrs: { dom: _vm.dom, forms: _vm.form },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.formValue,
                        callback: function ($$v) {
                          _vm.formValue = $$v
                        },
                        expression: "formValue",
                      },
                    },
                    "v-tree",
                    _vm.dom,
                    false
                  )
                ),
              ],
            ],
            2
          )
        : _vm.dom.component === "treeSelect"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c(
                  "treeSelect",
                  _vm._b(
                    {
                      staticStyle: { width: "100%" },
                      attrs: { dom: _vm.dom, form: _vm.form },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.formValue,
                        callback: function ($$v) {
                          _vm.formValue = $$v
                        },
                        expression: "formValue",
                      },
                    },
                    "treeSelect",
                    _vm.dom,
                    false
                  )
                ),
              ],
            ],
            2
          )
        : _vm.dom.component === "fancyTable"
        ? _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              [
                _c(
                  "fancyTable",
                  _vm._b(
                    {
                      staticStyle: { width: "100%" },
                      attrs: { dom: _vm.dom, form: _vm.form },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.formValue,
                        callback: function ($$v) {
                          _vm.formValue = $$v
                        },
                        expression: "formValue",
                      },
                    },
                    "fancyTable",
                    _vm.dom,
                    false
                  )
                ),
              ],
            ],
            2
          )
        : _c(
            "el-form-item",
            _vm._b(
              { staticClass: "myFormItem" },
              "el-form-item",
              _vm.dom,
              false
            ),
            [
              _c(
                _vm.dom.component,
                _vm._b(
                  {
                    tag: "component",
                    staticStyle: { width: "100%" },
                    attrs: { dom: _vm.dom, form: _vm.form },
                    on: { change: _vm.handleChange, linkVal: _vm.linkVal },
                    model: {
                      value: _vm.formValue,
                      callback: function ($$v) {
                        _vm.formValue = $$v
                      },
                      expression: "formValue",
                    },
                  },
                  "component",
                  _vm.dom,
                  false
                )
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }