import Cookies from 'js-cookie'
const state = {
  siteconfig: {},
  defaultSetting: {}
}

const mutations = {
  GET_CONFIG: (state, data) => {
    state.siteconfig = data
  },
  GET_DEFAULT: (state, data) => {
    state.defaultSetting = data
  }
}

const actions = {
  getConfig({ commit }, data) {
    Cookies.set('url', data.siteDefault.domainApi);
    Cookies.set('siteInfoUrl', data.siteDefault.siteInfoUrl);
    Cookies.set('commonUrl', data.siteDefault.domain);
    Cookies.set('loginApi', data.siteDefault.loginApi);
    Cookies.set('menuApi', data.siteDefault.menuApi);
    if (JSON.stringify(data.siteList) !== '{}') {
      Cookies.set('url', data.siteList.domainApi === '' ? data.siteDefault.domainApi : data.siteList.domainApi);
      Cookies.set('siteInfoUrl', data.siteList.siteInfoUrl === '' ? data.siteDefault.siteInfoUrl : data.siteList.siteInfoUrl);
      Cookies.set('commonUrl', data.siteList.domain === '' ? data.siteDefault.domain : data.siteList.domain);
      Cookies.set('loginApi', data.siteList.loginApi === '' ? data.siteDefault.loginApi : data.siteList.loginApi);
      Cookies.set('menuApi', data.siteList.menuApi === '' ? data.siteDefault.menuApi : data.siteList.menuApi);
      
    }
    commit('GET_DEFAULT', data.siteDefault)
    commit('GET_CONFIG', data.siteList)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
