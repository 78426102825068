<template>
  <div>
    <el-table
            ref="multipleTable"
            :key="tableKey"
            v-loading="loading"
            :data="data"
            :row-class-name="rowClassName"
            style="width: 100%"
            :select-id="selectKey"
            @select="handleChangeRow"
            @row-dblclick="handleView"
            @row-click="rowClick"
            :max-height="table.height ? table.height : '420px'"
            :row-key="getRowKeys"
            :header-cell-style="{ background: '#F7F8FC', color: '#2C2D2D' }"
            :cell-style="cellStyle"
            v-bind="table"
            @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="table.showRadio" label="" width="30" fixed="left">
        <template slot-scope="scope">
          <el-radio
                  v-model="tableRadio"
                  :label="scope.row"
                  @change="changeRadio"
          ></el-radio>
        </template>
      </el-table-column>

      <!--    选择列    -->
      <el-table-column
              v-if="table.showSelect"
              type="selection"
              :reserve-selection="true"
      />

      <!--    序号列    -->
      <el-table-column
              v-if="table.showIndex"
              label="序号"
              align="center"
              width="80px"
              type=""
              fixed
      >
        <template slot-scope="scope">
          {{
          scope.$index +
          1 +
          (queryParams.currentPage - 1) * queryParams.pageSize
          }}
        </template>
      </el-table-column>

      <template v-for="(item, index) in table.dom">
          <template v-if="item.children && item.children.length">
            <v-table-column  :key="index" :column-header="item" :handleLink="handleLink"
                             :isShowBtn="isShowBtn"
                             :getDic_1="getDic_1"
                             :getTag="getTag"
                             :getDic="getDic"
                             :formatText="formatText"
                             :handleBtnClick="handleBtnClick"></v-table-column>
          </template>
          <template v-else>
        <el-table-column v-if="item.data" :key="index" v-bind="item">
          <template slot-scope="{ row }">
            {{ getDic(item.data, row[item.prop]) }}
          </template>
        </el-table-column>

        <el-table-column v-else-if="item.data_1" :key="index" v-bind="item">
          <template slot-scope="{ row }">
            <span
                    v-if="getDic_1(item.data_1, row[item.prop]).color"
                    class="dict-radio"
                    :style="{
                background: getDic_1(item.data_1, row[item.prop]).color,
              }"
            />

            <span
                    :style="{
                color: getDic_1(item.data_1, row[item.prop]).textColor,
              }"
            >
              {{ getDic_1(item.data_1, row[item.prop]).dictLabel || "未知" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column v-else-if="item.imgProp" :key="index" v-bind="item">
          <template slot-scope="{ row }">
            <el-image
                    style="width: 65px; height: 65px"
                    :src="row[item.imgProp]"
                    :preview-src-list="[row[item.imgProp]]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column v-else-if="item.options" :key="index" v-bind="item">
          <template slot-scope="{ row }">
            <!-- {{ formatData(row,item.options) }} -->
            <el-tooltip
                    v-if="item.options.type === 'length'"
                    effect="dark"
                    placement="top"
            >
              <div slot="content">
                {{ row[item.options.name] }}
              </div>
              <span>{{
                formatText(row[item.options.name], item.options)
              }}</span>
            </el-tooltip>
            <el-tag
                    v-else-if="item.options.type === 'tag'"
                    :type="getTag(item.options.data, row[item.options.name])"
                    disable-transitions
            >{{ row[item.options.name] }}</el-tag
            >
            <span v-else>{{
              formatText(row[item.options.name], item.options)
            }}</span>
          </template>
        </el-table-column>

        <!-- 直接展示值 -->
        <el-table-column
                v-else-if="item.prop !== 'customsBtns' && !item.custom"
                :key="index"
                v-bind="item"
        />

        <el-table-column v-else :key="index" v-bind="item">
          <template slot-scope="{ row }">
            <!--      按钮组      -->
            <div v-if="item.prop === 'customsBtns'">
              <span v-for="(b, bIndex) in item.btnArr" :key="bIndex">
                <el-button
                        v-if="isShowBtn(row, b) && b.permissions"
                        v-permission="b.permission || []"
                        size="mini"
                        type="text"
                        :icon="b.icon"
                        style="margin-left: 10px"
                        @click.stop="handleBtnClick(b, row, item)"
                >{{ b.name }}
                </el-button>
              </span>
            </div>

            <!--     复制     -->
            <copy
                    v-else-if="item.custom.type === 'copy'"
                    :text="row[item.custom.key]"
            />

            <!--     链接     -->
            <span
                    v-else-if="item.custom.type == 'link'"
                    style="color: #409eff; cursor: pointer"
                    @click="handleLink(row, item.custom)"
                    v-text="item.name ? item.name : row[item.custom.key]"
            ></span>

            <!--     正常     -->
            <span v-else v-text="row[item.custom.key]" />
          </template>
        </el-table-column>
        </template>
      </template>
    </el-table>

    <pagination
            v-show="!table.isThree && total > 0"
            :total="total"
            :page.sync="queryParams.currentPage"
            :limit.sync="queryParams.pageSize"
            :page-sizes="[10, 20, 30, 50]"
            @pagination="getList()"
    />
  </div>
</template>

<script>
    import VTableColumn from "./vTableColumn";
    import {saasRequest} from "../../api/request";
    import {parseTime} from "../../utils/nisbos";
    import {downloadFile} from "../../api/blob";

    export default {
    name: "VTable",
        components: {
            VTableColumn
        },
    props: {
      queryParams: {
        type: Object,
        default: () => {
          return {
            currentPage: 1,
            pageSize: 20,
          };
        },
      },
      single: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      table: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      tableHeaderDom: {
        type: Array,
        default: () => [],
      },
      ids: {
        type: Array,
        default: () => [],
      },
      selectRow: {
        type: Object,
        default: () => ({}),
      },
      selectKey: {
        type: String,
        default: "id",
      },
      selectKeyObj: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        tableRadio: "",
        loading: false,
        data: [],
        activeId: "",
        that: null,
        total: 1,
        tableKey: 0,
        rowKeysArr: [],
        editData: {},
        selection: [],
      };
    },
    created() {
      this.that = this;
      console.log(this.queryParams, this.table);
    },
    watch: {
      table: {
        immediate: true,
        deep: true,
        handler(val) {
          // }
          if (val.router) {
            val.router.forEach((item) => {
              let obj = {};

              console.log("yehua:");
              if (item.key!=undefined) {
                obj[item.key] = this.$route.query[item.linkKey];
              } else {
                obj[item] = this.$route.query[item];
              }

              Object.assign(this.queryParams, obj);
            });
          } else if (this.$route.query) {
            Object.assign(this.queryParams, this.$route.query);
          }
          this.getList();
          console.log(val);
        },
      },
    },
    mounted() {
      // const detail = JSON.stringify(editDialogDom, function(key, val) {
      //   if (typeof val === 'function') {
      //     return val + '';
      //   }
      //   return val;
      // })

      // const detail1 = JSON.parse(detail, function(k, v) {
      //   if (v.indexOf && v.indexOf('function') > -1) {
      //     // eslint-disable-next-line no-eval
      //     return eval('(function(){return ' + v + ' })()')
      //   }
      //   return v;
      // });

      console.log(123, this.tableHeaderDom);
      this.table.rowKey = this.table.rowKey ? this.table.rowKey : "id";
      setTimeout(() => {
        if (this.table.isThree) {
          this.table["tree-props"] = {
            children: "childrenData",
            hasChildren: "hasChildrenNode",
          };
        } else {
          this.table["tree-props"] = this.table.treeProps;
        }
        console.log(223444, this.table);
      });

      this.$bus.$on("changeStatus", (data) => {
        this.data = [];
      });
      this.$bus.$on("getEchartData", (msg) => {
        // A发送来的消息
        console.log(msg);
        this.queryParams = msg;
        this.getList();
      });

      console.log("yehua11111111111");
      console.log(this.queryParams);
    },
    methods: {
      getRowKeys(row) {
        return this.table.rowKey ? row[this.table.rowKey] : row["id"];
      },
      isShowBtn(row, b) {
            if (b.showBtn) {
                if (
                    b.showBtn &&
                    b.showBtn.value &&
                    row[b.showBtn.key] &&
                    b.showBtn.value.indexOf(row[b.showBtn.key]) > -1 &&
                    !b.noShowBtn &&
                    !b.showBtn.include
                ) {
                    return true;
                } else if (b.showBtn.include && b.showBtn.value == row[b.showBtn.key]) {
                    return true;
                } else if (b.showBtn.include && !b.showBtn.value && !b.showBtn.key) {
                    return true;
                } else {
                    return false;
                }
            } else if (b.noShowBtn) {
                if (row[b.noShowBtn.key] == b.noShowBtn.value) {
                    console.log(row, b);
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
      getDic_1(data_1, val) {
        if (typeof data_1[val] === "object") {
          return data_1[val];
        } else {
          return {};
        }
      },
      getTag(arr, val) {
        const v = arr.filter((item) => item.dictLabel == val);
        if (v.length) {
          return v[0].dictValue || "success";
        }
      },
      getDic(divArr, val) {
        const v = divArr.filter((item) => String(item.dictValue) === String(val));
        if (v.length) {
          return v[0].dictLabel || "未知";
        }
        return "未知";
      },
      handleChangeRow(selection, row) {
        /* 因为设置了:reserve-selection="true" 跨分页，所以这个事件里返回的参数selection会有重复数据，看不出当前操作的是否选中，
         * 故，直接判断这个data变量，每次操作的row，如果this.selectedMemberList里有，
         * 就说明当前是在执行【取消勾选】操作；否则就是勾选，要追加到this.selectedMemberList里。
         * 注意：(如果没设置:reserve-selection="true" 跨分页，此事件返回的已选中不会有重复数据，可以直接用selection参数判断)。
         * */
        // const res = this.selectedMemberList.find(m => m.memberID === row.memberID)
        // console.log('当前行是否在selectedMemberList中---', res)
        // if (res) {
        //   const theNew = this.selectedMemberList.filter(item => item.memberID !== row.memberID)
        //   this.selectedMemberList = JSON.parse(JSON.stringify(theNew))
        // } else {
        //   this.selectedMemberList = [...this.selectedMemberList, row]
        // }
        const ids = [];
        const idsArr = [];
        this.selection = selection;
        // this.names = [];
        let selectRow = {};
        selection.map((item) => {
          ids.push(item[this.selectKey]);
          selectRow = item;
          // this.names.push(item.productName);
        });
        if (this.table.selectKeyObj) {
          selection.forEach((item) => {
            console.log(item);
            const idsObj = {};
            idsObj[this.table.selectKeyObj[0]] = item[this.table.selectKeyObj[0]];
            idsObj[this.table.selectKeyObj[2]] = item[this.table.selectKeyObj[1]];
            console.log(idsObj);
            idsArr.push(idsObj);
          });
        }

        console.log(selection, selectRow, ids, idsArr);
        // this.single = selection.length !== 1;
        // this.multiple = !selection.length;
        this.$emit("update:single", selection.length !== 1);
        this.$emit("update:multiple", !selection.length);
        this.$emit("update:ids", ids);
        this.$emit("update:idsArr", idsArr);
        this.$emit("update:selectRow", selectRow);
        console.log("this.selectedMemberList =====", selection, row);
      },

      //列表默认选中状态
      toggleSelection(rows) {
        let select = [];
        let account = [];
        console.log(this.editData);

        if (this.editData) {
          let data = JSON.parse(JSON.stringify(this.editData));
          if (data.accounts) {
            account = data.accounts.split(",");
            console.log(data, account, this.data);
          }
          setTimeout(() => {
            this.data.forEach((item) => {
              account.forEach((i) => {
                if (i == item.account) {
                  select.push(item);
                  console.log(select);
                }
              });
            });
          }, 500);
          setTimeout(() => {
            if (select.length > 0) {
              console.log(select);
              this.$nextTick(() => {
                select.forEach((row) => {
                  this.$refs.multipleTable.toggleRowSelection(row, true);
                });
              });
            } else {
              console.log(this.table);
              this.$refs.multipleTable.clearSelection();
            }
          }, 800);
        }
      },
      changeRadio(item) {
        console.log(this.tableRadio, item);
        this.$emit("update:tableRadio", item.id);
      },
      /**
       * 单击表格行时
       * 1.记录列表展开情况
       * 2.查找到该行的 小箭头，并点击（进行展开，缩回操作）
       * */
      rowClick(row, column, event) {
        if (row.hasChildren) {
          const index = this.rowKeysArr.indexOf(row.id);
          if (index === -1) {
            // 如果当前行不在列表中，代表还未展开，添加到展开列表中
            this.rowKeysArr.push(row.id);
          } else {
            // 当前行已经在列表中，需要移除
            this.rowKeysArr.splice(index, 1);
          }
        }

        this.activeId = row.id;

        const pEl = event.target.closest(".el-table__row") || {};
        const arrow = pEl.querySelector(".el-icon-arrow-right");
        arrow && arrow.click();
      },
      /**
       * 高亮选中行
       * */
      rowClassName({ row }) {
        if (this.table.nthColor) {
          if ((rowIndex + 1) % 2 === 0) {
            return { background: this.table.nthColor };
          } else {
            return { background: this.table.othColor };
          }
        } else {
          return this.activeId === row.id ? "cur-active" : "";
        }
      },
      handleLink(item, { isInner, url, paramsArr }) {
        console.log(item);
        let params = "";
        if (Array.isArray(paramsArr) && paramsArr.length) {
          paramsArr.forEach((p, index) => {
            if (index === 0) {
              params += "?";
            } else if (index > 0) {
              params += "&";
            }
            if (p.key) {
              params += `${p.key}=${item[p.linkKey]}`;
            } else {
              params += `${p}=${item[p]}`;
            }
          });
        }
        console.log(params);
        if (isInner) {
          this.$router.push(url + params);
        } else if(url!=undefined){
          // 打开新窗口
          window.open(url + params);
        }else{
          window.open(item[paramsArr[0]] );
        }
      },
      handleView() {},
      getList(isThree = false, params, datas) {
        console.log(params, datas, this.editData, this.queryParams);
        if (this.table.rootParams) {
          if (this.queryParams[this.table.rootParams.key] == "EG") {
            Object.assign(this.queryParams, this.table.rootParams.param);
            console.log(this.queryParams);
          } else if (this.queryParams[this.table.rootParams.key] == "BG") {
            Object.assign(this.queryParams, this.table.rootParams.param1);
            console.log(this.queryParams, this.table.rootParams.param1);
          }
        }
        // const response = this.data2.
        if (!this.table.api) {
          console.log("未获取到列表api地址");
          return;
        }
        if(this.table.pageSize!=undefined&&this.table.pageSize!=0)
        {
          this.queryParams.pageSize=Number(this.table.pageSize)
        }
        this.loading = true;
        // this.deptList = [];
        // request({
        //   url: this.table.api,
        //   method: this.table.method || 'get',
        //   data: this.queryParams,
        //   params: this.queryParams
        // })

        if (params) {
          Object.assign(this.queryParams, params);
        }

        Object.assign(this.queryParams, this.table.params);
        if (!this.queryParams.orgCode) {
          Object.assign(this.queryParams, this.table.defaultParam);
        }
        console.log(this.table, this.queryParams);

        saasRequest(this.table, this.queryParams)
                .then((response) => {
                  if (datas) {
                    this.editData = datas;
                  }
                  this.toggleSelection();
                  this.total = Number(response.data.total);

                  let data = response.data;
                  console.log(data);
                  if (!Array.isArray(data)) {
                    data = response.data.records;
                  }
                  this.loading = false;
                  if (!isThree) {
                    console.log(this.data);
                    this.data = data;
                    return;
                  }

                  const treeKey = this.table.treeKey || "id";
                  const treeParentKey = this.table.treeParentKey || "parentId";

                  data.forEach((item) => {
                    if (item[treeParentKey] === null || item[treeParentKey] === "") {
                      item[treeParentKey] = 0;
                    }
                  });

                  this.data = this.handleTree(
                          data || [],
                          treeKey,
                          treeParentKey,
                          "children"
                  );

                  // console.log('treeKey', treeKey, 'treeParentKey', treeParentKey, this.data)

                  // setTimeout(() => {
                  //   this.tableKey++;
                  // });
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        const ids = [];
        const idsArr = [];
        this.selection = selection;
        // this.names = [];
        let selectRow = {};
        selection.map((item) => {
          ids.push(item[this.selectKey]);
          selectRow = item;
          // this.names.push(item.productName);
        });
        if (this.table.selectKeyObj) {
          selection.forEach((item) => {
            console.log(item);
            const idsObj = {};
            idsObj[this.table.selectKeyObj[0]] = item[this.table.selectKeyObj[0]];
            idsObj[this.table.selectKeyObj[2]] = item[this.table.selectKeyObj[1]];
            console.log(idsObj);
            idsArr.push(idsObj);
          });
        }

        console.log(selection, selectRow, ids, idsArr);
        // this.single = selection.length !== 1;
        // this.multiple = !selection.length;
        this.$emit("update:single", selection.length !== 1);
        this.$emit("update:multiple", !selection.length);
        this.$emit("update:ids", ids);
        this.$emit("update:selection", selection);
        this.$emit("update:idsArr", idsArr);
        this.$emit("update:selectRow", selectRow);
      },
      handleBtnClick(b, row, item) {
        console.log(row, b);
        b = Object.assign({}, b);
        const confirmType = ["export", "delete", "confirm"];
        if (b.type === "detail") {
          b.type = "dialog-detail";
        } else if (b.type === "add") {
          b.type = "dialog-add";
        } else if (b.type === "edit") {
          b.type = "dialog-edit";
        } else if (b.type === "link") {
          b.type = "link";
        }

        if (confirmType.indexOf(b.type) > -1) {
          const paramKey = b.paramKey || "id";
          const params = {
            // appId: '476449634043998215'
          };

          params[paramKey] = row[paramKey];
          if (b.linkParams) {
            params[b.linkParams.key] = row[b.linkParams.linkey];
          }
          if (b.paramStatus) {
            Object.assign(params, b.paramStatus);
          }
          if (b.paramKeyArr) {
            b.paramKeyArr.forEach((item) => {
              params[item] = row[item];
            });
          }
          if (this.$route.query) {
            Object.assign(params, this.$route.query);
          }
          console.log(params, b);
          this.$confirm(
                  b.showName
                          ? b.msg + row[b.showName]
                          : b.msg
                          ? b.msg
                          : "是否要执行该操作?",
                  b.title || "警告",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "primary",
                  }
          )
                  .then(() => {
                    if (b.type == "export") {
                      downloadFile(this.urlSplicing(b.url, params)).then((res) => {
                        console.log(res);
                        let blob = new Blob([res.data], {
                          type: "text/csv",
                        }); //type为所需要下载的文件格式，以xls文件为例
                        console.log(blob);
                        let link = document.createElement("a"); // 创建a标签
                        link.style.display = "none";
                        let objectUrl = URL.createObjectURL(blob);
                        link.href = objectUrl;
                        link.download = b.fileName ? b.fileName : "模板";
                        link.click();
                        URL.revokeObjectURL(objectUrl);
                      });
                    } else {
                      return saasRequest(b, params);
                    }
                  })
                  .then(() => {
                    this.msgSuccess();
                    this.getList(this.table.isThree);
                  });
        } else {
          const obj = {
            type: b.type,
            row: row,
            domKey: b.domKey,
            domApi: b.domApi,
            url: b.url,
            params: b.params,
            paramsArr: b.paramsArr,
            components: b.components,
            name: b.name,
            componentsProps: b.componentsProps,
            openType: b.openType,
          };
          console.log(obj, b);
          this.$emit(b.type, obj);
        }
      },
      urlSplicing(url, obj) {
        // url='http://admin.dev.nisbos.com'+url
        if (url.indexOf("?") == -1) {
          url += "?";
        }
        Object.keys(obj).map((key) => {
          url += key + "=" + obj[key] + "&";
        });
        url = url.slice(0, url.length - 1);
        console.log(url, obj);
        return url;
      },
      /**
       * options.type === 'time'时，文本为时间，format输入'{y}-{m}-{d} {h}:{i}:{s}'来决定展示的日期是否要保留小时、分钟、秒；
       * options.type === 'price'时，文本为金额，format为空时转换为千分位格式，format为'.'时保留两位小数；
       * options.type === 'length'时，文本为需要隐藏超出字符展示'...'格式，format为10时超出第十个字符串后展示'...'；
       * options.type === 'replace'时，替换；
       */
      formatText(text, options) {
        let formatText = text;
        switch (options.type) {
          case "time":
            formatText = parseTime(text, options.format);
            break;
          case "price":
            if (options.format.indexOf(".") !== -1) {
              formatText = parseFloat(text).toLocaleString("zh", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            } else {
              formatText = parseInt(text).toLocaleString();
            }
            break;
          case "length":
            if (
                    formatText &&
                    formatText.length > 0 &&
                    formatText.length > options.format
            )
              formatText = text.substr(0, options.format) + "...";
            break;
          case "replace":
            if (String(text) === options.old) {
              formatText = options.new;
            } else {
              console.log("旧的", text);
            }
            break;
          default:
            return text;
        }
        return formatText;
      },
      cellStyle({row, column, rowIndex, columnIndex}) {
        console.log("自定义颜色:add zouyh");
        var color='';
        if(column.property!=undefined&&this.table.cellStyle!=undefined){
          this.table.cellStyle.forEach((p, index) => {
            if (p.prop==column.property) {
             if(p.attributes!=undefined){
               p.attributes.forEach((att, index1) => {
                 switch (att.condition) {
                   case "eq":
                     if(att.value==row[column.property]){
                       color= att.color
                     }
                     break;
                   case "gt":
                     if(row[column.property]>att.value){
                        color= att.color
                      }
                     break;
                   case "ge":
                     if(row[column.property]>=att.value){
                       color= att.color
                     }
                     break;
                   case "lt":
                     if(row[column.property]<att.value){
                        color= att.color
                      }
                     break;
                   case "le":
                     if(row[column.property]<=att.value){
                       color= att.color
                     }
                     break;
                   case "bt":
                     if(row[column.property]<=att.maxValue&&row[column.property]>=att.minValue){
                       color= att.color
                     }
                     break;
                   default:
                     color = p.color;
                     break;
                 }
               });
             }else
               {
                 color = p.color;
               }
            }
          });
        }
        return color;
      }
    },
  };
</script>

<style scoped lang='scss'>
  /deep/ .el-table th.el-table__cell {
  }

  /deep/ .el-table .fixed-width .el-button--mini {
    display: block;
  }

  /deep/ .cur-active {
    background: #ecf5ff !important;
  }

  /deep/ tr {
    background: white !important;
  }

  .dict-radio {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: translateY(-20%);
  }
</style>
