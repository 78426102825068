// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"menuText": "#666769",
	"menuActiveText": "#02A4F5",
	"subMenuActiveText": "#02A4F5",
	"menuBg": "#FFFFFF",
	"menuHover": "#E8F5FF",
	"subMenuBg": "#FFFFFF",
	"subMenuHover": "#E8F5FF",
	"sideBarWidth": "260px"
};
module.exports = exports;
