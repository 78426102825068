<template>
  <div class="box">
    <div :style="{ ...dom.style }">{{ dom.options ? formatText(dom.text || value, dom.options) : dom.text || value }}</div>
  </div>
</template>

<script>
import { parseTime } from '../../utils/nisbos';

export default {
  name: 'Vtext',
  props: {
    dom: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [Number, Object, Array, String],
      default: ''
    }
  },
  methods: {
    /**
     * options.type === 'time'时，文本为时间，format输入'{y}-{m}-{d} {h}:{i}:{s}'来决定展示的日期是否要保留小时、分钟、秒；
     * options.type === 'price'时，文本为金额，format为空时转换为千分位格式，format为'.'时保留两位小数；
     * options.type === 'length'时，文本为需要隐藏超出字符展示'...'格式，format为10时超出第十个字符串后展示'...'；
     */
    formatText(text, options) {
      console.log('formatText', text, options);
      let formatText = text
      switch (options.type) {
        case 'time':
          formatText = parseTime(text, options.format);
          break;
        case 'price':
          if (options.format.indexOf('.') !== -1) {
            formatText = parseFloat(text).toLocaleString('zh', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          } else {
            formatText = parseInt(text).toLocaleString();
          }
          break;
        case 'length':
          if (formatText.length > 0 && formatText.length > options.format) formatText = text.substr(0, options.format) + '...'
          break;
      }
      return formatText
      // if (options.type === 'time') {
      //   return parseTime(text, options.format);
      // }
      // if (options.type === 'price') {
      //   if (Number(text) === 0) return text;
      //   if (isNaN(Number(text))) {
      //     this.$message.error('文本不是数字，无法进行金额转换');
      //     return text;
      //   }
      //   if (options.format.indexOf('.') !== -1) {
      //     return parseFloat(text).toLocaleString('zh', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      //   } else {
      //     return parseInt(text).toLocaleString();
      //   }
      // }
      // if (options.type === 'length') {
      //   const maxText = text.substr(0, options.format) + '...';
      //   return maxText;
      // }
    }
  }
};
</script>

<style>
</style>
