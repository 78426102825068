import axios from 'axios'
// import Message from 'vue-m-message'
import { Message } from 'element-ui'
import { getToken, removeToken, getSystemId } from '@/utils/auth'
import store from '@/store'
import router from '@/router'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers['x-app-id'] = '476449634043998215';
// axios.defaults.headers['skipTokenAuthorize'] = true
// axios.defaults.headers['x-user-id'] = '476501801144520705';
// axios.defaults.headers['x-app-id'] = '511610133850107907';
// axios.defaults.headers['x-tenant-id'] = '476501800058195973';

import Cookies from 'js-cookie'

// 创建axios实例
// console.log(process.env.VUE_APP_BASE_API)
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    // console.log('config', config)

    if (getSystemId()) {
      config.headers['systemId'] = getSystemId()
    } else {
      config.headers['systemId'] = '0'
    }

    if (getToken()) {
      // 排除 特殊接口不需要
      if (config.url !== '/nismes-user-service/saas-user/nisbos-login') {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
      }
    }
    if (config.url.indexOf('niscommon') !== -1) {
      // url带有niscommon跳过权限判断
      config.headers['x-noauth'] = true
    }
    //判断是否refresh token
    console.log(config.data && config.data.refreshToken)
    if(config.data && config.data.refreshToken){
      config.headers['Authorization'] = 'Bearer ' + Cookies.get('refreshToken')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    console.log(res)
    // 如果返回的数据中date不为0 则说明有异常 进行error提示, 并返回catch
    if (res.data.code !== 0 && !(res.data instanceof Blob) && !res.data.success) {
      if(res.data.code === 10013){
        Message.error(res.data.message || '未知异常')
        store.dispatch('FedLogOut').then(() => {
          store.commit('SET_ROLES', [])
          router.push({ path: '/newLogin' })
        })
      }else if (res.data.code === 10001) {
        store.dispatch('refreshToken').then(() => {
          
        })
        
      }else{
        Message.error(res.data.message || '未知异常')
      }
      throw res.data
    }
    // 未设置状态码则默认成功状态
    // 文件导出特殊处理(获取响应标头)
    if (res.data instanceof Blob) {
      return res
    } else {
      return res.data
    }
  },
  (error) => {
    console.log('err' + error, error.name, error.message, error.data)
    let { message } = error
    if (message === 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      if (message.substr(message.length - 3) * 1 === 401) {
        removeToken()
        // location.reload();
        Message({
          message: '登录超时！',
          type: 'error'
        })
        return
      }
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    if (error.response && error.response.data && error.response.data.error) {
      message = error.response.data.error
    }
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default service
// 创建axios实例
export const services = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/wapi',
  // 超时
  timeout: 60000
})

// request拦截器
services.interceptors.request.use(
  (config) => {
    // console.log('config', config)

    if (getSystemId()) {
      config.headers['systemId'] = getSystemId()
    } else {
      config.headers['systemId'] = '0'
    }

    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if (config.url.indexOf('niscommon') !== -1) {
      // url带有niscommon跳过权限判断
      config.headers['x-noauth'] = true
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
services.interceptors.response.use(
  (res) => {
    // 如果返回的数据中date不为0 则说明有异常 进行error提示, 并返回catch
    if (res.data.code !== 0) {
      Message.error(res.data.message || '未知异常')
      if (res.data.code === 10001) {
        alert(1)
        // store.dispatch('FedLogOut').then(() => {
        //   store.commit('SET_ROLES', [])
        //   router.push({ path: '/newLogin' })
        // })
      }
      throw res.data
    }

    // 未设置状态码则默认成功状态
    return res.data
  },
  (error) => {
    console.log('err' + error, error.name, error.message, error.data)
    let { message } = error
    if (message === 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      if (message.substr(message.length - 3) * 1 === 401) {
        removeToken()
        location.reload()
        Message({
          message: '登录超时！',
          type: 'error'
        })
        return
      }
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    if (error.response && error.response.data && error.response.data.error) {
      message = error.response.data.error
    }
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export function saasRequest(a, params = {}) {
  const defaultParams = a.defaultParams || {}
  const changeParamsKey = a.changeParamsKey || {}
  params = JSON.parse(JSON.stringify(params))

  if (Object.keys(defaultParams).length && typeof params === 'object') {
    // 添加默认参数
    for (const pKey in defaultParams) {
      if (!params[pKey]) {
        params[pKey] = defaultParams[pKey]
      }
    }
  }

  if (Object.keys(changeParamsKey).length && typeof params === 'object') {
    // 改变参数键值
    for (const cKey in changeParamsKey) {
      if (params[cKey]) {
        params[changeParamsKey[cKey]] = params[cKey]
        delete params[cKey]
      }
    }
  }

  const jsonData = params
  let formData = params
  // console.log(a.api, a.method)
  if (
    a.paramsType &&
    (a.paramsType.toLowerCase() === 'json' ||
      a.paramsType.toLowerCase() === 'array')
  ) {
    // console.log('清除formData')
    formData = undefined
  }
  const path = Cookies.get('url')
  return service({
    url: window.location.protocol + '//' + path + a.api,
    baseURL: '',
    method: a.method || 'post',
    data: jsonData, // json
    params: formData // form
  })
}
// export function saasRequest(a, params = {}) {
//   const defaultParams = a.defaultParams || {}
//   const changeParamsKey = a.changeParamsKey || {}
//   params = JSON.parse(JSON.stringify(params))

//   if (Object.keys(defaultParams).length && typeof params === 'object') {
//     // 添加默认参数
//     for (const pKey in defaultParams) {
//       if (!params[pKey]) {
//         params[pKey] = defaultParams[pKey]
//       }
//     }
//   }

//   if (Object.keys(changeParamsKey).length && typeof params === 'object') {
//     // 改变参数键值
//     for (const cKey in changeParamsKey) {
//       if (params[cKey]) {
//         params[changeParamsKey[cKey]] = params[cKey]
//         delete params[cKey]
//       }
//     }
//   }

//   const jsonData = params
//   let formData = params
//   console.log(a.api, a.method)
//   if (a.paramsType && (a.paramsType.toLowerCase() === 'json' || a.paramsType.toLowerCase() === 'array')) {
//     console.log('清除formData')
//     formData = undefined
//   }

//   return service({
//     url: a.api,
//     baseURL: '',
//     method: a.method || 'post',
//     data: jsonData, // json
//     params: formData // form
//   })
// }

export function saasRequestNew(a, params = {}) {
  const defaultParams = a.defaultParams || {}
  const changeParamsKey = a.changeParamsKey || {}
  params = JSON.parse(JSON.stringify(params))

  if (Object.keys(defaultParams).length && typeof params === 'object') {
    // 添加默认参数
    for (const pKey in defaultParams) {
      if (!params[pKey]) {
        params[pKey] = defaultParams[pKey]
      }
    }
  }

  if (Object.keys(changeParamsKey).length && typeof params === 'object') {
    // 改变参数键值
    for (const cKey in changeParamsKey) {
      if (params[cKey]) {
        params[changeParamsKey[cKey]] = params[cKey]
        delete params[cKey]
      }
    }
  }

  const jsonData = params
  let formData = params
  // console.log(a.api, a.method)
  if (
    a.paramsType &&
    (a.paramsType.toLowerCase() === 'json' ||
      a.paramsType.toLowerCase() === 'array')
  ) {
    // console.log('清除formData')
    formData = undefined
  }
  const path = Cookies.get('url')
  return axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '',
    url: path + a.api,
    method: a.method || 'post',
    data: jsonData, // json
    params: formData, // form
    // 超时
    timeout: 60000
  })
}
