import request from '@/utils/request'

import { saasRequest, services } from '@/utils/request'
import axios from 'axios'
import { getSystemId, getToken } from '@/utils/auth'
import Cookies from 'js-cookie'
import fa from 'element-ui/src/locale/lang/fa'
import { Message } from 'element-ui'
import store from '@/store'

// 验证app是否购买，企业是否存在
export function getCheckTenant(data) {
  // request.post('/niscommon/noAuth/get-check-tenant', data)
  let url = '/system/getSystemByDomain/'
  if (Cookies.get('siteInfoUrl')) {
    url = Cookies.get('siteInfoUrl')
  }
  // console.log(document.domain)
  return request({
    baseURL: process.env.VUE_APP_API,
    // url: '/niscommon/noAuth/get-check-tenant',
    url: url + data,
    method: 'get'
  })
  // axios.get(
  //   http://api.www.nisbos.com/
  //   "http://10.1.4.71:30080/nisbos/system/getSystemByDomain/admin.nisbos.com"
  // );
}
export function websiteRequest(url, params, method) {
  if (method == 'get') {
    return axios.get(
      'http://api-www.dev.nisbos.com' + url, {
        params
      }

    ).then(res => {
      // console.log(res)
      if (res.data.code !== 0) {
        Message.error(res.data.message || '未知异常')
        throw res.data.message
      }
      return Promise.resolve(res.data)
      // console.log(res)
    })
  } else {
    return axios.post(
      'http://api-www.dev.nisbos.com' + url,
      params
    ).then(res => {
      // console.log(res)
      if (res.data.code !== 0) {
        Message.error(res.data.message || '未知异常')
        throw res.data.message
      }
      return Promise.resolve(res.data)
      // console.log(res)
    })
  }
}
// 获取用户信息
export function getUserInfo(data) {
  return request.post('/niscommon/get-user-info', data)
}
// 退出登录
export function loginOut(data) {
  return request.post('/niscommon/noAuth/loginOut', data)
}
// 获取登录验证码
export function getLogonTelCode(params) {
  // return request({
  //   url: '/niscommon/noAuth/sendCode',
  //   method: 'post',
  //   params: params
  // });
  let url = ''
  if (Cookies.get('loginApi')) {
    url = Cookies.get('loginApi')
  }

  // console.log(Cookies.get('loginApi'), Cookies.get('systemId'))
  axios.defaults.headers['systemId'] = Cookies.get('systemId')
  return axios.post(
    location.hostname == 'localhost' ? 'https:' + '//' + Cookies.get('url') + '/niscommon/noAuth/sendCode' : window.location.protocol + '//' + Cookies.get('url') + '/niscommon/noAuth/sendCode',
    params
  ).then(res => {
    if (res.data.code !== 0) {
      Message.error(res.data.message || '未知异常')
      throw res.data.message
    }
    return res
    // console.log(res)
  })
}
// 注册
export function register(params) {
  // console.log(Cookies.get('loginApi'), Cookies.get('systemId'))
  axios.defaults.headers['systemId'] = Cookies.get('systemId')
  return axios.post(
    location.hostname == 'localhost' ? 'https:' + '//' + Cookies.get('url') + '/user/register' : window.location.protocol + '//' + Cookies.get('url') + '/user/register',
    params
  ).then(res => {
    if (res.data.code !== 0) {
      Message.error(res.data.message || '未知异常')
      throw res.data.message
    }
    return res
    // console.log(res)
  })
}
// 获取租户列表
export function getTenantList(params) {
  return request({
    url: '/niscommon/noAuth/get-tenant',
    method: 'post',
    params: params
  })
}
export function newLogin(params) {
  let url = ''
  if (Cookies.get('loginApi')) {
    url = Cookies.get('loginApi')
  }

  axios.defaults.headers['systemId'] = Cookies.get('systemId')
  // alert(Cookies.get('url'))
  return axios.post(
    location.hostname == 'localhost' ? 'https:' + '//' + Cookies.get('url') + '/niscommon/noAuth/loginNew' : window.location.protocol + '//' + Cookies.get('url') + '/niscommon/noAuth/loginNew',
    params
  ).then(res => {
    if (res.data.code !== 0) {
      Message.error(res.data.message || '未知异常')
      throw res.data.message
    } else {
      Cookies.set('companySystemId', res.data.data.systemId)
    }
    return res
  })
}
// 提交 授权码
export function licenseActive(data) {
  return request({
    url: '/basic/huawei/license/active?license=' + data,
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'get'
    // data
  })
}
// 查询 授权码
export function niscommonCheckAuthCode(data) {
  return request({
    url: '/niscommon/checkAuthCode',
    baseURL: process.env.VUE_APP_BASE_API,
    method: 'post',
    data
  })
}
// 登录
export function login(params) {
  // return request({
  //   url: "/niscommon/noAuth/login",
  //   method: "post",
  //   data: params,
  // });
  let url = ''
  if (Cookies.get('loginApi')) {
    url = Cookies.get('loginApi')
  }

  // console.log(Cookies.get('loginApi'), Cookies.get('systemId'))
  axios.defaults.headers['systemId'] = Cookies.get('systemId')
  return axios.post(
    location.hostname == 'localhost' ? 'https:' + '//' + Cookies.get('url') + url : window.location.protocol + '//' + Cookies.get('url') + url,
    params
  ).then(res => {
    if (res.data.code !== 0) {
      Message.error(res.data.message || '未知异常')
      throw res.data.message
    }
    return res
    // console.log(res)
  })
}

// 验证码登录
export function loginByPhone(params) {
  return request({
    url: '/niscommon/noAuth/loginByPhone',
    method: 'post',
    data: params
  })
}
// 续签操作
export function updateToken(params) {
  return request({
    url: '/niscommon/noAuth/refresh-token',
    method: 'post',
    data: params
  })
}
// 获取菜单列表
export function getRouters(params) {
  // console.log('params', params)
  let url = ''
  if (Cookies.get('menuApi')) {
    url = Cookies.get('menuApi')
  }
  return request({
    url: location.hostname == 'localhost' ? 'https:' + '//' + Cookies.get('url') + url : window.location.protocol + '//' + Cookies.get('url') + url,
    // url: '/nismes-user-service/saas-user/getRouters',
    method: 'post',
    params: params
  })
}

// 获取页面结构
// export function getStructure(params) {
//   return request({
//     url: '/niscommon/page-structure/getStructure',
//     method: 'post',
//     params: params
//   });
// }

axios.defaults.withCredentials = false

/**
 * 将结构中的 'false' 转化为 false
 * */
function changeValueType(structure) {
  if (Array.isArray(structure)) {
    structure.forEach((item) => {
      if (item === 'false') {
        item = false
      }
      changeValueType(item)
    })
  } else if (typeof structure === 'object') {
    for (const structureKey in structure) {
      if (structure[structureKey] === 'false') {
        structure[structureKey] = false
      }
      changeValueType(structure[structureKey])
    }
  }
}

export function getStructure(apiOrCode) {
  // console.log('开始请求')
  const path = location.hostname == 'localhost' ? 'https:' + '//' + Cookies.get('url') + '/app-system/list-menu-structure' : window.location.protocol + '//' + Cookies.get('url') + '/app-system/list-menu-structure'

  if (typeof apiOrCode === 'object') {
    return getStructureByApi(apiOrCode)
  }

  return axios({
    headers: {
      systemId: getSystemId(),
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + getToken()
    },
    withCredentials: false,
    url: path,
    method: 'get',
    params: { menuId: apiOrCode },
    // 超时
    timeout: 60000
  }).then((res) => {
    // console.log(res)
    const structure = JSON.parse(res.data.data)
    changeValueType(structure)
    return { data: structure }
  })
}

export function getStructureByApi(api) {
  // console.log('调用解析结构', api, api.domApi)

  if (!api.domApi) {
    Message({
      message: '未配置domApi',
      type: 'error'
    })
    return
  }
  const path = location.hostname == 'localhost' ? 'https:' + '//' + Cookies.get('url') : window.location.protocol + '//' + Cookies.get('url')
  // console.log(path + api.domApi)
  return axios({
    headers: {
      systemId: getSystemId(),
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + getToken()
    },
    withCredentials: false,
    url: path + api.domApi,
    method: 'get',
    // params: { menuId: apiOrCode },
    // 超时
    timeout: 60000
  }).then((res) => {
    // console.log(res)
    const structure = JSON.parse(res.data.data)
    changeValueType(structure)
    return { data: structure }
  })
}
// 单点登录
export function getIdmToken(param) {
  return request({
    url: `/niscommon/noAuth/getIdmToken`,
    method: 'get',
    params: param
  })
}

export function addMenuStructure(param) {
  return services({
    url: `/org_menuStructure/addMenuStructure`,
    method: 'POST',
    data: param
  })
}

export function updateMenuStructure(param) {
  return services({
    url: `/org_menuStructure/updateMenuStructure`,
    method: 'POST',
    data: param
  })
}
