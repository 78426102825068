<template>
    <div>
        <el-select
                v-if="dom.remoteMethod"
                v-model="formValue"
                v-bind="dom"
                clearable
                filterable
                :multiple="dom.multiple"
                :popper-append-to-body="false"
                :placeholder="dom.placeholder || '请输入关键字'"
                remote
                :loading="loading"
                :remote-method="remoteMethod"
                @change="handleChange"
                @clear="clearValue"
        >
            <el-option
                    v-for="(item, index) in options"
                    :key="index"
                    :label="item[dom['dictLabel'] || 'dictLabel']"
                    :value="item[dom['dictValue'] || 'dictValue']"
            />
        </el-select>
        <el-select
                v-else
                v-model="formValue"
                clearable
                :multiple="dom.multiple"
                :popper-append-to-body="true"
                :placeholder="dom.placeholder || '请选择'"
                @change="handleChange"
                v-bind="dom"
                @clear="clearValue"
        >
            <el-option
                    v-for="(item, index) in options"
                    :key="index"
                    :label="item[dom['dictLabel'] || 'dictLabel']"
                    :value="item[dom['dictValue'] || 'dictValue']"
            />
        </el-select>
    </div>
</template>

<script>
    import service, {saasRequest} from "../../api/request";
    import Cookies from "js-cookie";

    export default {
        name: "VSelect",
        props: {
            dom: {
                type: Object,
                default: () => {
                    return {};
                },
            },
            form: {
                type: Object,
                default: () => {
                    return {};
                },
            },
            value: {
                type: [Array, String,Number],
                default: "",
            },
        },
        data() {
            return {
                formValue: undefined,
                options: [],
                list: [],
                loading: false,
            };
        },
        watch: {
            value: {
                immediate: true,
                handler() {
                    console.log("select", this.value, this.dom);
                    // if (this.dom.relation) {
                    //   this.relationDict({
                    //     dictUrl:
                    //       this.dom.relation.url +
                    //       "?" +
                    //       this.dom.relation.paramsKey +
                    //       "=" +
                    //       this.value,
                    //       dictProps: this.dom.relation.dictProps,
                    //       prop:this.dom.relation.relaProp
                    //   });
                    // }
                    if(this.dom.custom){
                        setTimeout(()=>{
                            this.options = this.dom.data
                        },1000)
                    }
                    this.formValue = this.value;
                },
            },

            form1: {
                immediate: true,
                handler(val, o) {
                    console.log("select yehua ");
                    if (this.dom.selectLinkKey && val[this.dom.selectLinkKey] &&
                        val[this.dom.selectLinkKey] != o[this.dom.selectLinkKey]) {
                            let param = {};
                            this.formValue = null;
                            param[this.dom.selectLinkKey] = val[this.dom.selectLinkKey];
                            console.log("select yehua1 ");
                            this.getDict(this.dom, param);
                    }
                   else if (this.dom.selectLinkKey && this.form1[this.dom.selectLinkKey]) {
                        let param = {};
                        param[this.dom.selectLinkKey] = this.form1[this.dom.selectLinkKey];
                        console.log("select yehua2 ");
                        this.getDict(this.dom, param);
                    } else {
                        if (this.dom.remoteMethod){
                            console.log("select yehua3 ");
                            let param = {};
                            param[this.dom.paramsKey] = this.form[this.dom.paramsKey];
                            this.getDict(this.dom, param);
                        }
                        else if (this.dom.dictUrl) {
                            console.log(this.dom);
                            this.getDict(this.dom);
                        }
                    }
                    // if (this.dom.remoteMethod) {
                    //     console.log("select yehua3 ");
                    //     let param = {};
                    //     param[this.dom.paramsKey] = this.form[this.dom.paramsKey];
                    //     this.getDict(this.dom, param);
                    // }
                },
            },
        },
        computed: {
            form1() {
                return JSON.parse(JSON.stringify(this.form));
            },
        },
        mounted() {
            let dom = this.dom;
            console.log(dom);
            console.log(this.formValue);
            if (dom.api) {
                // 通过api获取选项
                this.getOption();
            } else if (dom.dictUrl) {
                // 通过字典获取选项

                if (this.dom.remoteMethod) {
                }else{
                    if (this.dom.selectLinkKey && this.form1[this.dom.selectLinkKey]) {
                        let param = {};
                        param[this.dom.selectLinkKey] = this.form1[this.dom.selectLinkKey];
                        this.getDict(this.dom, param);
                    } else {
                        this.getDict(dom);
                    }
                }
            } else {
                this.options = dom.data;
            }
        },
        methods: {
            clearValue(val) {
                console.log(val);
                this.formValue = null;
            },

            remoteMethod(query) {
                console.log(query);
                console.log(query.length);
                //最小字符搜索长度
                var minStringLen = this.dom.remoteMinLen?this.dom.remoteMinLen:0;
                if (query !== "" && query.length >= minStringLen) {
                    this.options = [];
                    this.loading = true;
                    let param = {};
                    if (this.dom.selectLinkKey && this.form[this.dom.selectLinkKey]) {
                        param[this.dom.selectLinkKey] = this.form[this.dom.selectLinkKey];
                    }
                    param[this.dom.paramsKey] = query;
                    console.log(param);
                    this.getDict(this.dom, param);
                } else {
                    this.options = [];
                }
            },

            // 通过api获取选项
            getOption() {
                const params = {
                    currentPage: -1,
                    pageSize: -1,
                };
                saasRequest(
                    { api: "/develop/dict/getDataById", method: "get" },
                    { id: this.dom.api }
                ).then((res) => {
                    let data = res.data;
                    if (!Array.isArray(data)) {
                        data = res.data.records;
                    }

                    this.options = data || [];
                    console.log(data);
                });
            },
            // 通过字典获取选项
            getDict(dom, param) {
                console.log(param, dom,this.form);
                if (!param) {
                    param = {};
                    // Object.assign(param, { t: 1 });
                }
                if (dom.params) {
                    Object.assign(param, dom.params);
                }
                if (dom.tablekey) {
                    let obj = {};
                    obj[dom.tablekey] = this.form[dom.tablekey];
                    console.log(obj);
                    Object.assign(param, obj);
                }
                console.log(param);
                const path = Cookies.get("url");
                service({
                    url: window.location.protocol + "//" + path + dom["dictUrl"],
                    method: dom["dictMethod"] || "get",
                    data: param,
                    params: param,
                }).then((res) => {
                    console.log(res);
                    this.loading = false;

                    if (dom["dictProps"]) {
                        if (res.data && res.data.records) {
                            this.list = res.data.records;
                            dom["data"] = res.data.records.map((item) => {
                                return {
                                    dictLabel: item[dom["dictProps"].dictLabel],
                                    dictValue: item[dom["dictProps"].dictValue],
                                };
                            });
                        } else if (res.data) {
                            this.list = res.data;
                            dom["data"] = res.data.map((item) => {
                                return {
                                    dictLabel: item[dom["dictProps"].dictLabel],
                                    dictValue: item[dom["dictProps"].dictValue],
                                };
                            });
                        }else{
                            this.list =[]
                        }
                    } else {
                        dom["data"] = res.data;
                    }
                    this.options = dom["data"];
                    console.log(this.options, this.list);
                });
            },
            relationDict(dom) {
                const path = Cookies.get("url");
                service({
                    url: window.location.protocol + "//" + path + dom["dictUrl"],
                    method: dom["dictMethod"] || "get",
                    data: {
                        t: 1,
                    },
                }).then((res) => {
                    console.log(res);
                    if (dom["dictProps"]) {
                        let data = res.data.map((item) => {
                            return {
                                dictLabel: item[dom["dictProps"].dictLabel],
                                dictValue: item[dom["dictProps"].dictValue],
                            };
                        });
                        this.$emit("relationData", { data: data, prop: dom.prop });
                    }
                    {
                        // dom["data"] = res.data;
                    }
                    // this.options = dom["data"];
                });
            },
            handleChange(item) {
                console.log(this.dom);
                let data = [];
                if (this.dom.multiple && this.dom.linkKey) {
                    this.list.forEach((i) => {
                        item.forEach((element) => {
                            if (i[this.dom.linkKey] == element) {
                                console.log(i);
                                data.push(i);
                            }
                        });
                    });
                    let newData = data
                        .map((obj) => {
                            return obj.orgNameFullPath;
                        })
                        .join(",");
                    let obj = {};
                    obj[this.dom.linkProp] = newData;
                    this.$emit("setLinkVal", obj);
                    console.log(this.formValue, data);
                }
                if (this.dom.linkKeyArr) {
                    let obj = this.list.find((i) => {
                        return item == i.id;
                    });
                    let newData = {};
                    this.dom.linkKeyArr.forEach((k) => {
                        console;
                        newData[k] = obj[k];
                    });
                    console.log(obj, newData);
                    // let newData = data
                    //   .map((obj) => {
                    //     return obj.orgNameFullPath;
                    //   })
                    //   .join(",");
                    // let obj = {};
                    // obj[this.dom.linkProp] = newData;
                    this.$emit("setLinkVal", newData);
                    // console.log(this.formValue, data);
                }
                // if (this.dom.oldLink) {
                //   console.log(item,this.list);
                //   let datas = this.list.find((i) => {
                //     return (i.postFullName == item);
                //   });
                //   let obj = {};
                //   obj[this.dom.oldLink.newKey]=datas.postCode
                //   this.$emit("setLinkVal", obj);
                //   console.log(obj,datas)
                // }
                this.$emit("input", this.formValue);
            },
        },
    };
</script>

<style scoped>
</style>